import React, { useState } from "react";
import { connect } from "react-redux";
import { loginAction } from "../../redux/actions/auth";
import { Row, Col } from "antd";
// import kfWx from "../../../assets/images/kf_wx.jpg";
// import logo1 from "../../assets/images/logo92x192.png";
import state48 from "../../assets/images/map.jpeg";
import { Navigate } from "react-router-dom";
import LoginForm from "../../components/login-form/login-form";
import RegisterForm from "../../components/register-form/register-form";

// 这是后台登录页面
function LoginPage(props) {
  //默认显示登录页面
  const [formState, setFormState] = useState("login");

  //切换登录或者注册页面
  const switchTo = (formName) => {
    setFormState(formName);
  };

  const handleLogin = async ({ username, password }) => {
    // console.log('handleLogin', props);
    props.loginAction({ username, password });
  };

  let { userInfo } = props;
  //如果用户已经是登录状态，自动跳转到home页面
  if (userInfo && userInfo.token && userInfo.id) {
    return <Navigate to="/dispatcher" />;
  }
  //未登录显示登录页面
  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "green",
          }}
        >
          <div>
            <img style={{ width: "100%" }} src={state48} alt="48 states" />
          </div>
        </Col>
        <Col span={2}></Col>
        <Col span={8}>
          <div
            style={{
              height: "30%",
              width: "100%",
              backgroundColor: "",
              textIndent: "20px",
              paddingTop: "60px",
            }}
          >
            <h1 style={{ color: "#333" }}>Try Quote First!</h1>
            <h2>Instant All America Trucking Quote.</h2>
            {/* <img style={{ width: "200px" }} src={logo1} alt="客服微信" /> */}
          </div>
          {formState === "login" ? (
            <LoginForm handleLogin={handleLogin} switchTo={switchTo} />
          ) : (
            <RegisterForm switchTo={switchTo} />
          )}
        </Col>
      </Row>
    </>
  );
}

export default connect((state) => ({ userInfo: state.auth.userInfo }), {
  loginAction,
})(LoginPage);
