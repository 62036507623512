import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Select,
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { listApi, saveApi, deleteApi } from "../../api/truck";
import { selectListApi } from "../../api/driver";

export default function TruckPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [searchForm, setSearchForm] = useState({ name: "name" });

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});
  const driverRef = useRef({});

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getSelectListData();
    await getListData();
  };

  //获取客户下来选择数据
  const getSelectListData = async () => {
    const result = await selectListApi();
    // console.log("getSelectListData result,", result);
    setDriverList(result);
    result.forEach((item) => {
      driverRef.current[item.id] = item.name;
    });
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      ...{
        [searchForm.name]: searchForm.value,
      },
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    setShowAddModal(false);
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    await saveApi(values);
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    { title: "LP #", dataIndex: "licensePlate", key: "licensePlate" },
    { title: "VIN #", dataIndex: "vinNo", key: "vinNo" },
    { title: "RFID Rag #", dataIndex: "rfidNo", key: "rfidNo" },
    { title: "RFID Rag #", dataIndex: "rfidNo", key: "rfidNo" },
    {
      title: "Driver Name",
      dataIndex: "driverId",
      key: "driverId",
      render: (driverId) => {
        return <div>{driverRef.current[driverId]}</div>;
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Address",
      dataIndex: "companyAddress",
      key: "companyAddress",
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={async (e) => {
              editRef.current = record;
              await getSelectListData();
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the customer"
            description="Are you sure to delete this customer?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Select
        placeholder="Search..."
        onChange={(v) => {
          setSearchForm({ ...searchForm, name: v });
        }}
        style={{ width: "110px" }}
        defaultValue="name"
      >
        <Select.Option value="name">Name</Select.Option>
        <Select.Option value="companyName">Company</Select.Option>
        <Select.Option value="address">Address</Select.Option>
      </Select>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) =>
          setSearchForm({ ...searchForm, value: e.target.value })
        }
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData();
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        extra={
          <Button
            onClick={async () => {
              editRef.current = {};
              await getSelectListData();
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Truck
          </Button>
        }
      >
        <Table rowKey="id" bordered columns={columns} dataSource={dataList} />
      </Card>
      <Modal
        title="Edit Truck"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 23 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{ ...editRef.current }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="license Plate #"
            name="licensePlate"
            rules={[{ required: true, message: "Please input licensePlate." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="VIN Number #"
            name="vinNo"
            rules={[{ required: true, message: "Please input VIN number." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="RFID Rag #"
            name="rfidNo"
            rules={[
              { required: true, message: "Please input RFID Tag number." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Driver"
            name="driverId"
            rules={[{ required: true, message: "Please select driver." }]}
          >
            <Select
              showSearch
              placeholder="Select a driver"
              optionFilterProp="label"
              options={driverList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item label="Company Name" name="companyName">
            <Input />
          </Form.Item>
          <Form.Item label="Company Address" name="companyAddress">
            <TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
