import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (params) =>
  {
    // console.log('params,', {...params});
    return ajax("/sys/poolChassis/list", {...params});
  }

//添加
export const saveApi = (data) =>
  ajax("/sys/poolChassis/save", obj2formData(data), reqType.POST);

//删除
export const deleteApi = (id) =>
  ajax("/sys/poolChassis/delete", { id }, reqType.GET);

//总计
export const totalApi = () =>
  ajax("/sys/poolChassis/total");
