import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { connect } from "react-redux";
import AdminPage from "./pages/admin";
import LoginPage from "./pages/login";
import { userOptions } from "./utils/storageUtils";
import { updateUserInfo } from "./redux/actions/auth";
import "./App.css";
import { refreshTokenApi } from "./api/auth";
import Spin from "./components/spin";

function App(props) {
  //初始化本地存储的用户信息到redux
  //等待更新用户信息
  const [waitForUserInfo, setWaitForUserInfo] = useState(props.waitForUserInfo);

  useEffect(() => {
    getUserInfoData();
  }, []);

  useEffect(() => {
    setWaitForUserInfo(props.waitForUserInfo);
  }, [props.waitForUserInfo]);

  const getUserInfoData = async () => {
    //检测用户是否已经登录过
    let userInfo = userOptions.getUserInfo();
    let result = null;
    if (userInfo && userInfo.token && userInfo.expired && ( Date.now() - userInfo.expired <= 48 * 60 * 60 * 1000 )) {
      result = await refreshTokenApi();
      result.expired = Date.now();
      console.log('refresh token and set expired to now');
      userOptions.saveUserInfo(result);
    }
    props.updateUserInfo(result);
  };

  console.log("isLoaded,", waitForUserInfo);

  return (
    <>
      {waitForUserInfo ? (
        <div>
          <Spin />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route path="/*" element={<AdminPage />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default connect(
  (state) => ({ waitForUserInfo: state.auth.waitForUserInfo }),
  { updateUserInfo }
)(App);
