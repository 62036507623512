import { Card, Col, Divider, Row, Space, Tooltip } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { invoiceTypeListApi, profitListApi } from "../../api/invoice";
import { INVOICE_TYPE } from "../../config";
import { formatMoney } from "../../utils/invoiceUtils";

export default function ARListPage() {
  const [dataList, setDataList] = useState([]);
  const [profit, setProfit] = useState(new Map());

  useEffect(() => {
    initData();
  }, []);

  //初始化数据
  const initData = async () => {
    await getPayListData();
    await getProfitListData();
  };

  //获取利润列表
  const getProfitListData = async () => {
    const res = await profitListApi();
    const tt = res.reduce((result, value) => {
      const key = `${value["customerId"]}_${value["invoiceType"]}`;
      result.set(key, value["amount"]);
      return result;
    }, new Map());
    // console.log("result", tt);
    setProfit(tt);
  };

  //获取所有需要支付的主体数据
  const getPayListData = async () => {
    const res = await invoiceTypeListApi(INVOICE_TYPE.AR);
    var data = new Map();
    res.forEach(({ partyId, partyName, amount, status }) => {
      var value = data.get(partyId) || {
        init: 0,
        cfmd: 0,
        paid: 0,
        partyName,
        partyId,
      };
      if (status === 0) {
        value["init"] += amount;
      } else if (status === 1) {
        value["cfmd"] += amount;
      } else {
        value["paid"] += amount;
      }
      data.set(partyId, value);
    });
    // 将 Map 转换为键值对数组，并按值进行降序排序
    const sortedArray = Array.from(data).sort(
      (a, b) => b[1]["init"] + b[1]["cfmd"] - (a[1]["init"] + a[1]["cfmd"])
    );
    // console.log(
    //   "sortedArray",
    //   sortedArray.map(([_, b]) => b)
    // );
    setDataList(sortedArray.map(([_, b]) => b));
  };

  return (
    <Card style={{ padding: "10px" }}>
      <Space size={[16, 16]} wrap>
        {dataList.map((item) => {
          return (
            <Card
              key={nanoid()}
              title={
                <Tooltip title={item["partyName"]}>
                  {item["partyName"].split("-")[0].trim()}
                </Tooltip>
              }
            >
              <div style={{ padding: "10px", width: "200px" }}>
                <Row>
                  <Col span={8}>INIT: </Col>
                  <Col span={12}>{formatMoney(item["init"])}</Col>
                </Row>
                <Row>
                  <Col span={8}>CFMD: </Col>
                  <Col span={12}>{formatMoney(item["cfmd"])}</Col>
                </Row>
                <Row>
                  <Col span={8}>PAID: </Col>
                  <Col span={12}>{formatMoney(item["paid"])}</Col>
                </Row>
                <Divider style={{ margin: "15px 0px" }} />
                <Row>
                  <Col span={5}>AR: </Col>
                  <Col span={19}>
                    {formatMoney(profit.get(`${item["partyId"]}_0`) || 0)}
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>AP: </Col>
                  <Col span={19}>
                    {formatMoney(profit.get(`${item["partyId"]}_1`) || 0)}
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>Profit: </Col>
                  <Col span={16} style={{ color: "red" }}>
                    {formatMoney(
                      (profit.get(`${item["partyId"]}_0`) || 0) -
                        (profit.get(`${item["partyId"]}_1`) || 0)
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Profit %: </Col>
                  <Col span={16} style={{ color: "red" }}>
                    {(
                      (((profit.get(`${item["partyId"]}_0`) || 0) -
                        (profit.get(`${item["partyId"]}_1`) || 0)) /
                        (profit.get(`${item["partyId"]}_0`) || 0)) *
                      100
                    ).toFixed(2) + "%"}
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </Space>
    </Card>
  );
}
