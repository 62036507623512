import { Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";

export default function ItemSelectForm(props) {
  const [options, setOptions] = useState(props.options);
  const [form] = Form.useForm();

  useEffect(() => {
    setOptions(props.options);
    if(props.value != null) {
      form.setFieldValue("name", props.value);
    }
  }, [props.options, props.value]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{ name: props.value || null }}
      >
        <Form.Item name="name" label={props.label}>
          <Select
            showSearch
            optionFilterProp="label"
            options={options}
            onChange={(index) => {
              // console.log("onChange", index);
              props.handleSave(index);
              // if (index !== props.value) {

              // Modal.confirm({
              //   title: `Update ${props.label}?`,
              //   onOk: () => props.handleSave(index),
              //   onCancel: () => {
              //     // console.log("canceled");
              //     // 恢复原来的值
              //     form.setFieldValue("name", props.value);
              //   },
              // });
              // }
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
