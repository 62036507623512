import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (params) => {
  // console.log('params,', {...params});
  return ajax("/sys/system/user/list", { ...params });
};

//添加
export const saveApi = (data) =>
  ajax("/sys/system/user/save", obj2formData(data), reqType.POST);

//更新权限
export const updatePermissionApi = (data) =>
  ajax("/sys/system/user/updatePermission", obj2formData(data), reqType.POST);

//添加
export const initPasswordApi = (data) =>
  ajax("/sys/system/user/initpwd", obj2formData(data), reqType.POST);

//删除
export const deleteApi = (id) =>
  ajax("/sys/system/user/delete", { id }, reqType.GET);
