import { Tooltip, DatePicker, Space, Button, Input } from "antd";
import React, { useState } from "react";
import { saveApi } from "../../api/loads";

const s_chassis = ["EMCZ", "PierA", "STCP", "FBA"];

export default function DoubleClickInput({
  id,
  name,
  content,
  handleUpdateList,
  handleSave = null,
  record,
  backgroundColor = null,
  width
}) {
  const [open, setOpen] = useState(false);

  //获取文本的颜色
  const getContentBgColor = () => {
    if (backgroundColor) {
      return backgroundColor;
    }
    if (content && name === "chassisNo") {
      if (record["ownChassis"]) {
        return "#E3B0E4";
      }
      for (let index = 0; index < s_chassis.length; index++) {
        const element = s_chassis[index];
        if (content.toLowerCase().includes(element.toLowerCase())) {
          return "#E3B0E4";
        }
      }
    }
    return "white";
  };

  const submit = async (e) => {
    let value = e.target.value.trim();
        //alert(value);
        // console.log("onBlur", value);
        setOpen(false);
        if (name === "weight") {
          value = parseInt(value.replace(/,/g, ""), 10);
        }
        if (handleSave) {
          //alert(name);
          await handleSave({ id, [name]: value });
        } else {
          await saveApi({ id, [name]: value });
        }
        if(value=='') {
          value=' ';
        }
        handleUpdateList(value);
    }
  var minwidth = "300px";
  if (width != null) {
    minwidth = width; 
  }
  return open ? (
    <Input
      style={{
        minWidth: minwidth,
      }}
      autoFocus={true}
      defaultValue={content}
      onKeyDown={async (e) => {
        if(e.key === 'Enter') {
          submit(e);
        }
      }}
      onBlur={async (e) => {
        submit(e);
      }}
    />
  ) : (
    <div
      onDoubleClick={(e) => {
        setOpen(true);
      }}
      style={{
        cursor: "pointer",
        backgroundColor: getContentBgColor(),
        color: content ? "black" : "white",
        paddingRight: "10px",
        borderRadius: "1px",
        // width: "100px",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
      }}
    >
      {content || "N/A"}
    </div>
  );
}
