

// 计算好评率
export const calcLikeRate = (like, unlike) => {
    const count = like + unlike;
    if(count === 0 || like === 0){
        return "暂无评价";
    }
    let rate = (Math.round((like / count) * 10000) / 100.00);
    return rate.toFixed(2) + "%";
}

// 金额格式化
export const formatCurrency = (amount) =>{
    if(amount === undefined || amount === null) {
        amount = 0
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(amount);
}