import {
  Button,
  Col,
  Form,
  Input,
  Typography,
  Row,
  Select,
  Table,
  InputNumber,
  Divider,
  Space,
  message,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { selectListApi as customerListApi } from "../../api/customer";
import { selectListApi as driverListApi } from "../../api/driver";
import {
  selectListApi as chargeTypeListApi,
  saveApi as saveChargeTypeApi,
} from "../../api/charge-type";
import { getDetailsApi } from "../../api/invoice";

const { Text } = Typography;

//定义可编辑的行
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

//定义可编辑的Cell组件
const EditableCell = ({
  title,
  editable,
  required,
  children,
  dataIndex,
  record,
  handleSave,
  chargeTypeList,
  getChargeTypeList,
  ...restProps
}) => {
  //新增新的收费类型名称
  const [chargeTypeItemName, setChargeTypeItemName] = useState("");
  const [selectedChargeType, setSelectedChargeType] = useState(null);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (record) {
      form.setFieldValue("name", record.name);
    }
  }, [record]);

  //编辑状态改变的时候触发
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  //对编辑状态进行更新，同时更新Table组件的数据
  const toggleEdit = () => {
    setEditing(!editing);
    //给Table的行赋值
    form.setFieldsValue({
      //这里赋值给dataIndex是因为Form.Item在生成的时候name定义的就是dataIndex
      //[dataIndex]这里并不是数组的表示，而是取dataIndex的值
      [dataIndex]: record[dataIndex],
    });
  };

  //数据保存处理，在输入回车键或者组件失去焦点的时候触发
  const save = async (rate = 0) => {
    try {
      //触发表单的数据验证
      const values = await form.validateFields();
      // console.log("valuesvaluesvaluesvalues", values, record);
      handleSave({
        ...record,
        rate,
        ...values,
      });
      setEditing(!editing);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  //选择添加charge type函数
  const onNameChange = (event) => {
    setChargeTypeItemName(event.target.value);
  };
  const addItem = async (e) => {
    e.preventDefault();
    if (!chargeTypeItemName || chargeTypeItemName.length < 1) return;
    // console.log("chargeTypeItemName", chargeTypeItemName);
    await saveChargeTypeApi({ name: chargeTypeItemName });
    setChargeTypeItemName(null);
    //更新收费类型列表
    getChargeTypeList();
  };

  let childNode = children;
  //charge type下拉框
  if (dataIndex === "name") {
    // console.log("recordrecordrecord", record);
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        //通过required属性设置所在列都是必填项
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select charge type..."
          optionFilterProp="label"
          options={chargeTypeList.map((item) => ({
            label: item.name,
            value: item.id,
            origin: item,
          }))}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space.Compact
                block
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  placeholder="Please enter item"
                  value={chargeTypeItemName}
                  onChange={onNameChange}
                />
                <Button
                  style={{ width: "40px" }}
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addItem}
                ></Button>
              </Space.Compact>
            </>
          )}
          value={selectedChargeType}
          onChange={(value, option) => {
            const rate = option?.origin?.rate || 0;
            setSelectedChargeType(value);
            save(rate);
          }}
        />
      </Form.Item>
    );
  } else if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        //通过required属性设置所在列都是必填项
        rules={[
          required && {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === "description" ? (
          <Input
            style={{ width: "300px" }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        ) : (
          <InputNumber
            style={{ width: "100%" }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          maxWidth: "300px",
          overflow: "hidden",
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

//主组件
const LoadBillingEdit = ({
  editRecord,
  addType,
  handleClose,
  handleInvoiceSave,
}) => {
  const [form] = Form.useForm();
  const vendorValue = Form.useWatch("vendor", form);
  const [dataSource, setDataSource] = useState([]);
  const [venderList, setVenderList] = useState([]);
  const [chargeTypeList, setChargeTypeList] = useState([]);
  const [addButtonText, setAddButtonText] = useState("Customer");
  //导航跳转
  const navigate = useNavigate();
  //表示Table中记录总数
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (addType === "AP") {
      getDriverListData();
      setAddButtonText("Driver");
    } else {
      getCustomerListData();
      setAddButtonText("Customer");
    }
  }, [addType]);

  useEffect(() => {
    getChargeTypeList();
  }, []);

  useEffect(() => {
    if (editRecord) {
      // console.log("editRecord", editRecord);
      form.setFieldValue("vendor", editRecord.partyId);
      //获取订单详细
      getInvoiceDetailData(editRecord.id);
    } else {
      handleAdd();
    }
  }, []);

  //获取订单详细
  const getInvoiceDetailData = async (invoiceId) => {
    const res = await getDetailsApi(invoiceId);
    let newDatas = res.map((item, index) => ({
      ...item,
      name: item.chargeTypeId,
      key: index,
    }));
    if (newDatas.length > 0) {
      setCount(newDatas.length);
      setDataSource([...newDatas]);
      // console.log("newDatasnewDatasnewDatas,", newDatas);
    }
  };

  //获取收费类型列表
  const getChargeTypeList = async () => {
    const res = await chargeTypeListApi();
    setChargeTypeList(res);
  };

  //获取客户列表
  const getCustomerListData = async () => {
    const res = await customerListApi();
    setVenderList(res);
  };
  //获取司机列表
  const getDriverListData = async () => {
    const res = await driverListApi();
    setVenderList(res);
  };

  //删除行操作
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  //默认的表的列
  const defaultColumns = [
    {
      title: <div className="required">Name of Charge #</div>,
      name: "Name of Charge #",
      dataIndex: "name",
      width: "200px",
      editable: true,
      required: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      width: "300px",
    },
    { title: "Amount", dataIndex: "amount", width: "150px" },
    {
      title: <div className="required">Unit Count</div>,
      dataIndex: "unitCount",
      editable: true,
      required: true,
      name: "Unit Count",
      width: "120px",
    },
    {
      title: <div className="required">Rate</div>,
      dataIndex: "rate",
      editable: true,
      required: true,
      name: "Rate",
      width: "120px",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "100px",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          // <Popconfirm
          //   title="Sure to delete?"
          //   onConfirm={() => handleDelete(record.key)}
          // >
          //   <a style={{ color: "red" }}>Delete</a>
          // </Popconfirm>
          <Button
            type="link"
            style={{ color: "red" }}
            onClick={(e) => {
              e.preventDefault();
              handleDelete(record.key);
            }}
          >
            Delete
          </Button>
        ) : null,
    },
  ];
  //添加行操作
  const handleAdd = () => {
    const newData = {
      key: count,
      name: null,
      description: null,
      amount: 0,
      unitCount: 1,
      rate: 0,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  //保存数据，找出原有数据，然后覆盖
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      amount: row["unitCount"] * row["rate"],
    });
    // console.log("newData", newData);
    setDataSource([...newData]);
  };

  //自定义Table的生成组件
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  //定义Table的列
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      //设置单元格属性
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.name,
        required: col.required, //是否是必填项
        chargeTypeList,
        getChargeTypeList,
        handleSave,
      }),
    };
  });

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          marginTop: "20px",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical" autoComplete="off">
              <Form.Item
                name="vendor"
                label="Vendor"
                rules={[
                  {
                    required: true,
                    message: "Please select Vendor!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={venderList.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Row>
                        <Button
                          type="text"
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              addType === "AP" ? "/driver" : "/customer"
                            );
                          }}
                        >
                          Add {addButtonText}
                        </Button>
                      </Row>
                    </>
                  )}
                  onChange={(value) => {
                    // console.log("onChange ", vendorValue, value)
                  }}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          //统计
          summary={(pageData) => {
            let totalAmount = 0;
            pageData.forEach(({ amount }) => {
              totalAmount += amount;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="right">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text type="danger">{totalAmount}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
                {/* <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={10}></Table.Summary.Cell>
                </Table.Summary.Row> */}
              </>
            );
          }}
        />
        <Row justify={"end"} style={{ marginTop: "10px" }}>
          <Button
            onClick={handleAdd}
            style={{
              marginBottom: 16,
              color: "hsl(218, 91%, 59%)",
              fontWeight: 500,
            }}
          >
            Add a row
          </Button>
        </Row>
        <Divider style={{ height: "60px" }} />
        <Row
          justify={"center"}
          style={{ position: "absolute", bottom: "10px", width: "100%" }}
        >
          <Space size={60}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="primary"
              style={{ width: "130px" }}
              onClick={(e) => {
                e.preventDefault();
                //数据校验
                if (!vendorValue) {
                  message.error("Please select Vendor.");
                  return;
                }
                for (let index = 0; index < dataSource.length; index++) {
                  const element = dataSource[index];
                  if (!element.name) {
                    message.error("Please select the charge type.");
                    return;
                  }
                }
                //执行保存Invoice的操作
                handleInvoiceSave({
                  vendor: vendorValue,
                  dataList: dataSource,
                });
              }}
            >
              Save
            </Button>
          </Space>
        </Row>
      </div>
    </>
  );
};
export default LoadBillingEdit;
