import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (params) =>
  ajax("/sys/attachment/list", {...params});

//编辑保存
export const saveApi = ({id, name}) =>
  ajax("/sys/attachment/save", obj2formData({id, name}), reqType.POST);

//删除
export const deleteApi = (id) =>
  ajax("/sys/attachment/delete", { id }, reqType.GET);

//文件列表
export const getListApi = (ids) =>
  ajax("/sys/attachment/getList", {ids}, reqType.GET);

//上传文件
export const uploadApi = (file) =>
  ajax("/sys/attachment/autoAdd", obj2formData({file}), reqType.POST);

//下载PDF
export const downloadPdfApi = async (url, filename) =>
  await downloadFile("/sys/loads/downloadPDF", filename, url);
