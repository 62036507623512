import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Table,
  Input,
  Space,
  Popconfirm,
  message,
  Tag,
} from "antd";
import { PAGE_SIZE, POOL_CHASSIS } from "../../config";
import {
  listApi as listLogApi,
  saveApi as saveLogApi,
  deleteApi as deleteLogAPi,
  totalApi,
} from "../../api/pool-chassis-log";
import moment from "moment";
import { formatCurrency } from "../../utils/numUtils";

export default function PoolChassisLogPage() {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);

  const sizeRef = useRef(100);
  const pageRef = useRef(1);

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listLogApi({
      page,
      size,
      chassisNumber: searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //确定删除log
  const confirmLogDelete = async (id) => {
    await deleteLogAPi(id);
    message.success("Success");
    getListData();
  };

  //车架公司颜色
  const pool_color = ["#000000", "#0070BE", "#ED1744"];

  const columns = [
    // {
    //   title: "id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Type",
      dataIndex: "typeId",
      key: "typeId",
      fixed: "left",
      render: (typeId) => (
        <Tag color={pool_color[typeId]}>{POOL_CHASSIS[typeId]}</Tag>
      ),
    },
    {
      title: "Chassis Number",
      dataIndex: "chassisNumber",
      key: "chassisNumber",
      fixed: "left",
    },
    { title: "MBL #", dataIndex: "mbl", key: "mbl", fixed: "left" },
    {
      title: "PickUp Container",
      dataIndex: "pickupContainer",
      key: "pickupContainer",
    },
    {
      title: "Rate #",
      dataIndex: "rate",
      key: "rate",
      render: (amount) => formatCurrency(amount),
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      width: "60px",
      align: "center",
    },
    {
      title: "Amount #",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => formatCurrency(amount),
    },
    { title: "Invoice ID", dataIndex: "invoiceId", key: "invoiceId" },
    {
      title: "Type #",
      dataIndex: "typeSize",
      key: "typeSize",
      width: "80px",
    },
    { title: "Start Date", dataIndex: "startDate", key: "startDate" },
    { title: "End Date", dataIndex: "endDate", key: "endDate" },
    {
      title: "Status #",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === 0 ? "red" : "green"}>
          {status === 0 ? "Waiting" : "Confirmed"}
        </Tag>
      ),
    },
    { title: "SL #", dataIndex: "shippingLine", key: "shippingLine" },
    { title: "Return Scac", dataIndex: "dropOffScac", key: "dropOffScac" },
    {
      title: "DropOff Container",
      dataIndex: "dropoffContainer",
      key: "dropoffContainer",
    },
    {
      title: "PickUp Location",
      dataIndex: "pickupLocation",
      key: "pickupLocation",
    },
    {
      title: "Update Time",
      dataIndex: "updateTime",
      key: "updateTime",
      render: (updateTime) => {
        return (
          <span>
            {moment.utc(updateTime).local().format("MM/DD HH:mm ddd")}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      // width: "190px",
      render: (_, record) => (
        <Popconfirm
          title="Delete the record"
          description="Are you sure to delete this record?"
          okText="Yes"
          cancelText="No"
          onConfirm={(e) => confirmLogDelete(record["id"])}
        >
          <a>Delete</a>
        </Popconfirm>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) => setSearchForm(e.target.value.trim())}
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card title={titleNode} loading={loading}>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{
            showTotal: (total) => `Total ${total} items`,
            total,
            pageSize: sizeRef.current,
          }}
          scroll={{ x: "max-content", y: "auto" }}
        />
      </Card>
    </>
  );
}
