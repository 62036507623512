import { Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Barcode from "react-barcode";

export default function LabelDiv({
  page,
  total,
  customerName,
  shipTo,
  mark,
  cntr,
  qty,
  showPage,
}) {
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    buildPageData();
  }, []);

  //构建托盘标的数据
  const buildPageData = () => {
    // console.log("PalletLable", customerName, shipTo, cntr, qty, page, total);
    let data = [];
    data.push({
      title: "pallet",
      value:
        "Pallet Label\t托盘标签\t" + (showPage ? `${page}  of  ${total}` : ""),
    });
    data.push({
      title: "客户",
      value: customerName,
    });
    data.push({
      title: "SHIP TO",
      value: <span style={{ fontWeight: "500", fontSize: 24 }}>{shipTo}</span>,
    });
    data.push({
      title: "Container NO",
      value: <span style={{ fontWeight: "500", fontSize: 20 }}>{cntr}</span>,
    });
    data.push({
      title: "Qty",
      value: qty,
    });
    data.push({
      title: "Date",
      value: moment().format("MM/DD/YYYY"),
    });
    setRowsData(data);
  };

  const columns = [
    {
      title: "title",
      dataIndex: "title",
      key: "title",
      render: (text, record, index) => {
        if (index === 0) {
          return <h2 style={{ whiteSpace: "pre" }}>{record["value"]}</h2>;
        }
        return <div>{text}</div>;
      },
      onCell: (_, index) => ({
        colSpan: index === 0 ? 2 : 1,
      }),
    },
    {
      title: "value",
      dataIndex: "value",
      key: "value",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
      onCell: (_, index) => ({
        colSpan: index === 0 ? 0 : 1,
      }),
    },
  ];

  return (
    <>
      <Row>
        <Table
          style={{ width: "100%", padding: "3px 0px" }}
          showHeader={false}
          columns={columns}
          dataSource={rowsData}
          bordered
          pagination={false}
          size="small"
        />
        <div
          style={{
            marginTop: "15px",
            width: "100%",
            textAlign: "center",
            // backgroundColor: "yellow",
          }}
        >
          <span style={{ fontSize: "66px", fontWeight: "600" }}>{mark}</span>
        </div>
        <Row style={{ width: "95%", position: "absolute", bottom: 5, left: 9 }}>
          <Barcode
            value={`${cntr}_${shipTo}_${page.toString().padStart(3, "0")}`}
          />
        </Row>
      </Row>
    </>
  );
}
