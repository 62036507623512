import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (params) => {
  // console.log("params,", { ...params });
  return ajax("/sys/loadAnalysis/list", { ...params });
};

//列表
export const etaListApi = (params) => {
  // console.log("params,", { ...params });
  return ajax("/sys/loadAnalysis/eta", { ...params });
};
