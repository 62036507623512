import React from "react";
import LoadBillingEdit from "../../pages/dispatcher/billing-edit";
import { Modal } from "antd";

export default function InvoiceEditModal({
  open,
  title,
  setOpen,
  invoice,
  addType,
  handleClose,
  handleInvoiceSave,
}) {
  return (
    <Modal
      title={title}
      style={{
        top: 20,
      }}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={1200}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
    >
      <LoadBillingEdit
        addType={addType}
        editRecord={invoice}
        handleClose={handleClose}
        handleInvoiceSave={(values) => handleInvoiceSave(values)}
      />
    </Modal>
  );
}
