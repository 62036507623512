/*
 * 能发送异步ajax请求的函数模块
 * 封装axios库
 * 函数的返回值是promise对象
 *
 * 1、优化：统一处理请求异常。
 *       在外层包一个Promise对象
 *       请求出错不用reject，使用错误提示。
 *
 * 注意: 后台接口异常、网络异常都在axios的请求返回中处理了，调用方无需处理异常和失败情况
 *
 * */
import { BaseUrl, ReqType } from "./config";
import axios from "axios";
import { message } from "antd";
//从内存中读取用户信息
import { userOptions } from "../utils/storageUtils";
import { store } from "../redux/store";
import FileSaver from 'file-saver'

export const ajax = (
  url,
  data = {},
  type = ReqType.GET,
  baseUrl = BaseUrl,
  callback = () => {}
) => {
  // 填充完整url
  url = baseUrl + url;
  //自动添加token参数
  const token = userOptions.getToken();
  let headers = { token: token };
  // console.log('ajax token ', url, token);

  return new Promise((resolve, reject) => {
    // 1、执行ajax请求
    let promise =
      ReqType.GET === type
        ? axios.get(url, { headers: headers, params: data })
        : axios.post(url, data, { headers });

    // 2、成功就resolve
    // 3、 失败，不调用reject（如果调用，那么外面就会执行catch中的错误处理），显示异常信息。
    promise
      .then((response) => {
        let data = response.data;
        // console.log('ajax response', response);
        if (parseInt(data.status) !== 0) {
          //接口返回了错误信息，处理不成功的情况都在这里处理了
          throw new Error(data.msg);
        }
        //这里直接返回data数据
        return resolve(data.data);
      })
      .catch((err) => {
        //获取请求错误码
        let status = err?.response?.status || 0;
        console.log("ajax err", status, err.message);
        //调用回调函数
        callback();
        if (status === 0) {
          message.error(err.message);
        }
        //判断如果是，没有访问权限，并且不在登录页面，自动跳转到登录页面
        else if (status === 401) {
          //通知redux需要重新登录
          const { dispatch } = store;
          //清除本地存储的用户信息
          //userOptions.delUserInfo();
          setTimeout(() => {
            console.log("页面跳转，通知redux授权异常，跳转到登录页面");
            dispatch({
              type: "AUTH_UNAUTHORIZED",
            });
          }, 2000);
          //设置定时器，显示错误显示2秒以后自动跳转到登录页面
          message.error("401无权访问");
        } else {
          message.error("网络请求出错");
        }
      });
  });
};

//下载文件使用
export const downloadFile = async (_url, filename, params) => {
  const token = userOptions.getToken();

  const response = await axios({
    url: BaseUrl + _url,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      token: `${token}`,
    },
    params: {...params}
  });

  // console.log('response,', response);

  //type: 'application/pdf'
  //type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, filename)
};

//获取文件内容
export const getUrlFile = async (_url, params) => {
  const token = userOptions.getToken();
  const response = await axios({
    url: BaseUrl + _url,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      token: `${token}`,
    },
    params: {...params}
  });

  // console.log('response,', response);
  return response.data;
};