import { Button, Checkbox, Divider, Row, Space } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { nanoid } from "nanoid";
import LabelDiv from "./label-div";

//cbm 表示有多少个方
export default function PalletLabel({
  customerName,
  shipTo,
  cntr,
  qty,
  cbm,
  mark,
}) {
  const [pages, setPages] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [ppi, setPpi] = useState(null);
  //打印组件
  const componentRef = useRef(null);

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    // console.log("PalletLabel", customerName, shipTo, cntr, qty, cbm);
    //1.计算需要打印托盘标的数量
    calcPageTotal();
  };

  //计算需要打印托盘标的数量
  const calcPageTotal = () => {
    const num = Math.ceil(cbm / 2.0) + 1;
    // console.log("calcPageTotal", num);
    setPages(Array(num).fill(0));
  };

  //打印组件
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        {pages.map((_, index) => {
          return (
            <Row align={"center"} style={{ width: "100%" }} key={nanoid()}>
              <div
                style={{
                  width: "calc(4in - 10px)",
                  height: "calc(6in - 10px)",
                  position: "relative",
                  margin: "0px 0px",
                  // backgroundColor: "red",
                  padding: "5px",
                }}
              >
                <LabelDiv
                  total={pages.length}
                  page={index + 1}
                  customerName={customerName}
                  shipTo={shipTo}
                  cntr={cntr}
                  qty={qty}
                  mark={mark}
                  showPage={showPage}
                />
              </div>
              <Divider id="printButton" />
            </Row>
          );
        })}
      </div>
    );
  });

  //点击打印，保存到PDF的处理
  const handlePrint = useReactToPrint({
    documentTitle: customerName + "-" + cntr + "-" + shipTo + ".pdf", // specify the file name here
    pageStyle: "@page { size: 101.6mm 152.4mm; page-break-after: always; }",
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return <div>{shipTo}</div>;
    },
  });

  useEffect(() => {
    const calculatePpi = () => {
      const devicePpi = window.devicePixelRatio * 96;
      // console.log("devicePpi", devicePpi);
      setPpi(devicePpi);
    };

    calculatePpi();
    window.addEventListener("resize", calculatePpi);

    return () => {
      window.removeEventListener("resize", calculatePpi);
    };
  }, []);

  //是否显示顺序吗
  const onChangeShowPage = (e) => {
    setShowPage(e.target.checked);
  };

  return (
    <div style={{ width: "100%" }}>
      <Row align={"center"}>
        <Space>
          <Button onClick={handlePrint} type="primary">
            Print
          </Button>
          <Checkbox
            style={{ marginLeft: "20px" }}
            defaultChecked={showPage}
            onChange={onChangeShowPage}
          >
            Show Label NO.
          </Checkbox>
        </Space>
      </Row>
      <Divider />
      <Space direction="vertical" style={{ width: "100%" }}>
        <ComponentToPrint ref={componentRef} />
      </Space>
    </div>
  );
}
