import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Row,
  Col,
  message,
  Select,
  Divider,
  Tooltip,
  InputNumber,
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import { listApi, saveApi, deleteApi } from "../../api/own-chassis";
import {
  listApi as listLogApi,
  saveApi as saveLogApi,
  deleteApi as deleteLogAPi,
} from "../../api/own-chassis-log";
import validator from "validator";
import moment from "moment";
import { SketchPicker } from "react-color";
import { formatCurrency } from "../../utils/numUtils";
import ChassisLogPage from "./chassis-log";

export default function ChassisEquitementPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);
  const [bgColor, setBgColor] = useState("#ffffff");
  const [fontColor, setFontColor] = useState("#000000");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});
  const [addForm] = Form.useForm(); // 获取表单实例的引用

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      name: searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    // console.log("111111111111111111", addForm.getFieldsValue());
    editRef.current = {};
    addForm.resetFields();
    setShowAddModal(false);
  };

  //关闭编辑客户对话框
  const handleCloseLogModal = () => {
    editRef.current = {};
    setShowLogModal(false);
  };

  //保存客户信息
  const handleLogSave = async (values) => {
    // console.log("handleSave,", values);
    await saveLogApi({ ...values, chassisId: editRef.current["id"] });
    handleCloseLogModal();
    message.success("Success");
    getListData();
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    await saveApi({ ...values, bgColor, fontColor });
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  //添加或者删除元素
  function addOrRemoveFromArray(arr, value) {
    // 修改成只展开一行
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1); // 如果存在，则删除
    } else {
      arr = [value];
    }
    return arr;
  }

  const columns = [
    {
      title: "ID #",
      dataIndex: "id",
      key: "id",
    },
    // { title: "Identifier #", dataIndex: "identifier", key: "identifier" },
    {
      title: "Chassis Number",
      dataIndex: "chassisNumber",
      key: "chassisNumber",
      render: (_, record) => (
        <span
          style={{
            color: record["fontColor"],
            backgroundColor: record["bgColor"],
            cursor: "pointer",
            padding: "1px 3px",
          }}
          onClick={(e) => {
            e.preventDefault();
            setExpandedRowKeys([
              ...addOrRemoveFromArray([...expandedRowKeys], record["id"]),
            ]);
          }}
        >
          {record["chassisNumber"]}
        </span>
      ),
    },
    // {
    //   title: "Font Color",
    //   dataIndex: "fontColor",
    //   key: "fontColor",
    //   render: (_, record) => (
    //     <div>
    //       <Space>
    //         <div
    //           style={{
    //             width: "10px",
    //             height: "10px",
    //             color: record["fontColor"],
    //             backgroundColor: record["fontColor"],
    //           }}
    //         ></div>
    //         {record["fontColor"]}
    //       </Space>
    //     </div>
    //   ),
    // },
    // {
    //   title: "BG Color",
    //   dataIndex: "bgColor",
    //   key: "bgColor",
    //   render: (_, record) => (
    //     <div>
    //       <Space>
    //         <div
    //           style={{
    //             width: "10px",
    //             height: "10px",
    //             color: record["fontColor"],
    //             backgroundColor: record["bgColor"],
    //           }}
    //         ></div>
    //         {record["bgColor"]}
    //       </Space>
    //     </div>
    //   ),
    // },
    {
      title: "Income",
      dataIndex: "income",
      key: "income",
      render: (amount) => formatCurrency(amount),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (amount) => formatCurrency(amount),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark) => {
        return (
          <Tooltip title={remark}>
            <div>{remark}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Update Time",
      dataIndex: "updateTime",
      key: "updateTime",
      render: (updateTime) => {
        return (
          <span>
            {moment.utc(updateTime).local().format("MM/DD HH:mm ddd")}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "190px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size={1}>
          <Button
            type="link"
            onClick={(e) => {
              e.preventDefault();
              editRef.current = record;
              setShowLogModal(true);
            }}
          >
            Log
          </Button>
          <a
            onClick={(e) => {
              e.preventDefault();
              editRef.current = record;
              addForm.setFieldsValue({ ...record });
              setBgColor(record["bgColor"] || "0xfff");
              setFontColor(record["fontColor"] || "0xfff");
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the chassis"
            description="Are you sure to delete this chassis?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) => setSearchForm(e.target.value)}
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        extra={
          <Button
            onClick={() => {
              editRef.current = {};
              addForm.resetFields();
              setAddLoading(true);
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Chassis
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items`, total }}
          expandable={{
            // columnWidth: 0,
            showExpandColumn: false,
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: (record) => (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  marginTop: "-10px",
                  // backgroundColor: "red",
                }}
              >
                <ChassisLogPage chassisId={record["id"]} />
              </div>
            ),
          }}
        />
      </Card>
      <Modal
        title="Edit Own Chassis"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 22 }}
          form={addForm}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          // initialValues={{ ...editRef.current }}
          onChange={(e) => setAddLoading(false)}
          style={{ paddingTop: "25px" }}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Chassis Number"
            name="chassisNumber"
            rules={[
              { required: true, message: "Please input Chassis number." },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Identifier #"
            name="identifier"
            rules={[
              {
                required: true,
                message: "Please input the Identifier.",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ backgroundColor: bgColor, color: fontColor }}>
                    Background Color
                  </div>
                }
                name="bgColor"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select background color.",
                //   },
                // ]}
              >
                <SketchPicker
                  color={bgColor}
                  onChange={(c) => {
                    // console.log("bgColor onChange, ", c);
                    addForm.setFieldValue({
                      bgColor: c.hex,
                    });
                    setBgColor(c.hex);
                    setAddLoading(false);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<div style={{ color: fontColor }}>Font Color</div>}
                name="fontColor"
                // rules={[
                //   { required: true, message: "Please select font color." },
                // ]}
              >
                <SketchPicker
                  color={fontColor}
                  onChange={(c) => {
                    // console.log("fontColor onChange, ", c);
                    addForm.setFieldValue({
                      fontColor: c.hex,
                    });
                    setFontColor(c.hex);
                    setAddLoading(false);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item label="Remark" name="remark">
            <Input.TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      {/* 添加chassis日志 */}
      <Modal
        title="Edit Chassis Log"
        open={showLogModal}
        onCancel={(e) => handleCloseLogModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleLogSave}
          autoComplete="off"
          preserve={false}
          onChange={(e) => setAddLoading(false)}
          style={{ paddingTop: "25px" }}
        >
          <Form.Item
            label="Type #"
            name="typeId"
            rules={[
              {
                required: true,
                message: "Please select the type.",
              },
            ]}
          >
            <Select>
              <Select.Option value={0}>Cost</Select.Option>
              <Select.Option value={1}>Income</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please input amount." }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Content"
            name="name"
            rules={[{ required: true, message: "Please input content." }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseLogModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
