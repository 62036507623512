import { Form, Input, Modal } from "antd";
import React, { useEffect } from "react";

export default function ItemForm(props) {
  const [form] = Form.useForm();
  useEffect(() => {
    if(props.value != null) {
      form.setFieldValue("name", props.value);
    }
  }, [props.value]);
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{ name: props.value }}
      >
        <Form.Item name="name" label={props.label}>
          <Input
            onBlur={(e) => {
              console.log("onBlur", e.target.value, props.value);
              if (
                e.target.value !== undefined &&
                e.target.value !== props.value
              ) {
                Modal.confirm({
                  title: `Update ${props.label}?`,
                  onOk: () => props.handleSave(e.target.value),
                  onCancel: () => {
                    // console.log("canceled");
                    // 恢复原来的值
                    form.setFieldValue("name", props.value);
                  },
                });
              }
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
