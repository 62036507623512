import {
  Button,
  Divider,
  Row,
  Space,
  Table,
  Col,
  Typography,
  Modal,
  Tag,
  Dropdown,
  message,
  Tooltip,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  saveApi,
  getList,
  updateStatusApi,
  deleteApi,
} from "../../api/invoice";
import moment from "moment";
import InvoicePrintModal from "../../components/invoice-print-modal";
import InvoiceEditModal from "../../components/invoice-edit-modal";
import { formatMoney } from "../../utils/invoiceUtils";

const { Text } = Typography;

//账单状态
const invoiceStatusDef = [
  { name: "INIT", color: "red" },
  { name: "Confirmed", color: "green" },
  { name: "Paid", color: "gray" },
];

export default function LoadBilling(props) {
  const [open, setOpen] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  const [printTitle, setPrintTitle] = useState(null);
  const [addType, setAddType] = useState(null);
  const [modalTitle, setModalTitle] = useState(
    "Destination Revenue(Invoice/AR)"
  );
  const [invoiceList, setInvoiceList] = useState([]);
  //获取到Load的基本信息
  const { record: baseInfo } = props;
  const editInvoiceRef = useRef(null);
  const printInvoiceRef = useRef(null);

  // console.log("baseInfo", baseInfo);

  useEffect(() => {
    getInvoiceListData();
  }, []);

  //获取这个Load的所有账单记录
  const getInvoiceListData = async () => {
    const res = await getList({ loadId: baseInfo.id });
    // console.log("res,", res);
    setInvoiceList(res || []);
  };

  //点击了编辑
  const handleEdit = (record) => {
    editInvoiceRef.current = record;
    const type = ["AR", "AP", "DC"];
    handleOpenModal(type[record.invoiceType]);
  };

  //计算使用了多少天
  function calculateDateDiff(startDateStr, endDateStr) {
    var defaultYear = new Date().getFullYear(); // 获取当前年份
    // 确保使用 'MM/DD' 格式
    var startDate = new Date(`${defaultYear}/${startDateStr}`);
    var endDate = new Date(`${defaultYear}/${endDateStr}`);
    var timeDiff = endDate - startDate;
    var dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));
    return dayDiff;
  }
  //打开编辑或者添加框
  const handleOpenModal = (type) => {
    const name = {
      AR: "Destination Revenue(Invoice/AR)",
      DC: "Origin Revenue/Cost(D/C Note)",
      AP: "Destination Cost(AP)",
    };
    let chassisTime = "";
    if (baseInfo["pickupAptDate"] && baseInfo["returnAptDate"]) {
      const startDate = baseInfo["pickupAptDate"].split(" ")[0];
      const endDate = baseInfo["returnAptDate"].split(" ")[0];
      chassisTime =
        startDate +
        "-" +
        endDate +
        ` (${calculateDateDiff(startDate, endDate) + 1} DAYS)`;
    }
    setModalTitle(
      baseInfo["customerName"] +
        " " +
        name[type] +
        " MBL#" +
        baseInfo["mbl"] +
        " CNT#" +
        baseInfo["container"] +
        " " +
        chassisTime +
        " " +
        baseInfo["chassisNo"]
    );
    setAddType(type);
    setOpen(true);
  };

  //处理Action被点击的事件
  const handleActionClick = async (action, record) => {
    printInvoiceRef.current = null;
    console.log("handleActionClick", action, record);
    if (action === "delete") {
      Modal.confirm({
        title: "Are you sure?",
        onOk: async () => {
          // console.log("Confirmed!");
          await deleteApi(record.id);
          //刷新列表
          getInvoiceListData();
        },
      });
    } else if (action === "print") {
      printInvoiceRef.current = {
        ...record,
        loadNumber: baseInfo["container"],
        mblNumber: baseInfo["mbl"],
      };
      setPrintOpen(true);
    }
  };

  //处理Action中的Status被点击的事件
  const handleStatusClick = async (status, record) => {
    // console.log("handleStatusClick", status, record);
    if (record["status"] !== status) {
      await updateStatusApi({
        invoiceId: record.id,
        status,
      });
      getInvoiceListData();
      message.success("Success");
    }
  };

  const columns = [
    {
      title: "",
      width: "80px",
      render: (record) => {
        const type = ["AR", "AP", "D/C"];
        return <Tag>{type[record["invoiceType"]]}</Tag>;
      },
    },
    {
      title: "Invoice No.",
      dataIndex: "id",
      width: "130px",
      render: (_, record) => {
        if (record["invoiceType"] === 1) {
          return (
            <a href="#" onClick={(e) => handleEdit(record)}>
              view
            </a>
          );
        }
        const no = record["id"].toString().padStart(5, "0");
        return (
          <a href="#" onClick={(e) => handleEdit(record)}>
            {(record["invoiceType"] === 0 ? "IN-" : "DC-") + no}
          </a>
        );
      },
    },
    {
      title: "Party",
      dataIndex: "partyName",
      render: (partyName) => {
        return (
          <div style={{ minWidth: "250px" }}>
            <Tooltip title={partyName}>{partyName}</Tooltip>
          </div>
        );
      },
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      width: "130px",
      align: "right",
      render: (_, record) => {
        const number = (record["invoiceType"] !== 1 && record.amount) || 0;
        return <span>{formatMoney(number)}</span>;
      },
    },
    {
      title: "Cost",
      dataIndex: "cost",
      width: "130px",
      align: "right",
      render: (_, record) => {
        const number = (record["invoiceType"] === 1 && record.amount) || 0;
        return <span>{formatMoney(number)}</span>;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      width: "130px",
      align: "right",
      render: (_, record) => {
        const number = (record["invoiceType"] === 0 && record.amount) || 0;
        return <span>{formatMoney(number)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "110px",
      align: "center",
      render: (_, record) => {
        const status = invoiceStatusDef[record["status"]];
        return <Tag color={status?.color}>{status?.name}</Tag>;
      },
    },
    {
      title: "Post Date	",
      dataIndex: "postDate",
      align: "right",
      width: "110px",
      render: (_, record) => {
        return (
          <span>
            {moment.utc(record["updateTime"]).local().format("YYYY-MM-DD")}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: "110px",
      align: "right",
      render: (_, record) => {
        return (
          <span>
            {moment.utc(record["createTime"]).local().format("YYYY-MM-DD")}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "110px",
      align: "center",
      render: (_, record) => {
        const items = [
          {
            label: (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleActionClick("print", record);
                }}
              >
                Print/Email
              </div>
            ),
            key: "0",
          },
          {
            label: (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleActionClick("delete", record);
                }}
              >
                Delete
              </div>
            ),
            key: "1",
          },
          {
            type: "divider",
          },
          {
            label: "Status",
            key: "3",
            children: invoiceStatusDef.map((item, index) => {
              return {
                label: (
                  <div
                    style={{
                      color: record["status"] === index ? "gray" : "",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleStatusClick(index, record);
                    }}
                  >
                    {item.name}
                  </div>
                ),
                key: String(100 + index),
              };
            }),
          },
        ];
        return (
          <Dropdown
            placement={"bottomRight"}
            menu={{ items }}
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer", color: "gray" }}>
              <Space size={3}>
                Action
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  //处理Invoice保存
  const handleInvoiceSave = async (values) => {
    //账单类型
    const invoiceType = {
      AR: 0,
      AP: 1,
      DC: 2,
    };
    const _data = {
      invoiceId: editInvoiceRef.current?.id || null,
      partyId: values.vendor, //账单给谁的
      loadId: baseInfo.id, //Load关联
      invoiceType: invoiceType[addType], //Invoice类型
      invoiceDetails: JSON.stringify(values.dataList),
    };
    // console.log("handleInvoiceSave, ", _data);
    await saveApi(_data);
    setOpen(false);
    editInvoiceRef.current = null;
    //刷新列表
    getInvoiceListData();
  };

  return (
    <>
      <div style={{ width: "calc(100vw - 60px)", margin: "0px 0px 20px 0px" }}>
        <Row>
          <Col span={24}>
            <Row>
              <Space>
                <Button
                  style={{ backgroundColor: "#428ACA" }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    editInvoiceRef.current = null;
                    handleOpenModal("AR");
                  }}
                >
                  Destination Revenue(Invoice/AR)
                </Button>
                <Button
                  style={{ backgroundColor: "#428ACA" }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    editInvoiceRef.current = null;
                    handleOpenModal("DC");
                  }}
                >
                  Origin Revenue/Cost(D/C Note)
                </Button>
                <Button
                  style={{ backgroundColor: "#428ACA" }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    editInvoiceRef.current = null;
                    handleOpenModal("AP");
                  }}
                >
                  Destination Cost(AP)
                </Button>
              </Space>
            </Row>
            <Divider style={{ margin: "10px" }} />
            <Row>
              <div
                style={{ width: "calc(100% - 30px)", marginInline: "-40px" }}
              >
                <Table
                  bordered
                  rowKey={"id"}
                  columns={columns}
                  dataSource={invoiceList}
                  footer={null}
                  pagination={false}
                  summary={(pageData) => {
                    let revenue = 0;
                    let cost = 0;
                    let balance = 0;
                    // console.log("pageData", pageData);
                    pageData.forEach(({ invoiceType, amount }) => {
                      if (invoiceType === 1) {
                        cost += amount;
                      } else {
                        if (invoiceType === 0) {
                          balance += amount;
                        }
                        revenue += amount;
                      }
                    });
                    // Profit Percentage（利润率）= (利润 ÷ 销售额) × 100%
                    // Profit Margin（利润率）= (利润 ÷ 成本) × 100%
                    const profitPercentage =
                      (((revenue - cost) / revenue) * 100)
                        .toFixed(2)
                        .toString() + "%"; // 保留2位小数
                    const profitMargin =
                      (((revenue - cost) / cost) * 100).toFixed(2).toString() +
                      "%"; // 保留2位小数
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={3}
                            align="right"
                          >
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} align="right">
                            <Text type="danger">{formatMoney(revenue)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align="right">
                            <Text>{formatMoney(cost)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} align="right">
                            <Text>{formatMoney(balance)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={3}
                            align="right"
                          >
                            Amount
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={2}
                            align="right"
                          >
                            <Text type="danger">
                              {formatMoney(revenue - cost)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={10}></Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            colSpan={4}
                            align="right"
                          ></Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} align="right">
                            Amount
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} align="right">
                            Profit Percentage
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} align="right">
                            Profit Margin
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={4} align="right">
                            Total Profit
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} align="right">
                            {formatMoney(revenue - cost)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} align="right">
                            {profitPercentage}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={2} align="right">
                            {profitMargin}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={10}></Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      {/* invoice操作的Modal */}
      <InvoiceEditModal
        title={modalTitle}
        open={open}
        setOpen={setOpen}
        addType={addType}
        invoice={editInvoiceRef.current}
        handleClose={() => {
          editInvoiceRef.current = null;
          setOpen(false);
        }}
        handleInvoiceSave={(values) => handleInvoiceSave(values)}
      />
      {/* <Modal
        title={modalTitle}
        style={{
          top: 20,
        }}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1200}
        destroyOnClose={true}
        footer={null}
        maskClosable={false}
      >
        <LoadBillingEdit
          addType={addType}
          editRecord={editInvoiceRef.current}
          handleClose={() => {
            editInvoiceRef.current = null;
            setOpen(false);
          }}
          handleInvoiceSave={(values) => handleInvoiceSave(values)}
        />
      </Modal> */}
      {/* invoice保存成PDF的Modal */}
      <InvoicePrintModal
        open={printOpen}
        invoice={printInvoiceRef.current}
        title={printTitle}
        setPrintOpen={setPrintOpen}
      />
    </>
  );
}
