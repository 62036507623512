import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Select,
  Tooltip,
  Switch
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  listApi,
  saveApi,
  deleteApi,
  updateUsernameApi,
} from "../../api/customer";
import validator from "validator";

export default function CustomerPage() {
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUsernameAddModal, setShowUsernameAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState({ name: "name" });

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});
  const editUsernameRef = useRef({});

  useEffect(() => {
    getListData();
  }, []);

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      ...{
        [searchForm.name]: searchForm.value,
      },
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    setShowAddModal(false);
  };

  //关闭编辑客户对话框
  const handleCloseUsernameModal = () => {
    editUsernameRef.current = {};
    setShowUsernameAddModal(false);
  };

  //保存用户名和密码信息
  const handleSaveUsername = async ({ id, username, password, password2, showAppt }) => {
    // console.log("handleSaveUsername,", username, password, password2);
    if (!username || !validator.isEmail(username)) {
      message.error("Username Error");
      return;
    }

    if (password.trim().length !== password2.trim().length) {
      message.error("Password Error");
      return;
    }

    showAppt  =  showAppt == true ? 1 : 0;
    if(password.trim().length == 0) {
      await updateUsernameApi({ id, username, showAppt });
    } else {
      await updateUsernameApi({ id, username, password, showAppt });
    }
    handleCloseUsernameModal();
    getListData();
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    await saveApi(values);
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (companyName) => {
        return (
          <div
            style={{
              width: "160px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {companyName}
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      // width: "350px",
      render: (_, { address }) => (
        <div
          style={{
            width: "300px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip title={address}>{address}</Tooltip>
        </div>
      ),
    },
    {
      title: "Remark",
      key: "remark",
      width: "200px",
      dataIndex: "contactInfo",
      render: (_, { contactInfo }) => (
        <div
          style={{
            width: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip
            placement="topLeft"
            title={contactInfo}
            overlayInnerStyle={{ whiteSpace: "pre-wrap" }}
          >
            {contactInfo}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, record) => {
        return (
          <Space>
            <div>{record["username"]}</div>
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault();
                editUsernameRef.current = {
                  username: record["username"],
                  id: record["id"],
                  password: "",
                  password2: "",
                  showAppt:record["showAppt"]
                };
                setShowUsernameAddModal(true);
              }}
            >
              edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      // width: "220px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={(e) => {
              editRef.current = record;
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the customer"
            description="Are you sure to delete this customer?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) =>
          setSearchForm({ ...searchForm, value: e.target.value })
        }
        allowClear
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        extra={
          <Button
            onClick={() => {
              editRef.current = {};
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Customer
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items` }}
        />
      </Card>
      <Modal
        title="Edit Customer"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{ ...editRef.current }}
          onChange={(e) => setAddLoading(false)}
          style={{ paddingTop: "25px" }}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Nick Name"
            name="name"
            rules={[{ required: true, message: "Please input name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: "Please input company name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>
          <Form.Item label="Remark" name="contactInfo">
            <TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      {/* 添加账号 */}
      <Modal
        title="Edit Customs Username"
        open={showUsernameAddModal}
        onCancel={(e) => handleCloseUsernameModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSaveUsername}
          autoComplete="off"
          preserve={false}
          initialValues={{ ...editUsernameRef.current }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input username." }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <Input.Password />
          </Form.Item>

          <Form.Item label="Confirm Password" name="password2">
            <Input.Password />
          </Form.Item>
          <Form.Item label="Show Appointment in Customer WebSite" name="showAppt">
          <Switch />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseUsernameModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
