// token缓存key
export const userKey = "Website_ADMIN_USER_KEY";

// 每页默认显示的数据量
export const PAGE_SIZE = 40;

// 统计数据时段类型
export const REAL_TIME_TYPE = {
  HOUR: 1,
  MONTH: 2,
  YEAR: 3,
};

// 码头列表
export const TERMINALS = [
  "FMS",
  "APM",
  "LBCT",
  "PierA",
  "WBCT",
  "ETS",
  "ITS",
  "YTI",
  "TraPac",
  "C60",
  "C60/STC",
  "C60/STA",
  "PCT",
  "PCT/STL",
  "TTI",
  "TBA",
];

// 码头还空列表
export const EMPTY_TERMINALS = [
  "LBCT",
  "WBCT",
  "PCT",
  "FMS",
  "APM",
  "ITS",
  "YTI",
  "TraPac",
  "TTI",
  "PierA",
];

//集装箱类型
export const CNTR_SIZE = ["20GP", "40GP", "40HQ", "45HQ"];

export const SSL = ["EXX", "CMDU", "EGLV", "HDUJ","MATS", "MEDU", "ZIMU", "COSU","WHLU","OOLU", "HDMU", "SJHH", "待定","SHLM","TXZJ","MAEU","ONEY","YMJA"];

//Invoice状态
export const INVOICE_STATUS = {
  INIT: 0,
  CFMD: 1,
  PAID: 2,
};

//Invoice类型
export const INVOICE_TYPE = {
  AR: 0,
  AP: 1,
  DC: 2,
};

//Invoice类型
export const  WHEELED= [
   { 
    label: "Grounded",
    value: "0"
  },
  {
    label: "Wheeled",
    value: "1"

  }
];


//搜索类型类型
export const ONLY_CONTAINERS_SEARCH = ["ALL", "CNTR"];
//搜索包含或不包含
export const SEARCH_YES_OR_NO = ["YES", "NO"];
// 车架公司列表
export const POOL_CHASSIS = ["DCLI", "TRAC", "FLEXIVAN"];
