import React, { useState } from "react";
import { Modal } from "antd";
import PalletLable from "../pallet-label";

export default function PalletLabelPrintModal({
  title,
  open,
  load,
  setPrintOpen,
}) {
  // console.log("PalletLablePrintModal", load);
  return (
    <Modal
      title={title}
      // style={{
      //   top: 20,
      // }}
      open={open}
      onOk={() => setPrintOpen(false)}
      onCancel={() => setPrintOpen(false)}
      width={960}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
    >
      <PalletLable {...load} />
    </Modal>
  );
}
