import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Layout, Popconfirm, Space } from "antd";
import NotFound from "../error/404";
import DispatcherListPage from "../dispatcher";
import LeftSider from "../../components/left-sider";
import { logoutAction } from "../../redux/actions/auth";
import CustomerPage from "../customer";
import WarehousePage from "../warehouse";
import DriverPage from "../driver";
import TruckPage from "../driver/truck";
import LoadStatusConfigPage from "../load-status";
import ChargeTypeConfigPage from "../charge-type";
import AccountingPage from "../accounting";
import DispatcherListPageWithParams from "../loads";
import APListPage from "../accounting/ap-list";
import ARListPage from "../accounting/ar-list";
import ShippingLinePage from "../shipping-line";
import LoadAnalysisPage from "../load-analysis";
import ChassisEquitementPage from "../chassis";
import InvoiceSettingPage from "../invoice-setting";
import PoolChassisLogPage from "../pool-chassis-log";
import PaymentHistoryPage from "../payment-history";
import LoadDateAnalysisPage from "../analysis";
import OwnChassisPage from "../own-chassis";
import SystemUserListPage from "../system-user";
import ChassisTranstion from '../chassis-info'

const { Content, Header, Sider } = Layout;

function Admin({ userInfo, logoutAction }) {
  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, toggleIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
  };

  if (!userInfo) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <Header>
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "",
            position: "relative",
          }}
        >
          <div
            style={{
              fontFamily: "sans-serif",
              textTransform: "capitalize",
              fontSize: "22px",
              paddingLeft: "25px",
              color: "#4571C4",
              fontWeight: 500,
            }}
          >
            IDispatcher
          </div>
          <div style={{ position: "absolute", left: "250px", top: "1px" }}>
            <Space>
              <Popconfirm
                title="Are you sure you want to logout?"
                onConfirm={() => logoutAction()}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">{userInfo["username"]}</Button>
              </Popconfirm>
            </Space>
          </div>
          <div style={{ position: "absolute", right: "30px", top: "1px" }}>
            <Space>
              <button onClick={()=> {
                toggleIsOpen(!isOpen);
              }}>Own Chassis</button>
            </Space>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <LeftSider />
          
        </Sider>
        <Layout onClick={(e)=> {
      if(isOpen == true && e.clientX < window.innerWidth/2) {
        toggleIsOpen(false)
      }
      }
      }>
        <ChassisTranstion isOpen={isOpen} closeTranstion={()=> toggleIsOpen(false)} duration={250} />
          <Content style={{ margin: "0px 0px", minHeight: "80vh" }}>
            <Routes>
              <Route exact path="/" element={<Navigate to="/dispatcher" />} />
              <Route
                path="/dispatcher"
                exact
                element={<DispatcherListPageWithParams />}
              />
              <Route path="/loads" exact element={<DispatcherListPage />} />
              <Route
                path="/poolChassis"
                exact
                element={<PoolChassisLogPage />}
              />
              {/*<Route path="/ownChassis" exact element={<OwnChassisPage />} />*/}
              <Route
                path="/loadAnalysis"
                exact
                element={<LoadAnalysisPage />}
              />
              <Route
                path="/loadDateAnalysis"
                exact
                element={<LoadDateAnalysisPage />}
              />
              <Route path="/customer" exact element={<CustomerPage />} />
              <Route path="/accounting" exact element={<AccountingPage />} />
              <Route path="/apList" exact element={<APListPage />} />
              <Route path="/arList" exact element={<ARListPage />} />
              <Route
                path="/payment/history"
                exact
                element={<PaymentHistoryPage />}
              />
              <Route path="/warehouse" exact element={<WarehousePage />} />
              <Route path="/driver" exact element={<DriverPage />} />
              <Route path="/truck" exact element={<TruckPage />} />
              <Route
                path="/invoiceSetting"
                exact
                element={<InvoiceSettingPage />}
              />
              <Route
                path="/chassis"
                exact
                element={<ChassisEquitementPage />}
              />
              <Route
                path="/shippingLine"
                exact
                element={<ShippingLinePage />}
              />
              <Route
                path="/chargeType"
                exact
                element={<ChargeTypeConfigPage />}
              />
              <Route
                path="/loadStatus"
                exact
                element={<LoadStatusConfigPage />}
              />
              <Route
                path="/system/user"
                exact
                element={<SystemUserListPage />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default connect((state) => ({ userInfo: state.auth.userInfo }), {
  logoutAction,
})(Admin);
