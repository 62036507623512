import { HEADER_TITLE_UPDATE } from '../../action-types';

const initialState = {
  //Header区域显示的当前菜单路径
  title: '',
};

const headerReducer = (state = initialState, action) => {
  // console.log('authReducer', action.type);
  switch (action.type) {
    case HEADER_TITLE_UPDATE:
      //重新赋值
      return { ...state, title: action.payload };
    default:
      // console.log('headerReducer switch default');
      return state;
  }
};

export default headerReducer;
