import React from "react";
import { Form, Input, Button, Space, message } from "antd";
import styled from "styled-components";
import { registerApi, sendRegisterCodeApi } from "../../api/auth";
import { useForm } from "antd/es/form/Form";

const LoginForm = styled.div`
  width: 100%;
  padding-top: 35px;
  margin-left: 10px;
  padding-left: 10px;
  border: 1px solid rgb(229, 220, 238);
  border-radius: 5px;
`;

export default function RegisterForm(props) {
  const [regForm] = useForm(null);

  //提交表单且数据验证成功后回调事件
  const onFinish = async (values) => {
    // console.log("Success:", values);
    await registerApi(values);
    message.success("Success");
    props.switchTo("login");
  };

  //点击获取邮件验证码
  const sendCode = async (e) => {
    e.preventDefault();
    const username = regForm.getFieldValue("username");
    const password = regForm.getFieldValue("password");
    // console.log("regForm,", username, password);
    if (!username || !password || username.length < 3 || password.length < 6) {
      message.error("Please fill username and password first.");
      return;
    }
    const msg = await sendRegisterCodeApi({ username, password });
    // console.log("data,", data);
    message.success(msg);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // marginTop: "30px",
        // height: "100vh",
      }}
    >
      {/* <div style={{ width: "100%", textAlign: "left" }}>
        <h2 style={{ color: "#333" }}>Register</h2>
      </div> */}
      <LoginForm>
        <Form
          name="basic"
          form={regForm}
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 17,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input Email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Email-Code"
            name="code"
            rules={[
              {
                required: true,
                message: "Please input emial verify code!",
              },
            ]}
          >
            <Space>
              <Input />
              <Button onClick={sendCode}>Get Code</Button>
            </Space>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 18,
            }}
          >
            <Button block size="large" type="primary" htmlType="submit">
              Register
            </Button>
            <div>
              <p>
                To return to the login page,{" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    props.switchTo("login");
                  }}
                >
                  Login
                </a>
              </p>
            </div>
          </Form.Item>
        </Form>
      </LoginForm>
    </div>
  );
}
