import { Card, Col, Row, Space, Tooltip } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { invoiceTypeListApi } from "../../api/invoice";
import { INVOICE_TYPE } from "../../config";
import { formatMoney } from "../../utils/invoiceUtils";

export default function PayDriverPage() {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getPayListData();
  }, []);

  //获取所有需要支付的主体数据
  const getPayListData = async () => {
    const res = await invoiceTypeListApi(INVOICE_TYPE.AP);
    var data = new Map();
    res.forEach(({ partyName, amount, status }) => {
      var value = data.get(partyName) || {
        init: 0,
        cfmd: 0,
        paid: 0,
        partyName,
      };
      if (status === 0) {
        value["init"] += amount;
      } else if (status === 1) {
        value["cfmd"] += amount;
      } else {
        value["paid"] += amount;
      }
      data.set(partyName, value);
    });
    // 将 Map 转换为键值对数组，并按值进行降序排序
    const sortedArray = Array.from(data).sort(
      (a, b) => b[1]["init"] + b[1]["cfmd"] - (a[1]["init"] + a[1]["cfmd"])
    );
    // console.log(
    //   "sortedArray",
    //   sortedArray.map(([_, b]) => b)
    // );
    setDataList(sortedArray.map(([_, b]) => b));
  };

  return (
    <Card style={{ padding: "10px" }}>
      <Space size={[16, 16]} wrap>
        {dataList.map((item) => {
          return (
            <Card
              key={nanoid()}
              title={
                <Tooltip title={item["partyName"]}>
                  {item["partyName"].split("-")[0].trim()}
                </Tooltip>
              }
            >
              <div style={{ padding: "10px", width: "200px" }}>
                <Row>
                  <Col span={8}>INIT: </Col>
                  <Col span={12}>{formatMoney(item["init"])}</Col>
                </Row>
                <Row>
                  <Col span={8}>CFMD: </Col>
                  <Col span={12}>{formatMoney(item["cfmd"])}</Col>
                </Row>
                <Row>
                  <Col span={8}>PAID: </Col>
                  <Col span={12}>{formatMoney(item["paid"])}</Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </Space>
    </Card>
  );
}
