import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

export const connectQuickbook = (code, state, realmId) => {
  // console.log('params,', {...params});
  return ajax("/sys/invoice/connectCallback", {code, state, realmId}, reqType.GET);
};

export const generateInvoice = (id) => {
  // console.log('params,', {...params});
  return ajax("/sys/invoice/generateInvoice", {id}, reqType.GET);
};

//列表
export const listAllApi = (params) => {
  // console.log('params,', {...params});
  return ajax("/sys/invoice/getALlList", obj2formData(params), reqType.POST);
};

//列表
export const listApi = (params) => {
  // console.log('params,', {...params});
  return ajax("/sys/invoice/list", obj2formData(params), reqType.POST);
};

//更新选中Invoice的状态
export const updateAllStatusApi = (params) => {
  // console.log('params,', {...params});
  return ajax("/sys/invoice/allStatus", obj2formData(params), reqType.POST);
};

//获取total
export const totalApi = (params) => {
  // console.log('totalApi params,', {...params});
  return ajax("/sys/invoice/total", obj2formData(params), reqType.POST);
};

//指定Load的invoice列表
export const getList = (params) => {
  // console.log('params,', {...params});
  return ajax("/sys/invoice/getList", { ...params });
};

//添加
export const saveApi = (data) =>
  ajax("/sys/invoice/save", obj2formData(data), reqType.POST);

//删除
export const deleteApi = (id) =>
  ajax("/sys/invoice/delete", { id }, reqType.GET);

//更新状态
export const updateStatusApi = ({ invoiceId, status }) =>
  ajax(
    "/sys/invoice/status",
    obj2formData({ invoiceId, status }),
    reqType.POST
  );

//获取Invoice的详细记录
export const getDetailsApi = (invoiceId) =>
  ajax("/sys/invoice/getDetails", { invoiceId }, reqType.GET);

//下载Invoice详细
export const downloadApi = async (params, filename) =>
  await downloadFile("/sys/invoice/export", filename, params);

//下载可以导入QB的Invoice详细
export const downloadQBApi = async (params, filename) =>
  await downloadFile("/sys/accounting/qb/export", filename, params);

//获取所有的支付列表
export const invoiceTypeListApi = (invoiceType) =>
  ajax("/sys/invoice/invoiceTypeList", { invoiceType });

//获取所有客户的利润情况列表
export const profitListApi = (invoiceType) =>
  ajax("/sys/invoice/profitList", { invoiceType });
