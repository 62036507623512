import {
  Button,
  Divider,
  Row,
  Space,
  Table,
  Col,
  message,
  Popconfirm,
  Upload,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  uploadPackingListApi,
  getPackingListFileContentApi,
  deletePackingListApi,
} from "../../api/loads";
import PalletLablePrintModal from "../../components/pallet-label-print-modal";
import ExcelJS from "exceljs";
import xmljs from "xml-js";
import btoa from "btoa-lite";
//import clipboardCopy from "clipboard-copy";

//账单状态
const invoiceStatusDef = [
  { name: "INIT", color: "red" },
  { name: "Confirmed", color: "green" },
  { name: "Paid", color: "gray" },
];

export default function PackingList(props) {
  const [printOpen, setPrintOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [printTitle, setPrintTitle] = useState(null);
  const [excelRows, setExcelRows] = useState([]);
  const [excelCols, setExcelCols] = useState([]);

  //获取到Load的基本信息
  const { record: baseInfo } = props;
  const printPalletLableRef = useRef(null);

  // console.log("baseInfo", baseInfo);

  useEffect(() => {
    getPackingListData();
  }, []);

  //获取已经上传的Packing List数据
  const getPackingListData = async () => {
    const fileData = await getPackingListFileContentApi(baseInfo["id"]);
    // console.log("getPackingListData file", fileData.byteLength);
    if (fileData.byteLength) {
      const [cols, rows] = await readExcelFile(fileData);
      setExcelCols(cols);
      setExcelRows(rows);
    } else {
      setExcelCols([]);
      setExcelRows([]);
    }
    setLoading(false);
  };

  //删除Packing List文件
  const handleDeletePackingListFile = async () => {
    await deletePackingListApi(baseInfo["id"]);
    getPackingListData();
  };

  // 解析XML
  const getAllColors = (xmlString) => {
    var result1 = JSON.parse(
      xmljs.xml2json(xmlString, { compact: true, spaces: 4 })
    );
    const jsonMap = new Map(
      Object.entries(result1["a:theme"]["a:themeElements"]["a:clrScheme"])
    );
    // 遍历 Map 对象，保持顺序
    for (const [key, value] of jsonMap) {
      // console.log(key, value);
      if (value["a:sysClr"]) {
        console.log("color", value["a:sysClr"]["_attributes"]["lastClr"]);
      } else if (value["a:srgbClr"]) {
        console.log("color", value["a:srgbClr"]["_attributes"]["val"]);
      }
    }
  };

  //Excel地址转成Array下标
  const excelCellToIndex = (cellAddress) => {
    const columnLetters = cellAddress.match(/[A-Z]+/)[0];
    const rowNumber = parseInt(cellAddress.match(/\d+/)[0]);
    let columnIndex = 0;
    for (let i = 0; i < columnLetters.length; i++) {
      const letter = columnLetters[i];
      columnIndex = columnIndex * 26 + (letter.charCodeAt(0) - 65 + 1);
    }
    const rowIndex = rowNumber - 1;
    return [rowIndex, columnIndex - 1];
  };

  //读取Excel文件 返回Table 行和列的数据
  const readExcelFile = async (file) => {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);

    // const worksheet = workbook.getWorksheet("Sheet1"); // 根据需要替换为实际的工作表名称
    const worksheet = workbook.worksheets[0]; // 读取第一个工作表

    // console.log("workbook", workbook);
    // console.log("worksheet", worksheet);
    // getAllColors(workbook.model.themes["theme1"]);
    //获取合并的单元格信息
    const merges = {};
    for (let item of worksheet.model.merges) {
      // console.log("merges", item);
      // const [rowIndex, columnIndex] = excelCellToIndex(item.split(':')[0]);
      let key = item.split(":")[0];
      merges[key] = item;
      if (key.charAt(0) === "D") {
        key = key.replace(/D/g, "H");
        item = item.replace(/D/g, "H");
        merges[key] = item;
      }
    }
    // console.log("merges", merges);

    //获取图片资源
    const images = {};
    for (let item of worksheet.getImages()) {
      let buf = workbook.media[item.imageId].buffer;
      // const binaryString = String.fromCharCode.apply(null, buf);
      let binaryString = "";
      for (let i = 0; i < buf.length; i++) {
        binaryString += String.fromCharCode(buf[i]);
      }
      const base64String = (
        <Image src={"data:image/png;base64," + btoa(binaryString)} />
      );
      const row = item.range.tl.nativeRow;
      const col = item.range.tl.nativeCol;
      //计算下标位置的时候需要注意，如果是在合并的单元格内的，需要纠正为在合并单元的起始位置
      let key = `${row},${col}`;
      const mergeAddress = checkInMergesRange(
        convertToAddress(row, col),
        merges
      );
      if (mergeAddress) {
        const [rowIndex, colIndex] = excelCellToIndex(mergeAddress);
        key = `${rowIndex},${colIndex}`;
      }
      if (key in images) {
        images[key].push(base64String);
      } else {
        images[key] = [base64String];
      }
    }

    // 记录当前读取的是哪个FBA仓的数据
    let fbaName = "";
    let h_value = ""; //H列的内容填充
    let rowsData = worksheet.model.rows.map((row, index) => {
      // var rowSize = row.cellCount;
      // var numValues = row.actualCellCount;
      //console.log("单元格数量/实际数量:"+rowSize+"/"+numValues);
      // console.log("row", row);
      // cell.type单元格类型：1-合并单元格; 2-数值；3-字符串; 6-公式; 8-富文本richText
      // ValueType: {
      //   Null: 0,
      //   Merge: 1,
      //   Number: 2,
      //   String: 3,
      //   Date: 4,
      //   Hyperlink: 5,
      //   Formula: 6,
      //   SharedString: 7,
      //   RichText: 8,
      //   Boolean: 9,
      //   Error: 10,
      // },
      var rowData = row.cells.map((cell, index) => {
        let value = "";
        //获取下标地址
        const [rowIndex, columnIndex] = excelCellToIndex(cell.address);
        const key = `${rowIndex},${columnIndex}`;
        if (key in images) {
          value = images[key];
        } else {
          //Formula
          if (cell.type == 6) {
            value = cell.result;
            //初始化FBA仓名称
            if (cell.address.charAt(0) === "D") {
              fbaName = "";
              h_value = "";
            }
          } else if (cell.type == 0 || cell.type == 1) {
            //如果是Merge的值可以不用读取，因为已经在前面的地方读取过了
            value = "";
            if (cell.address.charAt(0) === "D" && fbaName.length > 1) {
              value = fbaName;
            }
            // console.log("fbaName", cell.address, fbaName);
          } else if (cell.type == 8) {
            //富文本读取处理
            let text = "";
            value = cell.value.richText.map((item) => {
              const { bold, color } = item?.font || {
                bold: false,
                color: { argb: undefined },
              };
              const argb = color?.argb || undefined;

              var cssColor = null;
              if (argb) {
                var argbValue = parseInt("0x" + argb); // Convert hex to decimal
                var alpha = (argbValue >> 24) & 0xff;
                var red = (argbValue >> 16) & 0xff;
                var green = (argbValue >> 8) & 0xff;
                var blue = argbValue & 0xff;
                cssColor =
                  "rgba(" +
                  red +
                  ", " +
                  green +
                  ", " +
                  blue +
                  ", " +
                  alpha / 255 +
                  ")";
              }
              // console.log(item, bold, argb);
              //记录文本内容
              text += item.text;
              return (
                <span
                  style={{
                    fontWeight: bold ? "bold" : "",
                    color: cssColor || "",
                  }}
                >
                  {item.text}
                </span>
              );
            });
            //初始化FBA仓名称
            if (cell.address.charAt(0) === "D") {
              fbaName = "";
            }
            //判断是UPS 或者 UPS拦截
            if (text.includes("UPS")) {
              if (text.includes("拦截")) {
                h_value = "UPS_RELABEL";
              } else {
                h_value = "UPS";
              }
            }
          } else {
            value = cell.value;
            if (
              cell.address.charAt(0) === "D" &&
              typeof value === "string" &&
              value.includes("Trucking-")
            ) {
              // fbaName = value.replace("Trucking-", "");
              // console.log("当前读取的FBA仓是: ", fbaName);
              fbaName = value;
            } else if (
              cell.address.charAt(0) === "D" &&
              typeof value === "string" &&
              value.includes("UPS") &&
              value.includes("换标")
            ) {
              fbaName = "";
              h_value = "UPS_RELABEL";
            } else if (
              cell.address.charAt(0) === "D" &&
              typeof value === "string" &&
              value.includes("UPS")
            ) {
              fbaName = "";
              h_value = "UPS";
            } else if (cell.address.charAt(0) === "D") {
              fbaName = "";
              h_value = "";
            }
          }
        }
        return value;
      });
      //填充H列数据
      if (
        h_value.length > 1 &&
        rowData.length > 0 &&
        rowData[0].toUpperCase().trim() !== "TOTAL"
      ) {
        rowData.push(h_value);
      }
      //添加到记录中
      return rowData.reduce(
        (result, current, index) => {
          const key = String.fromCharCode(65 + index); // 使用字符编码 A=65, B=66, C=67, ...
          result[key] = current;
          return result;
        },
        { id: (index + 1).toString() }
      );
    });

    // console.log("rowsData", rowsData);
    return [buildTableColums(rowsData, merges), rowsData];
  };

  //将 数字 转换成 A/B/C 字母
  function convertToLetter(number) {
    const charCode = 65 + number;
    return String.fromCharCode(charCode);
  }
  //坐标到 cell 地址
  function convertToAddress(rowIndex, colIndex) {
    const colName = convertToLetter(colIndex);
    return colName + (rowIndex + 1).toString();
  }
  //判断是否在单元格范围内
  function isCellInRange(cell, range) {
    const startCell = range.split(":")[0];
    const endCell = range.split(":")[1];
    const cellColumn = cell.charAt(0);
    const cellRow = parseInt(cell.substring(1));
    const startColumn = startCell.charAt(0);
    const startRow = parseInt(startCell.substring(1));
    const endColumn = endCell.charAt(0);
    const endRow = parseInt(endCell.substring(1));
    return (
      cellColumn >= startColumn &&
      cellColumn <= endColumn &&
      cellRow >= startRow &&
      cellRow <= endRow
    );
  }
  //获取地址的跨度
  function getRangeSpan(range) {
    const [startCell, endCell] = range.split(":");
    const startColumn = startCell.charAt(0);
    const endColumn = endCell.charAt(0);
    const startRow = parseInt(startCell.substring(1));
    const endRow = parseInt(endCell.substring(1));
    const columnSpan = endColumn.charCodeAt(0) - startColumn.charCodeAt(0) + 1;
    const rowSpan = endRow - startRow + 1;
    return { columnSpan, rowSpan };
  }
  //判断单元格地址是否在所有的合并访问内
  const checkInMergesRange = (cellAddress, merges) => {
    for (let key in merges) {
      if (isCellInRange(cellAddress, merges[key])) {
        //返回合并开始的起始位置地址
        return key;
      }
    }
    return null;
  };
  //动态构建Cell {rowSpan:1, colSpan: 1}
  const buildOnCell = (colName, rowIndex, merges) => {
    const cellAddress = colName + (rowIndex + 1).toString();
    if (cellAddress in merges) {
      //1.在合并的单元格的开始位置
      // console.log("cellAddress", cellAddress, merges[cellAddress]);
      const span = getRangeSpan(merges[cellAddress]);
      return { colSpan: span.columnSpan, rowSpan: span.rowSpan };
    }
    //2.在合并单元格中的位置，不渲染
    if (checkInMergesRange(cellAddress, merges)) {
      return { colSpan: 0 };
    }
    //3.不在合并内的
    return { colSpan: 1 };
  };
  //构建表格 setExcelCols
  const buildTableColums = (rowsData, merges) => {
    // console.log("buildTableColums merges", rowsData, merges);
    let cols = [];
    if (!rowsData || rowsData.length == 0) return;
    //表格支持行/列合并，使用 render 里的单元格属性 colSpan 或者 rowSpan 设值为 0 时，设置的表格不会渲染。
    for (let i = 0; i < Object.keys(rowsData[0]).length; i++) {
      const colName = convertToLetter(i);
      cols.push({
        title: colName,
        dataIndex: colName,
        align: colName === "H" ? "center" : "",
        key: colName,
        onCell: (_, rowIndex) => {
          return buildOnCell(colName, rowIndex, merges);
        },
        render: (value, rowData) => {
          // console.log("buildTableColums value", value, rowData);
          if (Array.isArray(value) && colName === "G") {
            return <Space direction="vertical">{value}</Space>;
          }
          //判断显示H列的打印label的按钮
          const DDate = rowData["D"];
          if (
            colName === "H" &&
            DDate &&
            typeof DDate === "string" &&
            DDate.includes("Trucking-")
          ) {
            const fbaData = rowsData.filter((item) => item["D"] === DDate);
            return (
              <Space direction="vertical">
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePrinterLable(fbaData, DDate);
                  }}
                >
                  {rowData["D"].replace("Trucking-", "")}
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleCopyFbaNumber(fbaData, DDate);
                  }}
                >
                  Copy
                </Button>
              </Space>
            );
          }
          //判断UPS
          if (colName === "H" && DDate) {
            if (
              typeof DDate === "string" &&
              DDate.includes("UPS") &&
              rowData["H"] === "UPS"
            ) {
              const upsData = rowsData.filter((item) => item["H"] === "UPS");
              const total = upsData.reduce(
                (result, current, index) => {
                  const { C, F } = current;
                  // console.log("handlePrinterLable F", C, F);
                  result["qty"] += parseFloat(C) || 0;
                  result["cbm"] += parseFloat(F) || 0;
                  return result;
                },
                { qty: 0, cbm: 0 }
              );
              return (
                <Space direction="vertical">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePrinterUPSLable({
                        ...total,
                        shipTo: "UPS",
                        mark: "UPS",
                      });
                    }}
                  >
                    UPS
                  </Button>
                </Space>
              );
            }
            if (rowData["H"] === "UPS_RELABEL") {
              // console.log("UPS拦截换标", typeof DDate, DDate);
              const upsData = rowsData.filter(
                (item) => item["H"] === "UPS_RELABEL"
              );
              const total = upsData.reduce(
                (result, current, index) => {
                  const { C, F } = current;
                  // console.log("handlePrinterLable F", C, F);
                  result["qty"] += parseFloat(C) || 0;
                  result["cbm"] += parseFloat(F) || 0;
                  return result;
                },
                { qty: 0, cbm: 0 }
              );
              return (
                <Space direction="vertical">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePrinterUPSLable({
                        ...total,
                        shipTo: "UPS",
                        mark: "RELABEL",
                      });
                    }}
                  >
                    RELABEL
                  </Button>
                </Space>
              );
            }
          }
          return <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>;
        },
      });
    }
    return cols;
  };

  const uploadProps = {
    name: "file",
    accept: ".xls, .xlsx",
    multiple: false,
    showUploadList: false,
    customRequest: async ({ file }) => {
      const [cols, rows] = await readExcelFile(file);
      // console.log("customRequest file", cols, rows, file);
      setExcelCols(cols);
      setExcelRows(rows);
      // 上传Packing List数据到后台
      await uploadPackingListApi({
        file,
        loadId: baseInfo["id"],
      });
      message.success("Success");
    },
    onChange(info) {
      // console.log("onChange", info);
    },
  };

  //处理 Label 打印
  const handlePrinterLable = (fbaData, fbaName) => {
    const total = fbaData.reduce(
      (result, current, index) => {
        const { C, F } = current;
        // console.log("handlePrinterLable F", C, F);
        result["qty"] += parseFloat(C) || 0;
        result["cbm"] += parseFloat(F) || 0;
        return result;
      },
      { qty: 0, cbm: 0 }
    );
    // console.log("handlePrinterLable", fbaName, fbaData, total, baseInfo);
    fbaName = fbaName.replace("Trucking-", "");
    printPalletLableRef.current = {
      customerName: baseInfo["customerName"],
      cntr: baseInfo["container"],
      shipTo: fbaName,
      mark: fbaName,
      //多少方
      cbm: total["cbm"],
      //多少箱
      qty: total["qty"],
    };
    setPrintOpen(true);
  };

  //打印UPS的托盘标
  const handlePrinterUPSLable = ({ qty, cbm, shipTo, mark }) => {
    printPalletLableRef.current = {
      customerName: baseInfo["customerName"],
      cntr: baseInfo["container"],
      shipTo,
      //多少方
      cbm,
      //多少箱
      qty,
      //大字体说明信息
      mark,
    };
    setPrintOpen(true);
  };

  //拷贝指定仓的FBA号
  const handleCopyFbaNumber = (fbaData, fbaName) => {
    //获取到指定FBA仓的数据
    const fbaNo = fbaData.map((item) => item["B"]);
    navigator.clipboard.writeText(fbaNo.join("\n"));
    //clipboardCopy(fbaNo.join("\n"));
    // console.log("handleCopyFbaNumber", fbaData, fbaName, fbaNo);
    message.success("Copy!");
  };

  return (
    <>
      <div style={{ width: "calc(100vw - 50px)", margin: "0px 0px 20px 0px" }}>
        <Row>
          <Col span={24}>
            <Row>
              <Space>
                <Upload {...uploadProps}>
                  <Button
                    style={{ backgroundColor: "#428ACA", color: "white" }}
                    icon={<UploadOutlined />}
                  >
                    Upload Packing List
                  </Button>
                </Upload>
                <Popconfirm
                  title="Delete the Packing List"
                  description="Are you sure to delete this Packing list?"
                  onConfirm={(e) => {
                    e.preventDefault();
                    handleDeletePackingListFile();
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>Delete</Button>
                </Popconfirm>
              </Space>
            </Row>
            <Divider style={{ margin: "10px" }} />
            <Row>
              <div
                style={{ width: "calc(100% - 30px)", marginInline: "-40px" }}
              >
                <Table
                  //不显示表头
                  // showHeader={false}
                  loading={loading}
                  dataSource={excelRows}
                  columns={excelCols}
                  pagination={false}
                  bordered
                  rowKey={"id"}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Pallet Lable 保存成PDF的Modal */}
      <PalletLablePrintModal
        open={printOpen}
        load={printPalletLableRef.current}
        title={printTitle}
        setPrintOpen={setPrintOpen}
      />
    </>
  );
}
