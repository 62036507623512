//从本地初始化用户信息到redux
export const INIT_USERINFO_FROM_LOCAL = "INIT_USERINFO_FROM_LOCAL";

//更新redux中的用户信息
export const AUTH_USERINFO_UPDATE = "AUTH_USERINFO_UPDATE";

//登录相关
export const AUTH_LOGIN_OK = "AUTH_LOGIN_OK";
//成功退出登录
export const AUTH_LOGOUT_OK = "AUTH_LOGOUT_OK";
//网络请求返回401响应
export const AUTH_UNAUTHORIZED = "AUTH_UNAUTHORIZED";
//用户名密码错误
export const AUTH_LOGIN_ERROR = "LOGIN_WITH_EMAIL_ERROR";

//菜单路径更换
export const HEADER_TITLE_UPDATE = "HEADER_TITLE_UPDATE";
