import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (params) =>
  {
    // console.log('params,', {...params});
    return ajax("/sys/payment/history/list", obj2formData(params), reqType.POST);
  }

//添加
export const saveApi = (data) =>
  ajax("/sys/payment/history/save", obj2formData(data), reqType.POST);

//删除
export const deleteApi = (id) =>
  ajax("/sys/payment/history/delete", { id }, reqType.GET);


//计算总额
export const sumTotalApi = (params) =>
  ajax("/sys/payment/history/total", obj2formData(params), reqType.POST);

