//普通对象转FormData格式
export const formatCntrs = (param) => {
  // console.log("param", JSON.stringify(param));
  if (param === undefined || param === null || param.trim().length < 11) {
    return null;
  }
  let value = param.trim();
  let cntrs = null;
  value = value
    .replaceAll(" ", "\n")
    .replaceAll(",", "\n")
    .replaceAll("，", "\n")
    .replaceAll("\t", "\n")
    .replaceAll("\r\n", "\n");
  // console.log("value,", value);
  if (value.indexOf(" ") > 0) {
    cntrs = value.split(" ");
  } else if (value.indexOf("\n")) {
    cntrs = value.split("\n");
  }
  let results = [];
  cntrs.forEach((val) => {
    val = val.trim();
    if (val.length === 11) {
      results.push(val);
    }
  });
  return results;
};

//过滤空柜行，按行分割
export const formatLines = (param) => {
  if (param === undefined || param === null || param.trim().length < 1) {
    return null;
  }
  let value = param.trim();
  let cntrs = null;
  value = value
    .replaceAll("，", " ")
    .replaceAll(",", " ")
    .replaceAll("\t", " ")
    .replaceAll("\r\n", " ")
    .replaceAll("\n", " ");
  cntrs = value.split(" ");
  // console.log("cntrs,", cntrs, value);
  let results = [];
  cntrs.forEach((val) => {
    val = val.trim();
    if (val.length > 0) {
      results.push(val);
    }
  });
  return results;
};
