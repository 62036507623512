import React, { useState } from "react";
import { Modal } from "antd";
import Invoice from "../../pages/dispatcher/invoice";

export default function InvoicePrintModal({
  title,
  open,
  invoice,
  setPrintOpen,
}) {
  return (
    <Modal
      title={title}
      style={{
        top: 20,
      }}
      open={open}
      onOk={() => setPrintOpen(false)}
      onCancel={() => setPrintOpen(false)}
      width={1200}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
    >
      <Invoice invoice={invoice} />
    </Modal>
  );
}
