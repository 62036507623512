import React, { createRef } from "react";
import {
  Card,
  Button,
  Table,
  Space,
  Row,
  Input,
  Popconfirm,
  message,
  Divider,
  Modal,
  Form,
  Radio,
  Select,
  Upload,
  Tooltip,
  Tabs,
  DatePicker,
  Tag,
} from "antd";
import { useState } from "react";
import { useRef } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FileAddOutlined,
  ReloadOutlined,
  PlusOutlined,
  InboxOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import VerticalLayout from "../../components/vertical-layout";
import { AiFillWarning } from "react-icons/ai";
import { BsSnow3 } from "react-icons/bs";
import { RiScales2Fill } from "react-icons/ri";
import { SiVitest } from "react-icons/si";
import { GiTripleGate } from "react-icons/gi"; //CUSTOMS
import { IoBoatOutline } from "react-icons/io5"; //FREIGHT
import { MdLocalParking } from "react-icons/md"; //PierPass
import { TbLetterC, TbLetterO, TbLetterW } from "react-icons/tb"; //CTF
import LoadBaseInfo from "./base-info";
import LoadDocuments from "./documents";
import LoadBilling from "./billing";
import PackingList from "./packing-list";
import LoadNotes from "./notes";
import LoadLogs from "./logs";
import {
  listApi,
  saveApi,
  deleteApi,
  getByIdsApi,
  updatePierPassApi,
  syncContainerStatusApi,
} from "../../api/loads";
import { listApi as listSLApi } from "../../api/shipping-line";
import { listApi as listDriverApi} from "../../api/driver";
import { uploadApi, deleteApi as delUploadApi } from "../../api/attachment";
import { selectListApi as customerListApi } from "../../api/customer";
import { selectListApi as warehouseListApi } from "../../api/warehouse";
import { selectListApi as statusListApi } from "../../api/loads/status";
import { listApi as listChassisApi, saveApi as saveChassisApi} from "../../api/own-chassis";
import { connectQuickbook,generateInvoice } from "../../api/invoice";
import FloatingToolbar from "../../components/floating-bar";
//import clipboardCopy from "clipboard-copy";
import DoubleClickDatePicker from "../../components/double-click-datepicker";
import DoubleClickDateTimePicker from "../../components/double-click-timepicker/timepicker";
import DoubleClickInput from "../../components/double-click-input";
import DoubleClickSelect from "../../components/double-click-select";
import { CNTR_SIZE, ONLY_CONTAINERS_SEARCH, SEARCH_YES_OR_NO, TERMINALS, SSL, WHEELED } from "../../config";
import DoubleClickTimePicker from "../../components/double-click-timepicker";
import { formatCntrs, formatLines } from "../../utils/searchUtils";
import numeral, { options } from "numeral";
import { BaseUrl } from "../../api/config";
import { isMobile } from "react-device-detect";
import { userOptions } from "../../utils/storageUtils";
import MySpin from "../../components/spin";
import WebSocket from "../../api/webSocket";
import * as XLSX from 'xlsx';


const { RangePicker } = DatePicker;
const { confirm } = Modal;

const StyledTable = styled(Table)`
  .ant-table-body {
    position: relative;
    overflow-x: auto;
    overflow-y: scroll;
    min-height: calc(100vh - 300px); /* 设置最大高度 */
    max-height: calc(100vh - 200px); /* 设置最大高度 */
    &::-webkit-scrollbar {
      height: 8px; /* 滚动条高度 */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #687d96; /* 滚动条颜色 */
    }
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* 滚动条轨道颜色 */
    }
    .ant-table-scroll {
      position: static;
    }
    .ant-table-placeholder {
      visibility: hidden;
      height: 0;
    }
    .ant-table-cell {
      border: 0.5px solid #111;
    }
    .ant-table-content {
      overflow: visible;
      table {
        table-layout: fixed;
        width: 100%;
        th {
          position: sticky;
          top: 0;
          background: #fff;
        }
        td,
        th {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 0;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      display: block;
      height: 8px; /* 滚动条高度 */
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
`;

const SolidLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${(props) => props.color || "black"};
  padding-top: 2px;
`;

const { Dragger } = Upload;
const { TextArea } = Input;

const GrayIcon = styled.div`
  color: ${(props) => props.color || "#eee"};
`;

const TipContent = styled.div`
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  color: #000;
  width: ${(props) => (props.width ? props.width + "px" : "auto")};
`;

//仓库类型
const warehouseTypes = [
  "",
  "Warehouse area",
  "Transload warehouse",
  "Residental area",
  "Construction area",
];

export default function DispatcherListPage({ comeFrom }) {
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchForm, setSearchForm] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabKey, setTabKey] = useState(1);
  //下拉框数据
  const [customerList, setCustomerList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [loadStatusList, setLoadStatusList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  //上传文件列表
  const [fileList, setFileList] = useState([]);
  //是否已经初始化完成
  const [initDone, setInitDone] = useState(false);

  //分页
  const pageRef = useRef(1);
  const sizeRef = useRef(comeFrom ? 200 : 10);
  const customersRef = useRef({});
  const loadStatusRef = useRef({});
  const shippingLineRef = useRef({});
  const tableColumnRef = useRef(null);
  //导航跳转
  const navigate = useNavigate();
  //添加Loads的Form对象
  const [addForm] = Form.useForm(); // 获取表单实例的引用
  //多选选择了哪些行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //是否选择了选中所有行
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);

  const [webSocketValue, setWebSocketValue] = useState('');

  const [chassis,setChassis] = useState([]);
  const [refresh,setRefresh] = useState(false);

  const onMessage = (message) => {
    //const webSocketMessage = JSON.parse(message.data);
      //update dataList data in memory
      var data = JSON.parse(message.data);
      if( data.key == 'ping') {
        //console.log("ping receive");
      } else
      {
        setWebSocketValue(message.data);
      }
      
  }
  const ping = () => {
    sendData("-1", "ping", "ping");
  }

  const makeConnection = () => {
    WebSocket.getInstance().setCallback(onMessage);
    var intervalId = WebSocket.getInstance().getPingInvtervalId();
    console.log('get  websocket ping ' + intervalId);
    if(intervalId >=0 ) {
      clearInterval(intervalId);
      console.log('clear websocket ping');
    }
    intervalId = setInterval(ping, 10000);
    WebSocket.getInstance().setPingInvtervalId(intervalId);
    console.log('set websocket ping ' + intervalId);
  }
  const sendData = (id, key, value) => {
    var data = {
      id: id,
      key: key,
      value: value
    }
    try {
      var sendSuccess = WebSocket.getInstance().send(JSON.stringify(data));
      if(data.value != "ping") {
        setRefresh(!refresh);
      }
      //var a = id.func();
      if(!sendSuccess) {
        //message.error("error send sync message, please try again");
        WebSocket.getInstance().close();
        makeConnection();
        console.log("send failed resent websocket");
        WebSocket.getInstance().send(JSON.stringify(data)); //send again
      }
    } catch(e) {
      //message.error("error send sync message, please try again");
      WebSocket.getInstance().close();
      makeConnection();
      console.log("exception resent websocket");
      WebSocket.getInstance().send(JSON.stringify(data)); //send again
    }
  }

  const setValueFromWebSocket = (id, key, value) => {
    var result = value;
    if(key=='chassisNo') {
      //console.log('setValueFromWebSocket ' +  value);
    }
    if(webSocketValue.length !== 0) {
      const webSocketMessage = JSON.parse(webSocketValue);
      if(webSocketMessage["id"] === id && webSocketMessage["key"] === key && webSocketMessage["value"].length !== 0 ) {
        result = webSocketMessage["value"];
        var findIndex = dataList.findIndex((data) => data.id == webSocketMessage.id);
        var data = dataList[findIndex];
        data[webSocketMessage.key] = webSocketMessage["value"];
      }
    }
    return result;
  }
  

  useEffect(() => {
    getInitData();
    makeConnection();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const reamId = urlParams.get('realmId');
    if(code !=null && state !=null && reamId != null) {
      console.log(code + ' ' + state + ' ' + reamId);
      connectQuickbook(code, state, reamId);
    }
    urlParams.delete(code);
    urlParams.delete(state);
    urlParams.delete(reamId);
  },[])
  //初始化数据
  const getInitData = async () => {
    await getCustomersData();
    await getLoadStatusListData();
    await getSLData();
    await getListData();
    await getOwnChassis();
    await getDriverData();
    //设置显示列
    buildTableColumn();
  };
  //设置显示列
  const buildTableColumn = () => {
    const userInfo = userOptions.getUserInfo();
    // console.log(
    //   "columnJson",
    //   userInfo["columnJson"],
    //   userInfo["columnJson"].length
    // );
    tableColumnRef.current = null;
    if (userInfo["columnJson"] && userInfo["columnJson"].length > 2) {
      tableColumnRef.current = JSON.parse(userInfo["columnJson"]);
    }
    // console.log("tableColumnRef.current", tableColumnRef.current);
    setInitDone(true);
  };
  //get Driver 
  const getDriverData = async () => {
    const res = await listDriverApi({size: 10000});
    setDriverList(res.list);
  }
  //获取船司列表
  const getSLData = async () => {
    const res = await listSLApi({ size: 10000 });
    res.list.forEach((item) => {
      shippingLineRef.current[item["scac"].toUpperCase()] = item["freeDays"];
    });
  };
  //get Own Chassi from t_chassis
  const getOwnChassis = async () => {
    const res = await listChassisApi({ size: 10000 });
    setChassis(res.list.map((item) => ({ value: item.chassisNumber, label: item.chassisNumber, id: item.id, carryContainer: item.carryContainer})));
  }
  //客户下拉列表
  const getCustomersData = async () => {
    const result = await customerListApi();
    setCustomerList(result);
    result.forEach((item) => {
      customersRef.current[item.id] = item.name;
    });
  };
  //Load状态下拉列表
  const getLoadStatusListData = async () => {
    const result = await statusListApi();
    // console.log("getLoadStatusListData, ", result);
    setLoadStatusList(result);
    result.forEach((item) => {
      loadStatusRef.current[item.id] = item;
    });
  };
  //仓库下拉列表
  const getWarehouseData = async (userId) => {
    const result = await warehouseListApi({ userId });
    setWarehouseList(result);
  };
  //选择了客户以后重新加载仓库列表
  const reloadWarehoseList = async (userId) => {
    addForm.setFieldsValue({
      consignee: null,
    });
    await getWarehouseData(userId);
  };

  //获取任务列表
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    setFileList([]);
    // console.log("searchForm", searchForm);
    if (
      searchForm["onlySearchContainers"] &&
      searchForm["onlySearchContainers"] === 1
    ) {
      if (searchForm["name"] && searchForm["name"].length > 0) {
        searchForm["name"] = formatCntrs(searchForm["name"]).join("\n");
      }
    }
    const result = await listApi({
      page,
      size,
      ...searchForm,
      comeFrom,
      statusList: searchForm["status"],
    });
    // console.log("result,", result);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
    setDataList(
      //计算还柜LFD
      result.list.map((item) => {
        const scac = item["mbl"].substring(0, 4).toUpperCase();
        if (item["pickupAptDate"]) {
          const [r_lfd, isToday] = addWorkingDays(
            item["pickupAptDate"],
            shippingLineRef.current[scac] || 3
          );
          return {
            ...item,
            r_lfd,
            rLfdIsToday: isToday,
          };
        }
        return item;
      })
    );
    setTotal(result.total);
    //判断是否有选择选中全部
    if (isSelectedAllRows) {
      //如果选择了选择全部，则把所有的数据id都加入到选择列表中
      const uniqueArray1 = [...new Set(result.list.map((item) => item.id))];
      const uniqueArray2 = [...new Set(selectedRowKeys)];
      const mergedArray = [...uniqueArray1, ...uniqueArray2];
      setSelectedRowKeys(mergedArray);
      const uniqueArray11 = [
        ...new Set(result.list.map((item) => item.container)),
      ];
    }
  };

  //添加或者删除元素
  function addOrRemoveFromArray(arr, value) {
    // if (arr.includes(value)) {
    //   arr.splice(arr.indexOf(value), 1); // 如果存在，则删除
    // } else {
    //   arr.push(value); // 如果不存在，则添加
    // }
    // return arr;
    // 修改成只展开一行
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1); // 如果存在，则删除
    } else {
      arr = [value];
    }
    return arr;
  }

  //删除Load
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    //更新列表
    getListData();
  };

    //gemerate invoice for the Load
    const confirmGenerate = async (id) => {
      await generateInvoice(id);
      message.success("Success");
    };

  //选择全部或者取消全部
  const onSelectAllChange = (selected, selectedRows) => {
    // console.log("onSelectAllChange", selected, selectedRows);
    setIsSelectedAllRows(selected);
    if (selected) {
      //如果用户选择了全选，把所有数据加入到选中列表中
      const newArray = dataList.map((item) => item.id);
      setSelectedRowKeys(newArray);
    } else {
      //清空所有选中的列
      setSelectedRowKeys([]);
    }
  };

  //表格多选的定义
  const rowSelection = {
    selectedRowKeys,
    onSelectAll: onSelectAllChange,
    onSelect: (record, selected) => {
      // console.log("(onSelect)", record, selected, selectedRows, nativeEvent);
      if (selected) {
        //如果是选择加入到选择列表中
        setSelectedRowKeys([...selectedRowKeys, record["id"]]);
      } else {
        //从选择列表中移除
        const newArray = selectedRowKeys.filter(
          (element) => element !== record["id"]
        );
        setSelectedRowKeys(newArray);
      }
    },
  };

  //计算还空的LFD
  function addWorkingDays(startDate, numDays) {
    const startDateParts = startDate.split(" ");
    const dateParts = startDateParts[0].split("/");
    const month = parseInt(dateParts[0]) - 1; // Subtract 1 to adjust for JavaScript's month indexing (0-based)
    const day = parseInt(dateParts[1]);
    const timeParts = startDateParts[1].split(":");
    const hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);

    const endDate = new Date();
    endDate.setMonth(month);
    endDate.setDate(day);
    endDate.setHours(hour);
    endDate.setMinutes(minute);

    let count = 0;

    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1);
      if (isWorkingDay(endDate)) {
        count++;
      }
    }
    //格式化日期输出
    const formattedEndDate = `${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${endDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${getFormattedDayOfWeek(endDate)}`;

    //判断还空LFD是否是今天
    const isToday = isDateToday(endDate);

    return [formattedEndDate, isToday];
  }
  //判断是否是工作日
  function isWorkingDay(date) {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Exclude Sunday (0) and Saturday (6)
  }
  //判断日期是否小于等于今天
  function isDateToday(date) {
    const today = new Date().setHours(0, 0, 0, 0);
    const inputDate = new Date(date).setHours(0, 0, 0, 0);

    return inputDate <= today;
  }
  //获取星期几
  function getFormattedDayOfWeek(date) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  }

  //日期排序比较
  function compareDates(date1, date2) {
    if (!date1) return 1;
    if (!date2) return -1;

    const [date1Value, date1Weekday] = date1.split(" ");
    const [date2Value, date2Weekday] = date2.split(" ");

    const [month1, day1] = date1Value.split("/");
    const [month2, day2] = date2Value.split("/");

    const dateObj1 = new Date(`2023/${month1}/${day1}`);
    const dateObj2 = new Date(`2023/${month2}/${day2}`);

    if (dateObj1 < dateObj2) {
      return -1;
    } else if (dateObj1 > dateObj2) {
      return 1;
    }

    return 0; // 相等情况
  }

  //判断提柜LFD是否小于等于今天
  function isDateBeforeOrEqualToToday(dateString) {
    if (!dateString || dateString =='  ') {
      return [null, null];
    }
    const today = new Date();
    const dateParts = dateString.split("/");
    const month = parseInt(dateParts[0], 10) - 1; // 月份从0开始，需要减1
    const day = parseInt(dateParts[1], 10);

    const date = new Date(today.getFullYear(), month, day);
    const isBeforeOrEqualToToday = date <= today;
    const dayOfWeek = getFormattedDayOfWeek(date);

    return [isBeforeOrEqualToToday, dayOfWeek];
  }

  function getFormattedDayOfWeek(date) {
    const dayOfWeek = date.getDay();
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return weekdays[dayOfWeek];
  }

  const columns = [
    {
      title: "Container#",
      // dataIndex: "container",
      fixed: isMobile ? "" : "left",
      render: (record) => {
        return (
          <div style={{
            display: "flex"}}>
          <div
            style={{
              cursor: "pointer",
              // backgroundColor: loadStatusRef.current[record["status"]]?.bgColor,
              // color: loadStatusRef.current[record["status"]]?.fontColor,
              padding: "0px 2px",
              borderRadius: "1px",
              width: '90%'
            }}
            onDoubleClick={(e) => {
              //e.preventDefault();
              //clipboardCopy(record["container"]);
              //navigator.clipboard.writeText(record["container"]);
              //message.info("copied");
            }}
            onClick={(e) => {
              e.preventDefault();
              setExpandedRowKeys([
                ...addOrRemoveFromArray([...expandedRowKeys], record["id"]),
              ]);
            }}
          >
            {/* {record["container"]} */}
            <VerticalLayout
              height="35px"
              items={[
                <a
                  href="#"
                  style={{
                    backgroundColor:
                      loadStatusRef.current[record["status"]]?.bgColor,
                    color: loadStatusRef.current[record["status"]]?.fontColor,
                    padding: "1px 2px",
                    borderRadius: "1px",
                  }}
                >
                  <Tooltip
                    key={record["id"]}
                    title={record["loadNotes"]?.map((item) => (
                      <li>{item["notes"]}</li>
                    ))}
                  >
                    {record["container"]}
                  </Tooltip>
                </a>,
                // <SolidLine color="#ddd" />,
                <Space style={{ height: "15px" }}>
                  <GrayIcon color="">
                    {/* freight */}
                    <IoBoatOutline
                      color={record["lineHold"] === 1 ? "red" : ""}
                      size={10}
                    />
                  </GrayIcon>
                  <GrayIcon color="">
                    {/* Customs */}
                    <GiTripleGate
                      color={record["customsHold"] === 1 ? "red" : ""}
                      size={10}
                    />
                  </GrayIcon>
                  <GrayIcon>
                    {/* PierPass */}
                    <MdLocalParking
                      color={record["pierpassHold"] === 1 ? "red" : ""}
                      size={10}
                    />
                  </GrayIcon>
                  <GrayIcon>
                    {/* CTF */}
                    <TbLetterC
                      color={record["ctfHold"] === 1 ? "red" : ""}
                      size={10}
                    />
                  </GrayIcon>
                  <GrayIcon>
                    {/* other hold */}
                    <TbLetterO
                      color={record["otherHolds"] ? "red" : ""}
                      size={10}
                    />
                  </GrayIcon>
                  <GrayIcon>
                    {/* ON WHEELED */}
                    <TbLetterW
                      color={record["onWheeled"] === 1 ? "red" : ""}
                      size={10}
                    />
                  </GrayIcon>
                </Space>,
              ]}
            />
          </div>
          <div style={{cursor: 'pointer', width: '10%' }} onClick={ ()=>{
            //clipboardCopy(record["container"]);
            navigator.clipboard.writeText(record["container"]);
            message.info("copied");
          }}>
          <img src={require('../../assets/images/copy-content.png')}  width = "12" height = "12" />
          </div>
          
          </div>
        );
      },
    },
    {
      title: "SL #",
      align: "center",
      fixed: isMobile ? "" : "left",
      width: "60px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "csize", record["csize"]);
        var shippingLine = setValueFromWebSocket(record["id"], "shippingLine", record["shippingLine"]);;
        if(shippingLine == null) {
          shippingLine = record["mbl"] ? record["mbl"].slice(0, 4) : ""
        }
        return (<div style={{display: "flex", flexDirection: "column"}}>
          <span>
          <DoubleClickSelect
            id={record["id"]}
            name="shippingLine"
            content={shippingLine}
            width={80}
            handleUpdateList={(value) => {
              sendData(record["id"], "shippingLine", value)
              //getListData()
            }}
            options={SSL.map((item) => ({ value: item, label: item }))}
            
          />
          </span>
           <span><DoubleClickSelect
            id={record["id"]}
            name="cSize"
            content={value}
            width={60}
            handleUpdateList={(value) => {
              sendData(record["id"], "csize", value)
              //getListData()
            }}
            options={CNTR_SIZE.map((item) => ({ value: item, label: item }))}
            
          />
          </span>
          </div>);
      },
    },
    /*{
      title: "Size",
      dataIndex: "csize",
      fixed: isMobile ? "" : "left",
      width: "40px",
      align: "center",
      render: (_, record) => {
        // console.log("cSize", record["csize"]);
        var value = setValueFromWebSocket(record["id"], "csize", record["csize"]);
        return (
          <DoubleClickSelect
          id={record["id"]}
          name="cSize"
          content={value}
          handleUpdateList={(value) => {
            sendData(record["id"], "csize", value)
            //getListData()
          }}
          options={CNTR_SIZE.map((item) => ({ value: item, label: item }))}
        />
        );
      }
    },*/
    {
      title: "Status",
      align: "center",
      fixed: isMobile ? "" : "left",
      width: "120px",
      render: (record) => {
        const status = loadStatusRef.current[record.status];
        var statusId = status?.id;
        statusId = setValueFromWebSocket(record["id"], "status", record["status"]);
        return (
          loadStatusList.length > 0 && (
            <div id = {"status" + record["id"]}>
            <Select
              showSearch
              optionFilterProp="label"
              style={{ width: "120px" }}
              value={statusId}
              options={loadStatusList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onChange={async (value, option) => {
                var promptStr = 'Please confirm TO -> ';
                if(statusId == 59 || statusId == 50 || statusId == 51 || statusId == 52 || statusId == 53 || value == 74) {
                  promptStr = 'Delivery date should be ' + record["deliverTime"] +', are you sure want to change the status to -> '

                }
                return confirm({
                  icon: <ExclamationCircleOutlined />,
                  content: promptStr + option.label,
                  onOk: async () => {
                    //alert(value);
                    //35 finished
                    //36 E RTN
                    //80 E Invoice Processing
                    
                    await saveApi({ id: record.id, status: value});
                    //remove chassis carry containers
                    if(value == 35 || value == 36 || value == 80) {
                      var index = chassis.findIndex((item) => item.value == record.chassisNo);
                      var carryContainer='';
                      if(index != -1) {
                        carryContainer = chassis[index].carryContainer;
                      }
                      //alert(record.chassisNo +' index ' + index);
                      if( carryContainer.includes(record.container)) {
                                var containers = carryContainer.split(',');
                                const containerIndex = containers.indexOf(record.container);
                                delete containers[containerIndex];
                                var id = chassis[index].id;
                                carryContainer = containers.toString();
                                if(carryContainer.startsWith(",")) {
                                  carryContainer = carryContainer.substring(1);
                                }
                                if(carryContainer.endsWith(",")) {
                                  carryContainer = carryContainer.substring(0,carryContainer.length-1);
                                }
                                //alert(carryContainer.toString());
                                saveChassisApi({id, ["carryContainer"]:carryContainer});
                                chassis[index].carryContainer = carryContainer; 

                      }
                    }
                    var data = {
                      id: record["id"],
                      key: "status",
                      value: value
                    }
                    //setWebSocketValue(JSON.stringify(data));
                    WebSocket.getInstance().send(JSON.stringify(data));
                    console.log("status onChange " + value);
                    getListData();
                  },
                });
              }}
            />
            </div>
          )
        );
      },
    },
    /*{
      title: "Available Date",
      dataIndex: "availableDate",
      width: "100px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "availableDate", record["availableDate"]);
        return (
          <span>{record["availableDate"]}</span>
           
        );
      },
    },*/
    {
      title: "Notes #",
      dataIndex: "notes",
      width: "100px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "notes", record["notes"]);
        return (
          <DoubleClickInput
            id={record["id"]}
            name="notes"
            record={record}
            content={value}
            handleUpdateList={(value) => {
              //console.log(value);
              sendData(record["id"], "notes", value);
              //getListData()
            }}
          />
        );
      },
    },
    {
      title: "Chassis#",
      dataIndex: "chassisNo",
      width: "130px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "chassisNo", record["chassisNo"]);
        var onWheeled = setValueFromWebSocket(record["id"], "onWheeled", record["onWheeled"]);
        var label = "";
        if(onWheeled == 0) {
          label = "Grounded";
        } else if(onWheeled == 1) {
          label = "Wheeled";
        }
        return (
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: 1}}>
          <div>
          <Space style={{ width: "120px" }}>
            <span style={{ color: "red" }}>
              {record["prepaidChassis"] ? "P" : ""}
            </span>
            <DoubleClickSelect
              id={record["id"]}
              name="chassisNo"
              record={record}
              content={value}
            
              options={chassis}
              handleUpdateList={(newValue) => {
                sendData(record["id"], "chassisNo", newValue);

                if(record["status"] == 35 || record["status"] == 36 || record["status"] == 80) {
                  return
                }
                console.log('handle Change newValue ' + newValue + " old Value" + value);
                
                var oldValueIndex = chassis.findIndex((item) => item.value == value); 
                //remove old chassis carry container
                if(oldValueIndex != -1) {
                  console.log('change Chassis status to In use');
                  var carryContainer = chassis[oldValueIndex].carryContainer;
                  if( carryContainer != null && carryContainer.includes(record["container"])) {
                    //carry container includes old chassis container, need remove
                    carryContainer = carryContainer.replace(record["container"], '');
                  }
                  carryContainer = carryContainer.replace(',,', ',');
                  if(carryContainer.startsWith(",")) {
                    carryContainer = carryContainer.substring(1);
                  }
                  if(carryContainer.endsWith(",")) {
                    carryContainer = carryContainer.substring(0,carryContainer.length-1);
                  }
                  console.log(carryContainer);
                  chassis[oldValueIndex].carryContainer = carryContainer;
                  //await saveChassisApi({ chassis[index].id, [name]: value.value });
                  var id = chassis[oldValueIndex].id;
                  saveChassisApi({id, ["carryContainer"]:carryContainer});
                  

                }
              
                var index = chassis.findIndex((item) => item.value == newValue);
                if(index != -1) {
                  console.log('change Chassis status to In use');
                  var carryContainer = chassis[index].carryContainer;
                  if( carryContainer =='' || carryContainer == null) {
                    carryContainer = record["container"];
                  } else {
                    carryContainer = carryContainer  +  "," + record["container"];
                  }
                  console.log(carryContainer);
                  chassis[index].carryContainer = carryContainer;
                  //await saveChassisApi({ chassis[index].id, [name]: value.value });
                  var id = chassis[index].id;
                  saveChassisApi({id, ["location"]:"In use", ["carryContainer"]:carryContainer});
                  

                }
                
                //getListData();
                //getOwnChassis();
              }}
            />
          </Space>
          </div>
          <div>
          <DoubleClickSelect
            id={record["id"]}
            name="onWheeled"
            content={label}
            handleUpdateList={(value) => {
              sendData(record["id"], "onWheeled", value);
              //getListData()
            }}
            options={WHEELED.map((item) => ({ label:item.label, value: item.value  }))}
          /></div>
          </div>
  
        );
      },
    },
    /*{
      title: "APPT/PIN #",
      width: "120px",
      render: (_, record) => {
        let pinLen = null;
        //什么都不需要
        const tt = [
          "C60",
          "C60/STA",
          "C60/STC",
          "PCT",
          "PCT/STL",
          "PierA",
          "TraPac",
        ];
        if (record["pickupTerminal"] && tt.includes(record["pickupTerminal"])) {
          return <></>;
        }

        //PIN 不需要APP
        const terminals = ["FMS", "TTI", "WBCT", "ITS", "ETS"];
        if (
          record["pickupTerminal"] &&
          terminals.includes(record["pickupTerminal"])
        ) {
          pinLen = 4;
        }
        if (record["pickupTerminal"] && record["pickupTerminal"] === "ITS") {
          pinLen = 5;
        }

        return (
          <Space>
            {pinLen === null && <span>APP#{record["pickupApptNumber"]}</span>}
            {pinLen && (
              <Tooltip key={record["id"]} title={record["mbl"]}>
                <span>
                  PIN#{record["mbl"] ? record["mbl"].slice(-1 * pinLen) : ""}
                </span>
              </Tooltip>
            )}
          </Space>
        );
      },
    },*/
    /*{
      title: "Weight (lb)",
      dataIndex: "weight",
      width: "100px",
      align: "center",
      render: (_, record) => {
        // "20GP", "40GP", "40HQ", "45HQ"
        // console.log("cSize", record["csize"]);

        var value = setValueFromWebSocket(record["id"], "weight", record["weight"]);
        let backgroundColor = "white";
        if (record["csize"] && value) {
          if (record["csize"].includes("20") && value >= 36000) {
            backgroundColor = "red";
          }
          if (!record["csize"].includes("20")) {
            if(value >= 43000 && value < 44000) {
              backgroundColor = "yellow";
            }
            else if(value >= 44000) {
            backgroundColor = 'red';
            }
          }
        }
        value = `${numeral(value).format("0,0.00")}`;
        return (
          <>
            <VerticalLayout
              height="35px"
              items={[
                <DoubleClickInput
                  id={record["id"]}
                  name="weight"
                  content={value}
                  handleUpdateList={(value) => {
                    sendData(record["id"], "weight", value);
                    //getListData()
                  }}
                  backgroundColor={backgroundColor}
                />,
                // <SolidLine color="#ddd" />,
                <Space style={{ height: "15px" }}>
                  <GrayIcon color={record["hazmat"] ? "red" : ""}>
                    <AiFillWarning size={10} />
                  </GrayIcon>
                  <GrayIcon color={record["overWeight"] ? "red" : ""}>
                    <RiScales2Fill size={10} />
                  </GrayIcon>
                  <GrayIcon color={record["reefer"] ? "red" : ""}>
                    <BsSnow3 size={10} />
                  </GrayIcon>
                  <GrayIcon
                    color={
                      record["otherHolds"] && record["otherHolds"].length > 0
                        ? "red"
                        : ""
                    }
                  >
                    <SiVitest size={10} />
                  </GrayIcon>
                </Space>,
              ]}
            />
          </>
        );
      },
    },*/
    {
      title: "LFD",
      dataIndex: "lfd",
      width: "60px",
      align: "center",
      render: (_, record) => {
        var result = setValueFromWebSocket(record["id"], "lfd", record["lfd"]);
        var [isDay, dayOfWeek] = isDateBeforeOrEqualToToday(result);
        var value = result ? `${result} ${dayOfWeek}` : null;
        if(dayOfWeek == null) {
          value = null;
        }
        
        return (
          <DoubleClickDatePicker
            id={record["id"]}
            name="lfd"
            content={value}
            color={dayOfWeek ? (isDay ? "red" : "black") : null}
            handleUpdateList={(value) => {
              sendData(record["id"], "lfd", value);
              //getListData()
            }}
          />
        );
      },
    },
    {
      title: "P-Term..",
      dataIndex: "pickupTerminal",
      width: "80px",
      align: "center",
      render: (_, record) => {
        var valuePTerm = setValueFromWebSocket(record["id"], "pickupTerminal", record["pickupTerminal"]);
        var value = setValueFromWebSocket(record["id"], "weight", record["weight"]);
        let backgroundColor = "white";
        if (record["csize"] && value) {
          if (record["csize"].includes("20") && value >= 36000) {
            backgroundColor = "#ff8d8d";
          }
          if (!record["csize"].includes("20")) {
            if(value >= 43000 && value < 44000) {
              backgroundColor = "yellow";
            }
            else if(value >= 44000) {
            backgroundColor = '#ff8d8d';
            }
          }
        }
        value = `${numeral(value).format("0,0.00")}`;
          
        return (
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: 1}}>
          <div><DoubleClickSelect
            id={record["id"]}
            name="pickupTerminal"
            content={valuePTerm}
            handleUpdateList={(value) => {
              sendData(record["id"], "pickupTerminal", value);
              //getListData()
            }}
            options={TERMINALS.map((item) => ({ value: item, label: item }))}
          /></div>
          <div>
 
                <DoubleClickInput
                  width={"120px"}
                  id={record["id"]}
                  name="weight"
                  content={value}
                  handleUpdateList={(value) => {
                    sendData(record["id"], "weight", value);
                    //getListData()
                  }}
                  backgroundColor={backgroundColor}
                />
          </div>
          </div>
        );
      },
    },
    {
      title: "P-Date#",
      dataIndex: "pickupAptDate",
      width: "80px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "pickupAptDate", record["pickupAptDate"]);
        let pinLen = null;
        var appt = null;
        //什么都不需要
        const tt = [
          "C60",
          "C60/STA",
          "C60/STC",
          "PCT",
          "PCT/STL",
          "PierA",
          "TraPac",
        ];
        if (record["pickupTerminal"] && tt.includes(record["pickupTerminal"])) {
          appt =  <></>;
        }

        //PIN 不需要APP
        const terminals = ["FMS", "TTI", "WBCT", "ITS", "ETS"];
        if (
          record["pickupTerminal"] &&
          terminals.some( v => record["pickupTerminal"].includes(v) )
        ) {
          pinLen = 4;
        }
        if (record["pickupTerminal"] && record["pickupTerminal"].includes("ITS")) {
          pinLen = 5;
        }

        appt = 
          <Space>
            {pinLen === null && <span>APP#{record["pickupApptNumber"]}</span>}
            {pinLen && (
              <Tooltip key={record["id"]} title={record["mbl"]}>
                <span>
                  PIN#{record["mbl"] ? record["mbl"].slice(-1 * pinLen) : ""}
                </span>
              </Tooltip>
            )}
          </Space>
        
        return (
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: 1}}>
          <span><DoubleClickTimePicker
            id={record["id"]}
            name="pickupAptDate"
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "pickupAptDate", value);
              getListData()
            }}
          />
          </span>
          <span>
            {appt}
          </span>
          </div>
        );
      }
      
    },
    {
      title: "P-Driver",
      dataIndex: "pdriver",
      width: "80px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "pdriver", record["pdriver"]);
        return (
          <DoubleClickSelect
            id={record["id"]}
            name="pdriver"
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "pdriver", value);
              //getListData()
            }}
            options={driverList.map((item) => ({ value: item.name, label: item.name }))}
          />
        );
      },
    },
    {
      title: "D-Date#",
      dataIndex: "deliverTime",
      width: "80px",
      render: (_, record) => {
        var result = setValueFromWebSocket(record["id"], "deliverTime", record["deliverTime"]);
        var [isDay, dayOfWeek] = isDateBeforeOrEqualToToday(result);
        return (
          <DoubleClickDatePicker
            id={record["id"]}
            name="deliverTime"
            content={result ? `${result}` : null}
            color={isDay != null ? (isDay == false ? "red" : "black") : null}
            weight={isDay != null ? (isDay == false ? "700" : "400") : null}
            handleUpdateList={(value) => {
              sendData(record["id"], "deliverTime", value);
              //getListData()
            }}
          />
        );
      },
    },
    {
      title: "D-Time#",
      dataIndex: "deliverDateTime",
      width: "80px",
      render: (_, record) => {
        var result = setValueFromWebSocket(record["id"], "deliverDateTime", record["deliverDateTime"]);
        var [isDay, dayOfWeek] = isDateBeforeOrEqualToToday(record["deliverTime"]);
        return (
          <DoubleClickInput
            id={record["id"]}
            name="deliverDateTime"
            time={false}
            content={result ? `${result}` : null}
            color={isDay != null ? (isDay == false ? "red" : "black") : null}
            weight={isDay != null ? (isDay == false ? "700" : "400") : null}
            handleUpdateList={(value) => {
              sendData(record["id"], "deliverDateTime", value);
              //getListData()
            }}
          />
        );
      },
    },
    {
      title: "D-Driver",
      dataIndex: "ddriver",
      width: "80px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "ddriver", record["ddriver"]);
        return (
          <DoubleClickSelect
            id={record["id"]}
            name="ddriver"
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "ddriver", value);
              //getListData()
            }}
            options={driverList.map((item) => ({ value: item.name, label: item.name }))}
          />
        );
      },
    },
    {
      title: "R-LFD",
      dataIndex: "r_lfd",
      align: "center",
      width: "80px",
      sorter: (a, b) => compareDates(a["r_lfd"], b["r_lfd"]),
      render: (_, record) => {
        const { r_lfd, rLfdIsToday } = record;
        const divStyle = { color: "red", fontWeight: "600" };
        return (
          r_lfd && <span style={rLfdIsToday ? divStyle : null}>{r_lfd}</span>
        );
      },
    },
    {
      title: "Call-Empty",
      width: "80px",
      align: "center",
      render: (record) => {
        var value = setValueFromWebSocket(record["id"], "emptyDate", record["emptyDate"]);
        if (!value) return;
        //console.log(value);
        // 获取当前时间
        const currentTime = moment();
        // 指定时间（假设为2023年7月27日23:00）
        const specifiedTime = moment.utc(value);
        // 计算时间差（以小时为单位）
        const days = currentTime.diff(specifiedTime, "days");
        const hours = currentTime.diff(specifiedTime, "hours") % 24;
        
        let content = (
          <Space
            direction="vertical"
            size={0}
            style={{ fontSize: "12px", padding: "0px" }}
          >
            {moment.utc(value).local().format("MM/DD HH:mm ddd")}
            <span style={{ color: "red", fontSize: "12px" }}>
              {" "}
              {`${days}days ${hours}hours`}
            </span>
          </Space>
        );

        return (
          <DoubleClickTimePicker
            id={record["id"]}
            name="emptyDate"
            content={content}
            handleUpdateList={(value) => {
              sendData(record["id"], "emptyDate", value);
              getListData()
            }}
          />
        );
      },
    },
    {
      title: "R-Term..",
      dataIndex: "returnTerminal",
      width: "80px",
      align: "center",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "returnTerminal", record["returnTerminal"]);
        return (
          <DoubleClickSelect
            id={record["id"]}
            name="returnTerminal"
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "returnTerminal", value);
              //getListData()
            }}
            options={TERMINALS.map((item) => ({ value: item, label: item }))}
          />
        );
      },
    },
    {
      title: "R-Date#",
      dataIndex: "returnAptDate",
      width: "80px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "returnAptDate", record["returnAptDate"]);
        return (
          <DoubleClickDatePicker
            id={record["id"]}
            name="returnAptDate"
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "returnAptDate", value);
              getListData()
            }}
          />
        );
      },
    },
    {
      title: "R-Driver",
      dataIndex: "rdriver",
      width: "80px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "rdriver", record["rdriver"]);
        return (
          <DoubleClickSelect
            id={record["id"]}
            name="rdriver"
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "rdriver", value);
              //getListData()
            }}
            options={driverList.map((item) => ({ value: item.name, label: item.name }))}
          />
        );
      },
    },
    // {
    //   title: "Remark",
    //   dataIndex: "notes",
    //   width: "160px",
    //   render: (_, record) => {
    //     return (
    //       <DoubleClickInput
    //         id={record["id"]}
    //         name="notes"
    //         content={
    //           record["notes"]
    //           // <Tooltip
    //           //   style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
    //           //   title={record["notes"]}
    //           // >
    //           //   <TipContent width={150}>{record["notes"]}</TipContent>
    //           // </Tooltip>
    //         }
    //         handleUpdateList={() => getListData()}
    //       />
    //     );
    //   },
    // },
    {
      title: "Notes",
      dataIndex: "commentNotes",
      width: "100px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "commentNotes", record["commentNotes"]);
        return (
          <DoubleClickInput
            id={record["id"]}
            name="commentNotes"
            record={record}
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "commentNotes", value);
              //getListData()
            }}
          />
        );
      },
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      render: (_, record) => {
        return (
          <div style={{display: "flex", height:'100%'}}>
            <div style={{textAlign:'left', justifyContent: 'left',alignItems:'left', width:"90%",height:'100%'}}>
              <Tooltip key={record["id"]} title={record["deliveryWhAddressNotes"]}>
                <div style={{ minWidth: "220px" }}>
                  {record["deliveryWhAddress"]}
                </div>
              </Tooltip>
          </div>
          <div style={{cursor: 'pointer',textAlign:'right', justifyContent: 'right',alignItems:'right',width:"10%",height:'100%' }} onClick={ ()=>{
              //clipboardCopy(record["container"]);
              navigator.clipboard.writeText(record["deliveryWhAddress"]);
              message.info("copied");
            }}>
            <img  src={require('../../assets/images/copy-content.png')}  width = "12" height = "12" />
          </div>
          </div>
        );
      },
    },
    {
      title: "Customer Name",
      fixed: isMobile ? "" : "left",
      dataIndex: "customerName",
      width: "100px",
      align: "center",
      render: (_, record) => {
        return <div id={"customer" + record["id"] }>{record["customerName"]}</div>;
      },
    },
    {
      title: "Load Type",
      width: "120px",
      align: "center",
      render: (record) => {
        const name = ["", "Import", "Export", "Road", "Bill Only"];
        return (
          <div style={{ color: record["loadType"] === 1 ? "" : "red" }}>
            {name[record["loadType"]]}
          </div>
        );
      },
    },
    {
      title: "ETA",
      dataIndex: "eta",
      width: "60px",
      align: "center",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "eta", record["eta"]);
        const [isDay, dayOfWeek] = isDateBeforeOrEqualToToday(value);
        if(dayOfWeek == null) {
          value = null;
        }
        return (
          <DoubleClickDatePicker
            id={record["id"]}
            name="eta"
            content={value ? `${value} ${dayOfWeek}` : null}
            color={dayOfWeek ? (isDay ? "red" : "black") : null}
            handleUpdateList={(value) => {
              sendData(record["id"], "eta", value);
              getListData()
            }}
          />
        );
      },
    },
    {
      title: "Notes Charge",
      dataIndex: "chargeNotes",
      width: "100px",
      render: (_, record) => {
        var value = setValueFromWebSocket(record["id"], "chargeNotes", record["chargeNotes"]);
        return (
          <DoubleClickInput
            id={record["id"]}
            name="chargeNotes"
            record={record}
            content={value}
            handleUpdateList={(value) => {
              sendData(record["id"], "chargeNotes", value);
              //getListData()
            }}
          />
        );
      },
    },
    {
      title: "PP",
      // fixed: "left",
      width: "40px",
      align: "center",
      render: ({ id, pierpass }) => {
        const status = ["", "CPP", "S"];
        var value = setValueFromWebSocket(id, "pp", pierpass);
        return (
          <Popconfirm
            title="Change the status"
            description="Are you sure to change the status?"
            onConfirm={(e) => {
              e.preventDefault();
              handlePierPass([id], (value + 1) % 3);
              sendData(id, "pp", (value + 1) % 3)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tag
              style={{ cursor: "pointer" }}
              color={pierpass === 1 ? "green" : ""}
            >
              {status[value]}
            </Tag>
          </Popconfirm>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (_, record) => (
        <div>
          <Popconfirm
          title="Delete the Load"
          description="Are you sure to delete this Load?"
          okText="Yes"
          cancelText="No"
          onConfirm={(e) => confirmDelete(record["id"])}
        >
          <Button type="link" style={{ color: "grey" }}>
            Delete
          </Button>
        </Popconfirm>
        { record["invoiceLineItems"] && <Popconfirm
          title="generate invoice"
          description="Are you sure to generate invoice for this Load?"
          okText="Yes"
          cancelText="No"
          onConfirm={(e) => confirmGenerate(record["id"])}
        >
          <Button type="link" style={{ color: "grey" }}>
            invoice
          </Button>
        </Popconfirm>}
        </div>
        
        
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //添加Loads提交表格
  const onFinish = async (values) => {
    var eta = values["eta"];
    if(eta != null) {
      eta = values["eta"].format("YYYY-MM-DD");
    }
    //console.log("Success:",  eta);
    await saveApi({
      fileIds: [...fileList].map((item) => item.id).join(),
      deliveryWhId: values.consignee,
      warehouseId: values.customer,
      ...values,
      mbl: values["mbl"].trim(),
      4: values["container"].trim(),
      eta: eta,
    });
    setIsModalOpen(false);
    message.success("Success");
    //刷新列表
    getListData();
  };

  //拖拽文件上传
  const draggerProps = {
    name: "file",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    customRequest: async ({ file }) => {
      const res = await uploadApi(file);
      // console.log("file eeeee,", res, file);
      let newFileList = [
        ...fileList,
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          id: res.id,
        },
      ];
      setFileList(newFileList);
    },
    onRemove: async (file) => {
      // console.log("remove,", file);
      await delUploadApi(file.id);
      let newFileList = [...fileList].filter((item) => item.id !== file.id);
      setFileList(newFileList);
    },
    // onChange(info) {
    //   const { status } = info.file;
    //   if (status !== "uploading") {
    //     console.log("statusstatusstatus", status, info.file, info.fileList);
    //   }
    //   if (status === "done") {
    //     message.success(`${info.file.name} file uploaded successfully.`);
    //   } else if (status === "error") {
    //     message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
    // onDrop(e) {
    //   console.log("Dropped files", e.dataTransfer.files);
    // },
  };

  //拷贝所有的柜号
  const handleCopy = async () => {
    const res = await getByIdsApi(selectedRowKeys.join(","));
    const cntrs = res.map((item) => item["container"]);
    //navigator.clipboard.writeText(record["container"]);
    navigator.clipboard.writeText(cntrs.join("\n"));
    //clipboardCopy(cntrs.join("\n"));
    message.success("Copied!");
    // console.log("handleCopy", cntrs.join("\n"));
  };

  //更新PierPass状态
  const handlePierPass = async (ids, status) => {
    await updatePierPassApi(ids.join(","), status);
    // message.success("Success");
    await getListData();
  };

  //关闭悬浮窗口
  const handleClose = () => {
    setSelectedRowKeys([]);
    setIsSelectedAllRows(false);
  };

  //选择了时间范围
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setSearchForm({
        ...searchForm,
        beginTime: dateStrings[0],
        endTime: dateStrings[1],
      });
    } else {
      // console.log("Clear");
      setSearchForm({ ...searchForm, beginTime: null, endTime: null });
    }
  };

  //同步柜子状态
  const syncContainerStatus = async () => {
    await syncContainerStatusApi();
  };

  return initDone ? (
    <>
      <Card
        style={{ width: "100%", height: "100%" }}
        extra={
          <Space style={{ marginLeft:10, display: isMobile ? "none" : "" }}>
            <Button
              type="primary"
              style={{
                width: "100px",
              }}
              icon={<FileAddOutlined />}
              onClick={(e) => {
                e.preventDefault();
                showModal();
              }}
            >
              Add
            </Button>
            <img src={require('../../assets/images/excel.png')} style={{cursor: 'pointer',  margin: -10, marginLeft:2, marginRight:2 }} width = "24" height = "24" 
               onClick={() => {
                var excelData = [];
                //console.log(dataList);
                //console.log(JSON.stringify(loadStatusList));
                dataList.map( (data) => {
                  let exportData = { ...data } //clone a object then delete some unused field
                  delete exportData.id;
                  delete exportData.eta;
                  delete exportData.warehouseId;
                  delete exportData.shipperId;
                  delete exportData.truckingId;
                  loadStatusList.map(status => {
                    if(data.status == status.id) {
                      exportData.status = status.name;
                    }
                  });
                  if(data.pierpass == 1) {
                    exportData.pierpass = 'CPP';
                  } else if(data.pierpass == 2) {
                    exportData.pierpass = 'S';
                  } else {
                    exportData.pierpass = '';
                  }
                  excelData.push(exportData);
                });
                
                const worksheet = XLSX.utils.json_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                XLSX.writeFile(workbook, "iDispatch.xlsx");
              }}
            />
            <img src={require('../../assets/images/C2QB_white_btn_lg_hover.png')} style={{cursor: 'pointer',  margin: -10, marginLeft:2, marginRight:2 }} width = "24" height = "24" 
               onClick={() => {
                var win;
                var checkConnect;
                var parameters = "location=1,width=800,height=650";
                parameters += ",left=" + (800) / 2 + ",top=" + (650) / 2;
                const token = userOptions.getToken();
                // Launch Popup
                //win = window.open(BaseUrl + "/connectToQuickbooks?token=" + token, 'connectPopup', parameters);
                win = window.location = BaseUrl + "/connectToQuickbooks?token=" + token;
                }}
            />
          </Space>
        }
        title={
          <Space
            style={{ width: "100%", height: "100%" }}
            direction={isMobile ? "vertical" : "horizontal"}
          >
            <Form>
            <Select
              placeholder="Like..."
              style={{ width: "80px" }}
              showSearch
              allowClear
              defaultValue={0}
              value={searchForm.onlySearchContainers}
              onChange={(value) =>
                setSearchForm({
                  ...searchForm,
                  onlySearchContainers: value,
                })
              }
              optionFilterProp="label"
              options={ONLY_CONTAINERS_SEARCH.map((value, index) => ({
                label: value,
                value: index,
              }))}
            />
            <Select
              placeholder="YES"
              style={{ width: "80px" }}
              showSearch
              allowClear
              defaultValue={0}
              value={searchForm.searchNo}
              onChange={(value) =>
                setSearchForm({
                  ...searchForm,
                  searchNo: value,
                })
              }
              optionFilterProp="label"
              options={SEARCH_YES_OR_NO.map((value, index) => ({
                label: value,
                value: index,
              }))}
            />
            <Input
              allowClear
              placeholder="Search..."
              style={{ width: "200px" }}
              value={searchForm.name}
              onChange={(e) =>
                setSearchForm({
                  ...searchForm,
                  name: e.target.value?.trim(),
                })
              }
              onKeyDown={async (e) => {
                if(e.key === 'Enter') {
                  getListData(1);
                }
              }}
            />
            <Select
              placeholder="Customer..."
              mode="multiple"
              style={{ width: "120px" }}
              showSearch
              allowClear
              value={searchForm.warehouseIds}
              onChange={(value) => {
                setSearchForm({
                  ...searchForm,
                  warehouseIds: value,
                })
               }
              }
              optionFilterProp="label"
              options={customerList.map((item) => ({
                label: item.name.split("-")[0].trim(),
                value: item.id,
              }))}
            />
            <Select
              placeholder="Status..."
              mode="multiple"
              style={{
                width: "160px",
              }}
              value={searchForm.status}
              showSearch
              allowClear
              onChange={(value) =>
                setSearchForm({
                  ...searchForm,
                  status: value,
                })
              }
              optionFilterProp="label"
              options={loadStatusList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
            <Select
              placeholder="Terminal..."
              style={{ width: "120px" }}
              showSearch
              allowClear
              value={searchForm.terminal}
              onChange={(value) =>
                setSearchForm({
                  ...searchForm,
                  terminal: value,
                })
              }
              optionFilterProp="label"
              options={TERMINALS.map((name) => ({
                label: name,
                value: name,
              }))}
            />
            <Select
              placeholder="DateType..."
              style={{ width: "120px" }}
              showSearch
              allowClear
              value={searchForm.dateType}
              onChange={(value) =>
                setSearchForm({
                  ...searchForm,
                  dateType: value,
                })
              }
              optionFilterProp="label"
              options={[
                { label: "ETA", value: "eta" },
                { label: "PICKUP", value: "pickup" },
                { label: "RETURN", value: "return" },
              ]}
            />
            {/* 选择时间范围 */}
            <RangePicker onChange={onRangeChange} value={searchForm.date}  />
            <img src={require('../../assets/images/clear.png')} style={{ cursor: 'pointer', margin: -10, marginLeft:2, marginRight:2 }} width = "26" height = "26" 
              onClick={() => {
                  setSearchForm({
                    ...searchForm,
                    name: null,
                    onlySearchContainers: 0,
                    searchNo: 0,
                    dateType: null,
                    terminal:null,
                    status:null,
                    warehouseIds: null,
                    beginTime: null,
                    endTime: null,
                    date: null
                  })
              }} />
            <Button
              type="primary"
              onClick={() => {
                getListData(1);
              }}
            >
              Search
            </Button>

            <Popconfirm
              title="Sync container status"
              description="Are you sure to do this task?"
              onConfirm={syncContainerStatus}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                icon={<ReloadOutlined />}
                style={{
                  marginLeft: "10px",
                  display: isMobile ? "none" : "",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Sync
              </Button>
            </Popconfirm>
          </Form>
          </Space>
        }
      >
        <Row>
          <StyledTable
            loading={loading}
            size="small"
            bordered
            rowKey="id"
            sticky
            rowSelection={rowSelection}
            dataSource={dataList}
            columns={
              tableColumnRef.current
                ? columns.filter((column) =>
                    tableColumnRef?.current.includes(column.title)
                  )
                : columns
            }
            rowHeight={60}
            pagination={{
              total: total,
              showSizeChanger: true,
              onChange: getListData,
              current: pageRef.current,
              pageSize: sizeRef.current,
              showTotal: (total) => `Total ${total} items`,
            }}
            expandable={{
              // columnWidth: 0,
              showExpandColumn: false,
              expandedRowKeys: expandedRowKeys,
              expandedRowRender: (record) => (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "",
                  }}
                >
                  <Tabs
                    onChange={(key) => {
                      // console.log(key);
                      setTabKey(key);
                    }}
                    activeKey={tabKey}
                    type="card"
                    items={[
                      {
                        label: "Load Info",
                        key: 1,
                        children: tabKey === 1 && (
                          <LoadBaseInfo
                            loadId={record["id"]}
                            refresh={refresh}
                            warehouseId={record["warehouseId"]}
                            deliveryWhId={record["deliveryWhId"]}
                            updateList={() => getListData()}
                            customers={customerList.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))}
                            consignee={warehouseList.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))}
                          />
                        ),
                      },
                      {
                        label: "Billing",
                        key: 2,
                        children: tabKey === 2 && (
                          <LoadBilling record={record} />
                        ),
                      },
                      {
                        label: "Documents",
                        key: 30,
                        children: tabKey === 30 && (
                          <LoadDocuments record={record} />
                        ),
                      },
                      {
                        label: "Packing List",
                        key: 40,
                        children: tabKey === 40 && (
                          <PackingList record={record} />
                        ),
                      },
                      {
                        label: "Notes",
                        key: 50,
                        children: tabKey === 50 && (
                          <LoadNotes record={record} />
                        ),
                      },
                      {
                        label: "Track Logs",
                        key: 60,
                        children: tabKey === 60 && <LoadLogs record={record} />,
                      },
                    ]}
                  />
                </div>
              ),
            }}
            scroll={{ x: "max-content", y: "auto" }}
          />
        </Row>
      </Card>
      <Modal
        title="New Load"
        style={{
          top: 20,
        }}
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        width={488}
        okText="Create Load"
        footer={null}
      >
        <div>
          <Form
            layout="vertical"
            style={{
              maxWidth: 600,
            }}
            form={addForm}
            autoComplete="off"
            onFinish={onFinish}
            initialValues={{ loadType: 1 }}
            preserve={false}
          >
            <Divider style={{ margin: "13px" }} />
            <Form.Item
              label="Load Type"
              name="loadType"
              rules={[
                {
                  required: true,
                  message: "Please select Load Type!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={1}> Import </Radio>
                <Radio value={2}> Export </Radio>
                <Radio value={3}> Road </Radio>
                <Radio value={4}> Bill Only </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Customer"
              name={"customer"}
              rules={[
                {
                  required: true,
                  message: "Please select Customer!",
                },
              ]}
            >
              <Select
                placeholder="Select..."
                showSearch
                onChange={(value) => reloadWarehoseList(value)}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Button
                      type="text"
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/customer");
                      }}
                    >
                      Add item
                    </Button>
                  </>
                )}
                optionFilterProp="label"
                options={customerList.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Form.Item>

            <Form.Item
              label="Consignee"
              name={"consignee"}
              rules={[
                {
                  required: true,
                  message: "Please select Consignee!",
                },
              ]}
            >
              <Select
                placeholder="Select..."
                showSearch
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Button
                      type="text"
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/warehouse");
                      }}
                    >
                      Add item
                    </Button>
                  </>
                )}
                optionFilterProp="label"
                options={warehouseList.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Form.Item>

            <Form.Item
              label="MBL"
              name="mbl"
              rules={[
                {
                  required: true,
                  message: "Please input MBL!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Container Number"
              name="container"
              rules={[
                {
                  required: true,
                  message: "Please input Container Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="ETA"
              name="eta"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker format='YYYY-MM-DD' />
            </Form.Item>

            <Form.Item
              label="Weight"
              name="weight"
              rules={[
                {
                  required: false
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="D.O" valuePropName="fileList">
              <Dragger {...draggerProps} fileList={fileList}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag D.O files to this area to upload
                </p>
                {/* <Button type="primary">Choose From your device...</Button> */}
              </Dragger>
            </Form.Item>

            <Form.Item>
              <Row justify="end">
                <Space size={20}>
                  <Button onClick={handleCancel} type="text">
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Create Load
                  </Button>
                </Space>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <FloatingToolbar
        show={selectedRowKeys.length === 0 ? "none" : ""}
        handleCopy={handleCopy}
        handleClose={handleClose}
        handlePierPass={(status) => handlePierPass(selectedRowKeys, status)}
      />
    </>
  ) : (
    <MySpin />
  );
}
