import React from "react";
import "./FloatingToolbar.css";
import { Row } from "antd";
import { LuCopy } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { TbSquareLetterS, TbSquareRoundedLetterP } from "react-icons/tb";
import styled from "styled-components";

const HoverDiv = styled.div`
  width: 50px;
  height: 40px;
  margin: 0 15px;
  cursor: pointer;
  /* background-color: rgba(57, 78, 102, 0.8); */
  color: white;

  &:hover {
    background-color: rgba(57, 78, 102, 0.8);
    color: white;
    cursor: pointer;
  }
`;

const FloatingToolbar = ({ show, handleCopy, handleClose, handlePierPass }) => {
  const getItem = (content) => {
    return <HoverDiv>{content}</HoverDiv>;
  };

  return (
    <Row style={{ display: show }} className="floating-toolbar">
      {getItem(
        <LuCopy
          onClick={handleCopy}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {getItem(
        <TbSquareRoundedLetterP
          onClick={(e) => handlePierPass(1)}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {getItem(
        <TbSquareLetterS
          onClick={(e) => handlePierPass(2)}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {getItem(
        <AiOutlineClose
          onClick={handleClose}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </Row>
  );
};

export default FloatingToolbar;
