import { GiConfirmed, GiShipBow, GiCartwheel } from "react-icons/gi";
import { TbTruckReturn, TbBuildingWarehouse } from "react-icons/tb";
import { MdAccountBalance } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { IoSettingsOutline } from "react-icons/io5";
import { MdManageAccounts } from "react-icons/md";

export const menuConfig = [
  {
    id: 100,
    name: "Dispatcher",
    path: "#",
    icon: <GiConfirmed />,
    children: [
      {
        id: 101,
        name: "Dispatcher",
        path: "/dispatcher",
      },
      // {
      //   id: 102,
      //   name: "Pool Chassis",
      //   path: "/poolChassis",
      // },
      //{
      //  id: 102,
      //  name: "Own Chassis",
      //  path: "/ownChassis",
      //},
      {
        id: 104,
        name: "ETA-Analysis",
        path: "/loadDateAnalysis",
      },
      {
        id: 103,
        name: "Pickup-Analysis",
        path: "/loadAnalysis",
      },
    ],
  },
  {
    id: 200,
    name: "Customer",
    path: "#",
    icon: <SlPeople />,
    children: [
      {
        id: 201,
        name: "Customer List",
        path: "/customer",
      },
    ],
  },
  {
    id: 300,
    name: "Warehouse",
    path: "#",
    icon: <TbBuildingWarehouse />,
    children: [
      {
        id: 301,
        name: "Warehouse List",
        path: "/warehouse",
      },
    ],
  },
  {
    id: 400,
    name: "Accounting",
    path: "#",
    icon: <MdAccountBalance />,
    children: [
      {
        id: 401,
        name: "Invoice / Cost",
        path: "/accounting",
      },
      {
        id: 402,
        name: "AP List",
        path: "/apList",
      },
      {
        id: 403,
        name: "AR List",
        path: "/arList",
      },
      {
        id: 404,
        name: "Payment History",
        path: "/payment/history",
      },
    ],
  },
  {
    id: 500,
    name: "Drivers",
    path: "#",
    icon: <TbTruckReturn />,
    children: [
      {
        id: 501,
        name: "Driver Profiles",
        path: "/driver",
        // icon: <TbSteeringWheel />,
        type: 1,
      },
      // {
      //   id: 502,
      //   name: "Truck Profiles",
      //   path: "/truck",
      //   icon: <TbTruck />,
      //   type: 1,
      // },
    ],
  },
  {
    id: 600,
    name: "Shipping Line",
    path: "#",
    icon: <GiShipBow />,
    children: [
      {
        id: 601,
        name: "Shipping Line",
        path: "/shippingLine",
        type: 1,
      },
    ],
  },
  {
    id: 700,
    name: "Equipment",
    path: "#",
    icon: <GiCartwheel />,
    children: [
      {
        id: 701,
        name: "Own Chassis",
        path: "/chassis",
        type: 1,
      },
    ],
  },
  {
    id: 1000,
    name: "Settings",
    icon: <IoSettingsOutline />,
    children: [
      {
        id: 1001,
        name: "Invoice Setting",
        path: "/invoiceSetting",
        // icon: <BiGitCompare />,
      },
      {
        id: 1002,
        name: "Load Status",
        path: "/loadStatus",
        // icon: <BiTransfer />,
      },
      {
        id: 1003,
        name: "Charge Type",
        path: "/chargeType",
        // icon: <TfiMoney />,
      },
    ],
  },
  {
    id: 1100,
    name: "System",
    icon: <MdManageAccounts />,
    children: [
      {
        id: 1101,
        name: "User List",
        path: "/system/user",
        // icon: <BiGitCompare />,
      },
    ],
  },
];
