import React, { useEffect, useState } from "react";
import Invoice from "../../pages/dispatcher/invoice";
import { listAllApi } from "../../api/invoice";
import { Button, Modal, Space, message } from "antd";
import { nanoid } from "nanoid";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function ListAllInvoiceDiv({ open, searchForm, setPrintOpen }) {
  const [invoiceList, setInvoiceList] = useState([]);

  //获取数据
  useEffect(() => {
    getAllInvoice();
  }, []);

  //获取所有的Invoice 列表
  const getAllInvoice = async () => {
    const res = await listAllApi({ ...searchForm });
    // console.log("res", res);
    if (res.length > 200) {
      message.error("Too many datas.");
      return;
    }
    setInvoiceList(res);
  };

  //保存所有的Invoice到多个PDF文件
  //   const saveAllInvoice = async () => {
  //     const elements = document.querySelectorAll('.pdf-element[pid^="inv-"]');
  //     // 定义A4纸张大小
  //     const PDFFile = {
  //       A4: [592.28, 841.89],
  //     };

  //     // 遍历所有元素，将每个元素转换成PDF文件
  //     for (let i = 0; i < elements.length; i++) {
  //       const element = elements[i];
  //       //删除print按钮
  //       const idToRemove = "printButton"; // 要删除的子元素的id
  //       const childElement = element.querySelector(`#${idToRemove}`);
  //       if (childElement) {
  //         // childElement.remove();
  //         childElement.style.display = "none";
  //       }

  //       html2canvas(element, { scale: 4 }).then((canvas) => {
  //         //获取画布的宽高
  //         let canvasWidth = canvas.width;
  //         let canvasHeight = canvas.height;
  //         //一页PDF显示html生成的canvas高度
  //         let pdfPageHeight = (PDFFile.A4[1] / PDFFile.A4[0]) * canvasWidth;
  //         //未生成pdf的html页面内高度
  //         let pdfAllHeight = canvasHeight;

  //         let position = 0; //页面偏移

  //         //转换图片为dataURL，参数：图片格式和清晰度(0-1)
  //         let pageData = canvas.toDataURL("image/jpeg", 1.0);

  //         //html页面生成的canvas在pdf中图片的宽高
  //         let imgWidth = PDFFile.A4[0] - 60; //减去边距宽度
  //         let imgHeight = (canvasHeight / canvasWidth) * PDFFile.A4[0];
  //         //方向p竖直 l横向，尺寸ponits，格式a4
  //         let pdf = new jsPDF("p", "pt", PDFFile.A4);

  //         //当内容未超过pdf一页显示的范围，无需分页
  //         if (pdfAllHeight < pdfPageHeight) {
  //           // 从图片顶部开始打印   30 左右边距, 0 上下边距
  //           pdf.addImage(pageData, "jpeg", 30, 0, imgWidth, imgHeight);
  //         } else {
  //           while (pdfAllHeight > 0) {
  //             pdf.addImage(pageData, "jpeg", 0, position, imgWidth, imgHeight);
  //             pdfAllHeight -= pdfPageHeight;
  //             position -= PDFFile.A4[1];
  //             //避免添加空白页
  //             if (pdfAllHeight > 0) {
  //               pdf.addPage();
  //             }
  //           }
  //         }
  //         const pidValue = element.getAttribute("pid");
  //         pdf.save(`${pidValue}.pdf`);

  //         if (childElement) {
  //           // 还原print按钮显示
  //           childElement.style.display = "";
  //         }
  //       });
  //     }
  //   };

  const saveAllInvoice = async () => {
    const elements = document.querySelectorAll('.pdf-element[pid^="inv-"]');
    const PDFFile = {
      A4: [592.28, 841.89],
    };
    const pageSize = 1; // 每次生成的数量

    for (let i = 0; i < elements.length; i += pageSize) {
      const promises = [];
      for (let j = i; j < i + pageSize && j < elements.length; j++) {
        const element = elements[j];
        const idToRemove = "printButton";
        const childElement = element.querySelector(`#${idToRemove}`);

        if (childElement) {
          childElement.style.display = "none";
        }
        const promise = html2canvas(element, { scale: 3 }).then((canvas) => {
          let canvasWidth = canvas.width;
          let canvasHeight = canvas.height;
          let pdfPageHeight = (PDFFile.A4[1] / PDFFile.A4[0]) * canvasWidth;
          let pdfAllHeight = canvasHeight;
          let position = 0;
          let pageData = canvas.toDataURL("image/jpeg", 1.0);
          let imgWidth = PDFFile.A4[0] - 60;
          let imgHeight = (canvasHeight / canvasWidth) * PDFFile.A4[0];
          let pdf = new jsPDF("p", "pt", PDFFile.A4);

          if (pdfAllHeight < pdfPageHeight) {
            pdf.addImage(pageData, "jpeg", 30, 0, imgWidth, imgHeight);
          } else {
            while (pdfAllHeight > 0) {
              pdf.addImage(pageData, "jpeg", 0, position, imgWidth, imgHeight);
              pdfAllHeight -= pdfPageHeight;
              position -= PDFFile.A4[1];
              if (pdfAllHeight > 0) {
                pdf.addPage();
              }
            }
          }
          const pidValue = element.getAttribute("pid");
          pdf.save(`${pidValue}.pdf`);

          if (childElement) {
            childElement.style.display = "";
          }
        });
        promises.push(promise);
      }
      await Promise.all(promises);
    }
    message.success(`${elements.length} Invoice done.`);
  };

  return (
    <Modal
      title={
        <div style={{ position: "relative", width: "100%" }}>
          <Space style={{ marginLeft: 950 }} size={20}>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                saveAllInvoice();
              }}
            >
              Print ALL
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setPrintOpen(false);
              }}
            >
              Close
            </Button>
          </Space>
        </div>
      }
      style={{
        top: 20,
      }}
      open={open}
      onOk={() => setPrintOpen(false)}
      onCancel={() => setPrintOpen(false)}
      width={1200}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
      closable={false}
    >
      {invoiceList.map((inv) => (
        <Invoice key={nanoid()} invoice={inv} />
      ))}
    </Modal>
  );
}
