import { userOptions } from "../../../utils/storageUtils";
import {
  AUTH_LOGIN_OK,
  AUTH_LOGOUT_OK,
  INIT_USERINFO_FROM_LOCAL,
  AUTH_UNAUTHORIZED,
  AUTH_USERINFO_UPDATE,
} from "../../action-types";
import { loginApi, logoutApi, refreshTokenApi } from "../../../api/auth";
import { message } from "antd";

//更新用户信息
export const updateUserInfo = (userInfo) => {
  return (dispatch) => {
    dispatch({
      type: AUTH_USERINFO_UPDATE,
      payload: userInfo,
    });
  };
};

//从本地缓存中初始化用户信息到redux
export const initUserInfo = () => {
  return (dispatch) => {
    refreshTokenApi().then((user) => {
      dispatch({
        type: INIT_USERINFO_FROM_LOCAL,
        payload: user,
      });
    });
  };
};

//发送登录请求
export const loginAction = ({ username, password }) => {
  // console.log('loginAction', username, password);
  return (dispatch) => {
    loginApi({
      username,
      password,
    }).then((response) => {
      // console.log('reducer receive response', response);
      if (response) {
        message.success("登录成功");
        //在本地保存用户信息
        response.expired = Date.now();
        userOptions.saveUserInfo(response);
        dispatch({
          type: AUTH_LOGIN_OK,
          payload: response,
        });
      }
    });
  };
};

//执行退出登录操作
export const logoutAction = () => {
  // console.log('logoutAction');
  return (dispatch) => {
    logoutApi().then((response) => {
      // console.log("reducer logoutApi receive response", response);
      //删除在本地保存的用户信息
      userOptions.delUserInfo();
      dispatch({
        type: AUTH_LOGOUT_OK,
      });
    });
  };
};

//发生401请求，需要重新登录
export const unauthorizedAction = () => {
  // console.log('unauthorizedAction');
  return (dispatch) => {
    // console.log('reducer unauthorizedAction');
    //删除在本地保存的用户信息
    //userOptions.delUserInfo();
    dispatch({
      type: AUTH_UNAUTHORIZED,
    });
  };
};
