import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Radio,
  Select,
  Divider,
  message,
  Tooltip,
  InputNumber,
  DatePicker,
  Tag,
} from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import {
  listApi,
  saveApi,
  deleteApi,
  sumTotalApi,
} from "../../api/payment-history";
import validator from "validator";
import DoubleClickTextArea from "../../components/double-click-textarea";
import { selectListApi as customerListApi } from "../../api/customer";
import { selectListApi as driverListApi } from "../../api/driver";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { formatCurrency } from "../../utils/numUtils";

const { RangePicker } = DatePicker;
dayjs.extend(utc);

export default function PaymentHistoryPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  //下拉框数据
  const [customerList, setCustomerList] = useState([]);
  const [driverList, setDriverList] = useState([]);

  const sizeRef = useRef(100);
  const pageRef = useRef(1);
  const editRef = useRef({});

  //导航跳转
  const navigate = useNavigate();

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      ...searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
    //计算总额
    getTotalAmount();
  };

  //查询总额
  const getTotalAmount = async () => {
    const result = await sumTotalApi(searchForm);
    let res = {};
    result.forEach(({ typeId, paymentAmount }) => {
      res[typeId] = res?.typeId?.paymentAmount || 0 + paymentAmount;
    });
    // console.log("getTotalAmount", result, res);
    setTotalAmount(res);
  };

  //加载客户列表
  const reloadCustomerData = async (typeId) => {
    if (typeId === 0) {
      await getCustomersData();
    } else {
      await getDriversData();
    }
  };

  //客户下拉列表
  const getCustomersData = async () => {
    const result = await customerListApi();
    setCustomerList(result);
  };

  //司机下拉列表
  const getDriversData = async () => {
    const result = await driverListApi();
    setCustomerList(result);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    setShowAddModal(false);
  };

  //保存客户信息
  const handleSave = async (values) => {
    let paymentDate = dayjs(values["paymentDate"]).format("YYYY-MM-DD");
    // console.log("handleSave,", paymentDate, values);
    await saveApi({ ...values, paymentDate });
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //快速编辑保存remark
  const handleSaveRemark = async (values) => {
    await saveApi(values);
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  //选择了时间范围
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setSearchForm({
        ...searchForm,
        beginTime: dateStrings[0],
        endTime: dateStrings[1],
      });
    } else {
      // console.log("Clear");
      setSearchForm({ ...searchForm, beginTime: null, endTime: null });
    }
  };

  const columns = [
    // {
    //   title: "id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Customer Name",
      dataIndex: "payerName",
      key: "payerName",
      ellipsis: true,
      render: (payerName) => {
        return <Tooltip title={payerName}>{payerName}</Tooltip>;
      },
    },
    {
      title: "Type #",
      dataIndex: "typeId",
      key: "typeId",
      width: "100px",
      render: (typeId) => {
        return (
          <Tag color={typeId === 0 ? "red" : "green"}>
            {typeId === 0 ? "Income" : "Cost"}
          </Tag>
        );
      },
    },
    {
      title: "Amount #",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      width: "130px",
      render: (paymentAmount) => formatCurrency(paymentAmount),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      width: "150px",
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: "150px",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
      // width: "500px",
      render: (_, record) => {
        return (
          <Tooltip
            // placement="topLeft"
            title={
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {record["remark"]}
              </div>
            }
          >
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <DoubleClickTextArea
                id={record["id"]}
                name="remark"
                content={record["remark"]}
                handleUpdateList={getListData}
                handleSave={(value) =>
                  handleSaveRemark({ ...value, payerId: record["payerId"] })
                }
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Update Time",
      dataIndex: "updateTime",
      key: "updateTime",
      width: "150px",
      render: (updateTime) => {
        return dayjs(updateTime).utc().local().format("YYYY-MM-DD");
      },
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={async (e) => {
              e.preventDefault();
              await reloadCustomerData(record["typeId"]);
              editRef.current = record;
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the config"
            description="Are you sure to delete this config?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <a style={{ color: "gray" }}>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <Space>
      <Select
        placeholder="Type..."
        allowClear
        onChange={async (v) => {
          setSearchForm({ ...searchForm, typeId: v });
          if (v === undefined || v === null) {
            setCustomerList([]);
          } else {
            await reloadCustomerData(v);
          }
        }}
        options={[
          { name: "AR", id: 0 },
          { name: "AP", id: 1 },
        ].map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        style={{ width: 80 }}
      />
      <Input
        placeholder="Search..."
        style={{ width: 200 }}
        onChange={(e) =>
          setSearchForm({ ...searchForm, name: e.target.value.trim() })
        }
        allowClear
      />
      <Select
        placeholder="Select..."
        showSearch
        allowClear
        optionFilterProp="label"
        onChange={(v) => {
          setSearchForm({ ...searchForm, payerId: v });
        }}
        options={customerList.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        style={{ width: 200 }}
      />
      {/* 选择时间范围 */}
      <RangePicker onChange={onRangeChange} />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
      <div style={{ position: "absolute", top: -45, left: 300 }}>
        <Space size={25}>
          <div>
            Income:{" "}
            <span style={{ color: "" }}>{formatCurrency(totalAmount[0])}</span>
          </div>
          <div>
            Cost:{" "}
            <span style={{ color: "" }}>{formatCurrency(totalAmount[1])}</span>
          </div>
          <div>
            Balance:{" "}
            <span style={{ color: "red" }}>
              {formatCurrency((totalAmount[0] || 0) - (totalAmount[1] || 0))}
            </span>
          </div>
        </Space>
      </div>
    </Space>
  );

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        style={{ margin: "5px" }}
        extra={
          <Button
            onClick={async () => {
              editRef.current = {};
              //获取客户列表
              await reloadCustomerData(0);
              setAddLoading(true);
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Payment
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items` }}
        />
      </Card>
      <Modal
        title="Edit Payment History"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        // width={1000}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          style={{
            maxWidth: 600,
            paddingTop: "25px",
          }}
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 16 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{
            ...editRef.current,
            typeId: editRef.current["typeId"] ? editRef.current["typeId"] : 0,
            paymentDate: editRef.current["paymentDate"]
              ? dayjs(editRef.current["paymentDate"])
              : dayjs(),
            paymentAmount: editRef.current["paymentAmount"] || 0,
          }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Payment Type"
            name="typeId"
            rules={[
              {
                required: true,
                message: "Please select Payment Type!",
              },
            ]}
          >
            <Radio.Group
              onChange={async (v) => {
                // console.log("Radio", v.target.value);
                await reloadCustomerData(v.target.value);
              }}
            >
              <Radio value={0}> Accounts Receivable </Radio>
              <Radio value={1}> Accounts Payable </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Customer"
            name={"payerId"}
            rules={[
              {
                required: true,
                message: "Please select Customer!",
              },
            ]}
          >
            <Select
              placeholder="Select..."
              showSearch
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Button
                    type="text"
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/customer");
                    }}
                  >
                    Add item
                  </Button>
                </>
              )}
              optionFilterProp="label"
              options={customerList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Payment Method"
            name="paymentMethod"
            rules={[{ required: true, message: "Please input name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="paymentAmount"
            rules={[{ required: true, message: "Please input amount." }]}
          >
            <InputNumber
              style={{ width: "300px" }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item
            label="Payment Date"
            name="paymentDate"
            rules={[{ required: true, message: "Please select payment date." }]}
          >
            <DatePicker style={{ width: "180px" }} />
          </Form.Item>

          <Form.Item label="Remark" name="remark">
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
