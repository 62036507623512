import React, { Fragment, useState, useRef, useEffect } from "react";
import { Transition } from "react-transition-group";
import ModalBackdrop from "./modal-backdrop.jsx";
import Sidebar from "./sidebar.jsx";
import "./styles.css";
import {  Table, message,Layout } from "antd";
import Select from 'react-select'
import { listApi, saveApi } from "../../api/own-chassis";
import DoubleClickInput from "../../components/double-click-input";
import WebSocket from "../../api/webSocket";
const { Content} = Layout;

export default function ChassisTranstion ({
    isOpen,
    duration,
    closeTranstion
  }) {
    const [location, setLocation] = useState('LB Yard');
    const [dataList, setDataList] = useState([]);
    const sizeRef = useRef(65535);  //no page needed
    const pageRef = useRef(1);
    const [loading, setLoading] = useState(false);
    const [searchForm, setSearchForm] = useState(null);
    const [webSocketValue, setWebSocketValue] = useState('');
    const [selectLocation, setSelectLocation] = useState(-1);


    useEffect(() => {
      getInitData();
      makeConnection();
    }, [isOpen]);

    const getInitData = async () => {
      await getListData();
    };
    const getListData = async (
      page = pageRef.current,
      size = sizeRef.current
    ) => {
      setLoading(true);
      const result = await listApi({
        page,
        size,
        name: searchForm,
      });
      //console.log("searchForm,", result.list);
      setDataList(result.list);
      pageRef.current = page;
      sizeRef.current = size;
      setLoading(false);
    };
    


  const onMessage = (message) => {
    //const webSocketMessage = JSON.parse(message.data);
      //update dataList data in memory
      var data = JSON.parse(message.data);
      //console.log(message);
      if( data.key == 'ping') {
        //console.log("ping receive");
      } else {
        setWebSocketValue(message.data);
      }
  }
  const ping = () => {
    sendData("-1", "ping", "ping");
  }
  
  

  const makeConnection = () => {
      console.log("MakeConnection");
      WebSocket.getInstance().setCallback(onMessage);
      var intervalId = WebSocket.getInstance().getPingInvtervalId();
      console.log('get  websocket ping ' + intervalId);
      if(intervalId >=0 ) {
        clearInterval(intervalId);
        console.log('clear websocket ping');
      }
      intervalId = setInterval(ping, 10000);
      WebSocket.getInstance().setPingInvtervalId(intervalId);
      console.log('set websocket ping ' + intervalId);
    }
    const sendData = (id, key, value) => {
      var data = {
        id: id,
        chassis: key,
        value: value
      }
      try {
        var sendSuccess = WebSocket.getInstance().send(JSON.stringify(data));
        //var a = id.func();
        if(!sendSuccess) {
          //message.error("error send sync message, please try again");
          WebSocket.getInstance().close();
          makeConnection();
          console.log("send failed resent websocket");
          WebSocket.getInstance().send(JSON.stringify(data)); //send again
        }
      } catch(e) {
        //message.error("error send sync message, please try again");
        WebSocket.getInstance().close();
        makeConnection();
        console.log("exception resent websocket");
        WebSocket.getInstance().send(JSON.stringify(data)); //send again
      }
    }
    const setValueFromWebSocket = (id, key, value) => {
      var result = value;
      if(webSocketValue.length !== 0) {
        const webSocketMessage = JSON.parse(webSocketValue);
        //alert(webSocketValue);
        if(webSocketMessage["id"] === id && webSocketMessage["chassis"] === key && webSocketMessage["value"].length !== 0 ) {
          result = webSocketMessage["value"];
          var findIndex = dataList.findIndex((data) => data.id ==webSocketMessage.id);
          //alert(findIndex);
          var data = dataList[findIndex];
          data[webSocketMessage.chassis] = webSocketMessage["value"];
          console.log('websocket change dataList');
        }
      }
      return result;
    }

    const columnsList = ['LB Yard', 'New Yard','Old Yard','Industry Yard', 'In use'];


    let header = columnsList.map( (item) => {
      return (
        <button style= {{ backgroundColor: item === location ? 'yellow' : '#ccc'    }} className="title" onClick={() => setLocation(item) }>
           {item}    {dataList.filter(data => data.location==item).length}  </button> 
       ) 
    });
   
    const handleChange = async (id, name, value) => {
      var findIndex = dataList.findIndex((data) => data.id ==id);
      var data = dataList[findIndex];
      data[name] = value.value;
      sendData( id, name, value.value);   
      await saveApi({ id, [name]: value.value });
      setSelectLocation(-1);
    }
       
    const columns = 
    [
      {
        title: 'Location',
        align: "center",
        width: selectLocation== -1 ?  60 : 120 + 'px',
        //height: selectLocation== -1 ?  60 : 500 + 'px',
        render: (item, record,  index) => {
          //console.log('location item' + JSON.stringify(item) + '  record ' + JSON.stringify(record) +  ' index ' + index);
          var value = setValueFromWebSocket(record["id"], "location", record["location"]);
          return (
            record['id'] &&
            <div>
               <div id={index} style={{cursor: 'pointer',textAlign:'center', justifyContent: 'center',alignItems:'center',display: selectLocation != index ? 'block' : 'none' }} 
               onClick={ ()=>{
                setSelectLocation(index)
                }}>
                  &lt;&lt;&lt;</div>
            
              <div style={{display: selectLocation == index ? 'block' : 'none'}}>
                  <Select 
                id={record['id']}
                value={{value: value, label: value}}
                onBlur={() => {setSelectLocation(-1)}} 
                menuIsOpen={true}
                maxMenuHeight={200}
                minMenuHeight={10}
                onChange={(value)=> {
                  handleChange(record['id'], "location", value);
                }}
                options={columnsList.map((item) => ({ value: item, label: item }))}
              />
              </div>
            </div>
           
            
          );
        }
      },
      { 
        title: 'Chassis No',
        align: "center",
        width: "90px",
        render: (_, record) => {
          var value = setValueFromWebSocket(record["id"], "chassisNumber", record["chassisNumber"]);
          //var value = record['chassisNumber'];
          return (
            record['id'] &&
            <div style={{display: "flex", height:'100%'}}>
              <div style={{textAlign:'left', justifyContent: 'left',alignItems:'left', width:"90%",height:'100%'}}>
             {
              /*
              <DoubleClickInput
              id={record["id"]}
              name="chassisNumber"
              record={record}
              content={value}
              handleSave={saveApi}
              
              handleUpdateList={(value) => {
                console.log(value);
                sendData(record["id"], "chassisNumber", value);
              }}
            />
            */
              <span>{value}</span>
             } 
            </div>
            <div style={{cursor: 'pointer',textAlign:'right', justifyContent: 'right',alignItems:'right',width:"10%",height:'100%' }} onClick={ ()=>{
                  //clipboardCopy(record["container"]);
                  navigator.clipboard.writeText(value);
                  message.info("copied");
                }}>
              <img  src={require('../../assets/images/copy-content.png')}  width = "12" height = "12" />
            </div>
          </div>
            
          );
        },
      },
      { 
        title: 'Size',
        align: "center",
        width: "60px",
        render: (_, record) => {
          var value = setValueFromWebSocket(record["id"], "csize", record["csize"]);
          //var value = record['csize'];
          return (
            record['id'] &&
            <div>
            {
              /*
              <DoubleClickInput
              id={record["id"]}
              name="csize"
              record={record}
              content={value}
              handleSave={saveApi}
              handleUpdateList={(value) => {
                console.log(value);
                sendData(record["id"], "csize", value);
              }}
            />
            */
            <span>{value}</span>
           
            }
            </div>
            
          );
        },
      },
      { 
        title: 'Container',
        align: "center",
        width: "90px",
        render: (_, record) => {
          return (
            record['id'] &&
           <span  style={{textOverflow: 'ellipsis',
           overflow: 'hidden',
           whiteSpace : "wrap"}}>
            {record['carryContainer']}
           </span>
          );
        }
      },
      { 
        title: 'Notes',
        align: "left",
        width: "150px",
        textWrap: 'ellipsis' | 'word-break',
        render: (_, record) => {
          var value = setValueFromWebSocket(record["id"], "description", record["description"]);
          return (
            record['id'] &&
            <div style={{textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace : "wrap"}}>
              <DoubleClickInput
              id={record["id"]}
              name="description"
              record={record}
              content={value}
              textOverflow="ellipsis"
              handleSave={saveApi}
              ellipsize={true}
              handleUpdateList={(value) => {
                console.log(value);
                sendData(record["id"], "description", value);
                //getListData()
              }}
            />
            </div>
            
          );
       }
      }
  ];
  const height = window.innerHeight - 180;
  var tableData = dataList.filter(data => data.location==location)
  var blankRow = { location:'', csize:'', carryContainer:'', description:''};
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  tableData.push(blankRow);
  return (
    
    <Fragment>
      <Transition in={isOpen} timeout={duration}>
        {state => (
          <ModalBackdrop duration={duration} state={state}>
            <Sidebar duration={duration} state={state}>
              {isOpen && (
                <div style={{ height:height }}>
                  <div style={{ margin:10,textAlign: 'center'}}>
                   
                   <button 
                    onClick={() =>{
                      setLocation('LB Yard');
                      closeTranstion();
                    
                    }}>
                      
                      Close
                  </button> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                     Own Chassis Management  
                    </div>
                  <div className='flexbox-container'>
                    {header}
                  </div>
                  <div style={{ height: "100%" }}>
                    <Layout style={{ minHeight: "90vh" }}>            
                      <Table
                        style={{ height: height }}
                        className="ant-table-wrapper"
                        id='table-fixed-height'
                        scroll={{ x: '100%', y: height  }}
                        rowKey="id"
                        bordered
                        minHeight={'90vh'}
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                      />
                      <Content>
                        <div style={{ height: "100%" }} />
                      </Content>
                    </Layout>
                    </div>
                  </div>       
              )}
            </Sidebar>
          </ModalBackdrop>
        )}
      </Transition>
    </Fragment>
  );
}
