import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { listApi, saveApi, deleteApi, getApi } from "../../api/invoice-setting";
import validator from "validator";
import DoubleClickTextArea from "../../components/double-click-textarea";

export default function InvoiceSettingPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);

  const editRef = useRef({});

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async () => {
    setLoading(true);
    const result = await listApi({ name: searchForm });
    // console.log("searchForm,", searchForm);
    setDataList(result);
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    setShowAddModal(false);
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    if (!validator.isEmail(values["email"])) {
      message.error("Emaill Error.");
      return;
    }
    await saveApi({ ...values, currentUse: values["currentUse"] ? 1 : 0 });
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    // {
    //   title: "id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <Tooltip title={record["name"]}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {record["name"]}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Email #",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        return (
          <Tooltip title={record["email"]}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {record["email"]}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (_, record) => {
        return (
          <Tooltip title={record["address"]}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {record["address"]}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Active #",
      dataIndex: "currentUse",
      key: "currentUse",
      render: (_, record) => {
        return (
          <Tag
            color={record["currentUse"] === 1 ? "green" : ""}
            style={{ cursor: "pointer" }}
            onClick={async (e) => {
              e.preventDefault();
              await saveApi({
                id: record["id"],
                currentUse: record["currentUse"] === 0 ? 1 : 0,
              });
              getListData();
            }}
          >
            {record["currentUse"] === 1 ? "Currently Active" : "Not in Use"}
          </Tag>
        );
      },
    },
    {
      title: "Remark",
      dataIndex: "noted",
      key: "noted",
      ellipsis: true,
      width: "500px",
      render: (_, record) => {
        return (
          <Tooltip
            // placement="topLeft"
            title={
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {record["noted"]}
              </div>
            }
          >
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <DoubleClickTextArea
                id={record["id"]}
                name="noted"
                content={record["noted"]}
                handleUpdateList={getListData}
                handleSave={handleSave}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={(e) => {
              e.preventDefault();
              editRef.current = record;
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the config"
            description="Are you sure to delete this config?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) => setSearchForm(e.target.value)}
        allowClear
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        extra={
          <Button
            onClick={async () => {
              editRef.current = {};
              setAddLoading(true);
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Config
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={false}
        />
      </Card>
      <Modal
        title="Edit Invoice Setting"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={1000}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{
            ...editRef.current,
            currentUse: editRef.current["currentUse"] == 1,
          }}
          onChange={(e) => setAddLoading(false)}
          style={{ paddingTop: "25px" }}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="name"
            rules={[{ required: true, message: "Please input name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input email." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input address." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Active #" name="currentUse" valuePropName="checked">
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item label="Noted" name="noted">
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
