import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: center; /* 将子元素垂直居中 */
  align-items: center; /* 将子元素水平居中 */
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
`;

function VerticalLayout(props) {
  const { items } = props;

  return (
    <Container
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "4px",
        height: props.height || "100%",
      }}
    >
      {items.map((item, index) => (
        <Item key={index}>{item}</Item>
      ))}
    </Container>
  );
}

export default VerticalLayout;
