import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Table,
  Input,
  Space,
  Popconfirm,
  message,
  Tag,
} from "antd";
import { PAGE_SIZE } from "../../config";
import {
  listApi as listLogApi,
  saveApi as saveLogApi,
  deleteApi as deleteLogAPi,
} from "../../api/own-chassis-log";
import moment from "moment";
import { formatCurrency } from "../../utils/numUtils";

export default function ChassisLogPage({ chassisId }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listLogApi({
      page,
      size,
      chassisId,
      name: searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //确定删除log
  const confirmLogDelete = async (id) => {
    await deleteLogAPi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    // {
    //   title: "id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    { title: "Content #", dataIndex: "name", key: "name" },
    {
      title: "Amount #",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => formatCurrency(amount),
    },
    {
      title: "Type",
      dataIndex: "typeId",
      key: "typeId",
      render: (typeId) => (
        <Tag color={typeId === 0 ? "red" : "green"}>
          {typeId === 0 ? "Cost" : "Income"}
        </Tag>
      ),
    },
    {
      title: "Update Time",
      dataIndex: "updateTime",
      key: "updateTime",
      render: (updateTime) => {
        return (
          <span>
            {moment.utc(updateTime).local().format("MM/DD HH:mm ddd")}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "190px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size={1}>
          <Popconfirm
            title="Delete the record"
            description="Are you sure to delete this record?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmLogDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) => setSearchForm(e.target.value.trim())}
      />
      <Button
        // type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card title={titleNode} loading={loading}>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items`, total }}
        />
      </Card>
    </>
  );
}
