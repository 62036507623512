import { ajax } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//登录API
export const loginApi = (params) => {
  // console.log('loginApi', params);
  let bodyFormData = new FormData();
  bodyFormData.append("username", params["username"]);
  bodyFormData.append("password", params["password"]);
  return ajax("/sys/login", bodyFormData, reqType.POST);
};

//注册API
export const registerApi = async (data) => {
  return ajax("/sys/register", obj2formData(data), reqType.POST);
};

//发生注册验证码API
export const sendRegisterCodeApi = async (data) => {
  return ajax("/sys/sendRegisterCode", obj2formData(data), reqType.POST);
};

//退出登录API
export const logoutApi = async () => {
  return ajax("/sys/user/logout");
};

//刷新Token
export const refreshTokenApi = async () => {
  return ajax("/sys/user/reFreshToken");
};

//获取Amazon预约余额
export const memberBalanceApi = async () => {
  return ajax("/sys/user/balance");
};
