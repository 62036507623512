import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom"; // 导入路由相关组件

import { menuConfig } from "../../config/menuConfig";

const { SubMenu } = Menu;

const generateMenuItem = (item) => (
  <Menu.Item key={String(item.id)} icon={item.icon}>
    <Link to={item.path}>{item.name}</Link>
  </Menu.Item>
);

const generateSubMenu = (item) => (
  <SubMenu key={String(item.id)} icon={item.icon} title={item.name}>
    {item.children.map((subItem) => generateMenuItem(subItem))}
  </SubMenu>
);

export default function LeftSider() {
  const menuItems = menuConfig.map((item) =>
    item.children ? generateSubMenu(item) : generateMenuItem(item)
  );

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["100"]} //选中效果在哪个菜单
      defaultOpenKeys={["100"]} //展开哪个菜单
      style={{ height: "100%", borderRight: 0 }}
    >
      {menuItems}
    </Menu>
  );
}
