import { DatePicker, Modal } from "antd";
import React, { useState } from "react";
import { saveApi } from "../../api/loads";

export default function DoubleClickDatePicker({
  id,
  name,
  content,
  color,
  weight,
  handleUpdateList,
}) {
  const [open, setOpen] = useState(false);
  var value = content;

  return open ? (
    <DatePicker
      style={{ width: "120px" }}
      defaultOpen={true}
      onChange={async (date, dateString) => {
        value = dateString;
        //console.log("value", date, dateString);
        await saveApi({ id, [name]: dateString });
        setOpen(false);
        
        var endDate = new Date(date);
        const formattedDate = `${(endDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${endDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        handleUpdateList(formattedDate);
      }}
      onBlur={async (e) => {
        //setOpen(false);
      }}
      onOpenChange={async (e) => {
        console.log(" onOpenChange " + e + ' ' + value);
        if(e == false && value == content) {
          Modal.confirm({
            title: `Clear Date ?`,
            onOk: () => {
              var date = new Date(2000,1,1);
              console.log(date.toISOString().slice(0, 10));
              saveApi({ id, [name]: date.toISOString().slice(0, 10) });
              handleUpdateList("  ");
              setOpen(false);
            },
            onCancel: () => {
              // console.log("canceled");
              setOpen(false);
            },
          });
          
        }
      }}
      allowClear
    />
  ) : (
    <div
      onDoubleClick={(e) => {
        setOpen(true);
      }}
      style={{
        cursor: "pointer",
        color: color ? color : content ? "black" : "white",
        fontWeight: weight
      }}
    >
      {content || "N/A"}
    </div>
  );
}
