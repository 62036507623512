import React, { useEffect, useState } from "react";
import { Button, Space, Table, Row, Col, Divider, message } from "antd";
import { useReactToPrint } from "react-to-print";
import { getDetailsApi } from "../../api/invoice";
import { getApi as getCustomerInfoApi } from "../../api/customer";
import { getApi as getDriverInfoApi } from "../../api/driver";
import { selectListApi } from "../../api/charge-type";
import { getApi } from "../../api/invoice-setting";
import moment from "moment";
import { formatInvoiceId } from "../../utils/invoiceUtils";
import { result } from "lodash";

const G_COMPANY_NAME = "FEIFEI SYSTEM INC";
const G_COMPANY_EMAIL = "feifeisystem.inc@gmail.com";

function Invoice(props) {
  const { invoice } = props;
  const [details, setDetails] = useState([]);
  const [toInfo, setToInfo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [chargeType, setChargeType] = useState({});
  const componentRef = React.useRef(null);

  // console.log("invoice", invoice);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    let result = await getCompanyInfo();
    if (!result) return;
    await getChargeTypeData();
    await getToInfoData();
    await getInvoiceDetailData();
  };

  // 获取公司配置的Invoice信息
  const getCompanyInfo = async () => {
    const result = await getApi();
    if (!result) {
      message.error("Invoice Setting Error.");
      return null;
    }
    setCompanyInfo(result);
    return result;
  };

  //获取Charge名称
  const getChargeTypeData = async () => {
    const res = await selectListApi();
    let ct = {};
    res.forEach((item) => {
      ct[item.id] = item.name;
    });
    // console.log("ct", ct);
    setChargeType(ct);
  };

  //获取接收Invoice公司的信息
  const getToInfoData = async () => {
    let info = null;
    if (invoice.invoiceType === 1) {
      info = await getDriverInfoApi(invoice.partyId);
    } else {
      info = await getCustomerInfoApi(invoice.partyId);
    }
    // console.log("toInfo", info);
    setToInfo(info);
  };

  //获取Invoice的详细信息
  const getInvoiceDetailData = async () => {
    const res = await getDetailsApi(invoice.id);
    // console.log("res", res);
    setDetails(res);
  };

  //点击打印，保存到PDF的处理
  const handlePrint = useReactToPrint({
    documentTitle:
      formatInvoiceId(invoice["id"]) + "-" + invoice["loadNumber"] + ".pdf", // specify the file name here
    pageStyle: "@page { size: A4; page-break-after: always; }",
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      const headerStyle = {
        textAlign: "center",
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 10,
      };
      const pdfContent = (
        <div>
          <div style={headerStyle}>Invoice</div>
          <div style={{ marginBottom: 20 }}>
            <div>{invoice.id}</div>
            <div>{invoice.createTime}</div>
          </div>
        </div>
      );
      return pdfContent;
    },
  });

  //计算Due Date加7个工作日
  const getDueData = (createTime, days = 7) => {
    const originalDate = moment.utc(createTime).local();
    let newDate = originalDate.clone();
    let count = 0;
    while (count < days) {
      newDate = newDate.add(1, "days");
      if (newDate.weekday() !== 0 && newDate.weekday() !== 6) {
        count++;
      }
    }
    const formattedDate = newDate.format("YYYY-MM-DD");
    // console.log(formattedDate);
    return formattedDate;
  };

  //格式化美元输出
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const columns = [
    {
      title: "Item",
      dataIndex: "description",
      key: "description",
      render: (_, record) => {
        // console.log("record", record);
        return (
          <>
            <Row style={{ fontWeight: 500 }}>
              {chargeType[record.chargeTypeId]}
            </Row>
            <Row
              style={{
                wordBreak: "break-word",
                width: "400px",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {record["description"]}
            </Row>
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "unitCount",
      key: "unitCount",
      align: "right",
    },
    {
      title: "Price",
      dataIndex: "rate",
      key: "rate",
      align: "right",
      render: (value) => formatter.format(value),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (_, record) => formatter.format(record.unitCount * record.rate),
    },
  ];

  return (
    companyInfo && (
      <div
        pid={`${formatInvoiceId(invoice["id"])}-${invoice["loadNumber"]}`}
        className="pdf-element"
        style={{ margin: "0px 40px", minHeight: "202mm" }}
        ref={componentRef}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <div>
            <h2>{companyInfo["name"]}</h2>
            <Space>
              <span style={{ fontWeight: 500, fontSize: 16 }}>Email</span>{" "}
              <span>{companyInfo["email"]}</span>
            </Space>
          </div>
          <div>
            <h1>Invoice</h1>
            <h3>{companyInfo["name"]}</h3>
          </div>
        </div>

        <Row style={{ width: "100%", marginBottom: "2rem" }}>
          <Row style={{ width: "100%" }}>
            <Col span={18}>
              <span style={{ fontSize: "18px", fontWeight: 600 }}>BILL TO</span>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: 500 }}>
                  Invoice #
                </span>
                <span>{formatInvoiceId(invoice["id"])}</span>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col span={18}>
              <span style={{ fontSize: "16px" }}>{toInfo?.companyName}</span>
            </Col>
            <Col span={6}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Date</span>
                <span>
                  {moment
                    .utc(invoice["createTime"])
                    .local()
                    .format("YYYY-MM-DD")}
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col span={18} style={{ overflow: "hidden" }}>
              <span
                style={{ fontSize: "13px" }}
              >{`Container# ${invoice?.loadNumber}`}</span>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                <span>Due Date</span>
                <span>{getDueData(invoice["createTime"])}</span>
              </div>
            </Col>
          </Row>
        </Row>

        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={details}
          pagination={false}
          footer={() => (
            <div style={{ fontSize: "16px" }}>
              <div style={{ textAlign: "right", height: "40px" }}>
                <strong>Subtotal: </strong>
                <span style={{ marginLeft: "10px" }}>
                  {formatter.format(invoice.amount)}
                </span>
              </div>
              <div style={{ textAlign: "right" }}>
                <strong>Total: </strong>
                <span style={{ marginLeft: "10px" }}>
                  {formatter.format(invoice.amount)}
                </span>
              </div>
            </div>
          )}
        />

        <Row style={{ width: "100%", marginTop: "2rem" }}>
          <pre>
            {/* PLEASE REMIT THE PAYMENT AT YOUR EARLIEST CONVENIENCE.
            <br />
            <br />
            BENEFICIARY BANK: JPMORGAN CHASE BANK, N.A
            <br />
            BANK ADDRESS: 270 Park Ave., New York, NY 10017
            <br />
            BENEFICIARY NAME: FEIFEI SYSTEM INC.
            <br />
            BILLING ADDRESS: 713 W DUARTE RD UNIT G 124 ARCADIA, CA 91007
            <br />
            <br />
            BENEFICIARY ACCOUNT: 919903879
            <br />
            <br />
            ACH ROUTING#: 322271627 SWIFT CODE: CHASUS33
            <br /> */}
            {companyInfo["noted"]}
          </pre>
        </Row>

        <div id="printButton" style={{ marginTop: "2rem", textAlign: "right" }}>
          <Button type="primary" onClick={handlePrint}>
            Print
          </Button>
        </div>
      </div>
    )
  );
}

export default Invoice;
