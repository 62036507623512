import { DatePicker, Modal } from "antd";
import React, { useState } from "react";
import { saveApi } from "../../api/loads";
import moment from "moment";

export default function DoubleClickTimePicker({
  id,
  name,
  content,
  handleUpdateList,
}) {
  const [open, setOpen] = useState(false);

  var value = content;
  return open ? (
    <DatePicker
      style={{ width: "120px" }}
      defaultOpen={true}
      showTime={{
        format: "HH:mm",
      }}
      format= {"YYYY-MM-DD HH:mm"}
      minuteStep={30}
      onChange={async (date, dateString) => {
        value = dateString;
        if (name === "emptyDate") {
          //如果手动设置了报空时间，需要转换成UTC时间字符串
          dateString = moment(dateString, "YYYY-MM-DD HH:mm")
            .utc()
            .format("YYYY-MM-DD HH:mm");
        } 
               // console.log("value", date, dateString);
        await saveApi({ id, [name]: dateString });
        if(name !==  "emptyDate") {
          dateString = moment(dateString, "YYYY-MM-DD HH:mm")
          .format("MM/DD HH:mm");
        }
        handleUpdateList(dateString);
        setOpen(false);
      }}
      onOpenChange={async (e) => {
        console.log(" onOpenChange " + e);
        if(e == false && value == content ) {
          if (name === "emptyDate") {
            setOpen(false);
            return false; 
          }
          Modal.confirm({
            title: `Clear Date ?`,
            onOk: () => {
              var date = new Date(2000,1,1);
              console.log(date.toISOString().slice(0, 10));
              saveApi({ id, [name]: moment.utc(date).format("YYYY-MM-DD HH:mm")});
              handleUpdateList("  ");
              setOpen(false);
            },
            onCancel: () => {
              // console.log("canceled");
              setOpen(false);
            },
          });
        }
      }}
    />
  ) : (
    <div
      onDoubleClick={(e) => {
        setOpen(true);
      }}
      style={{ cursor: "pointer", color: content ? "black" : "white" }}
    >
      {content || "N/A"}
    </div>
  );
}
