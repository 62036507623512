import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Space,
  Tag,
  Dropdown,
  message,
  Cascader,
  Divider,
  Tooltip,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  listApi,
  updateStatusApi,
  saveApi,
  deleteApi,
  totalApi,
  downloadApi,
  updateAllStatusApi,
  downloadQBApi,
} from "../../api/invoice";
import InvoiceEditModal from "../../components/invoice-edit-modal";
import InvoicePrintModal from "../../components/invoice-print-modal";
import { selectListApi as customerSelectListApi } from "../../api/customer";
import { selectListApi as driverSelectListApi } from "../../api/driver";
import ListAllInvoiceDiv from "../../components/list-all-invoice";
import dayjs from "dayjs";
import { formatLines } from "../../utils/searchUtils";
import { formatMoney } from "../../utils/invoiceUtils";

const { RangePicker } = DatePicker;

//账单状态
const invoiceStatusDef = [
  { name: "INIT", color: "red" },
  { name: "CFMD", color: "green" },
  { name: "Paid", color: "gray" },
];

export default function AccountingPage() {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalData, setTotalData] = useState({
    total: 0,
    paid: 0,
    cfmd: 0,
    balence: 0,
  });
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState({});
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(
    "Destination Revenue(Invoice/AR)"
  );
  const [addType, setAddType] = useState(null);
  const [printOpen, setPrintOpen] = useState(false);
  const [listAllOpen, setListAllOpen] = useState(false);
  //多级联动选择Party
  const [options, setOptions] = useState([]);
  //多选选择了哪些行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //是否选择了选中所有行
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  //是否按照选中行过滤
  const [filterBySelect, setFilterBySelect] = useState(null);

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});
  const editInvoiceRef = useRef(null);
  const printInvoiceRef = useRef(null);

  useEffect(() => {
    getInitData();
  }, []);

  useEffect(() => {
    // console.log("filterBySelect change", filterBySelect);
    if (filterBySelect !== null) {
      getListData();
      //获取搜索条件下的统计数据
      getTotalData();
    }
  }, [filterBySelect]);

  useEffect(() => {
    if (selectedRowKeys.length === 0 && filterBySelect) {
      setFilterBySelect(false);
      getListData();
      //获取搜索条件下的统计数据
      getTotalData();
    }
  }, [selectedRowKeys]);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
    await getSelectListData();
    //获取搜索条件下的统计数据
    await getTotalData();
  };

  //判断是否需要按照选中的行过滤
  const getIds = () => {
    return filterBySelect ? selectedRowKeys.join(",") : null;
  };

  //获取总计数据
  const getTotalData = async () => {
    const res = await totalApi({ ...searchForm, ids: getIds() });
    // console.log("total", res);
    let total = 0,
      paid = 0,
      cfmd = 0,
      balence = 0;
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      total += element["amount"];
      if (element["status"] === 2) {
        paid += element["amount"];
      } else if (element["status"] === 1) {
        cfmd += element["amount"];
      } else {
        balence += element["amount"];
      }
    }
    setTotalData({ total, paid, balence, cfmd });
  };

  //构建Party Name多级联动搜索数据结构
  const getSelectListData = async () => {
    const customerList = await customerSelectListApi();
    const driverList = await driverSelectListApi();
    setOptions([
      {
        value: "AR",
        label: "AR",
        children: customerList.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      },
      {
        value: "DC",
        label: "DC",
        children: customerList.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      },
      {
        value: "AP",
        label: "AP",
        children: driverList.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      },
    ]);
  };

  //处理Action被点击的事件
  const handleActionClick = async (action, record) => {
    printInvoiceRef.current = null;
    // console.log("handleActionClick", action, record);
    if (action === "delete") {
      Modal.confirm({
        title: "Are you sure?",
        onOk: async () => {
          // console.log("Confirmed!");
          await deleteApi(record.id);
          //刷新列表
          getListData();
        },
      });
    } else if (action === "print") {
      printInvoiceRef.current = record;
      setPrintOpen(true);
    }
  };

  //处理Action中的Status被点击的事件
  const handleStatusClick = async (status, record) => {
    // console.log("handleStatusClick", status, record);
    if (record["status"] !== status) {
      await updateStatusApi({
        invoiceId: record.id,
        status,
      });
      getListData();
      message.success("Success");
    }
  };

  //处理所有的Action中更新状态
  const handleUpdateAllStatus = async (status) => {
    if (selectedRowKeys.length === 0) {
      message.warning("Please select update rows first.");
      return;
    }
    //只处理选中部分的状态
    await updateAllStatusApi({ ids: selectedRowKeys.join(","), status });
    message.success("Success");
    getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    // console.log("getListData", page, size);
    setLoading(true);
    const result = await listApi({
      page,
      size,
      ...searchForm,
      ids: getIds(),
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
    //判断是否有选择选中全部
    if (isSelectedAllRows) {
      //如果选择了选择全部，则把所有的数据id都加入到选择列表中
      const uniqueArray1 = [...new Set(result.list.map((item) => item.id))];
      const uniqueArray2 = [...new Set(selectedRowKeys)];
      const mergedArray = [...uniqueArray1, ...uniqueArray2];
      setSelectedRowKeys(mergedArray);
    }
  };

  //点击了编辑
  const handleEdit = (record) => {
    editInvoiceRef.current = record;
    const type = ["AR", "AP", "DC"];
    handleOpenModal(type[record.invoiceType]);
  };

  //打开编辑或者添加框
  const handleOpenModal = (type) => {
    const name = {
      AR: "Destination Revenue(Invoice/AR)",
      DC: "Origin Revenue/Cost(D/C Note)",
      AP: "Destination Cost(AP)",
    };
    setModalTitle(
      name[type] +
        " MBL#" +
        editInvoiceRef.current["mblNumber"] +
        " CNT#" +
        editInvoiceRef.current["loadNumber"]
    );
    setAddType(type);
    setOpen(true);
  };

  //处理Invoice保存
  const handleInvoiceSave = async (values) => {
    //账单类型
    const invoiceType = {
      AR: 0,
      AP: 1,
      DC: 2,
    };
    const _data = {
      invoiceId: editInvoiceRef.current?.id || null,
      partyId: values.vendor, //账单给谁的
      loadId: editInvoiceRef.current?.loadId || null, //Load关联
      invoiceType: invoiceType[addType], //Invoice类型
      invoiceDetails: JSON.stringify(values.dataList),
    };
    // console.log("handleInvoiceSave, ", _data);
    await saveApi(_data);
    setOpen(false);
    editInvoiceRef.current = null;
    //刷新列表
    getListData();
  };

  //选择全部或者取消全部
  const onSelectAllChange = (selected, selectedRows) => {
    // console.log("onSelectAllChange", selected, selectedRows);
    setIsSelectedAllRows(selected);
    if (selected) {
      //如果用户选择了全选，把所有数据加入到选中列表中
      const newArray = dataList.map((item) => item.id);
      setSelectedRowKeys(newArray);
    } else {
      //清空所有选中的列
      setSelectedRowKeys([]);
    }
  };

  //表格多选的定义
  const rowSelection = {
    selectedRowKeys,
    onSelectAll: onSelectAllChange,
    onSelect: (record, selected) => {
      // console.log("(onSelect)", record, selected, selectedRows, nativeEvent);
      if (selected) {
        //如果是选择加入到选择列表中
        setSelectedRowKeys([...selectedRowKeys, record["id"]]);
      } else {
        //从选择列表中移除
        const newArray = selectedRowKeys.filter(
          (element) => element !== record["id"]
        );
        setSelectedRowKeys(newArray);
      }
    },
  };

  const columns = [
    {
      title: "",
      width: "80px",
      render: (record) => {
        const type = ["AR", "AP", "D/C"];
        return <Tag>{type[record["invoiceType"]]}</Tag>;
      },
    },
    {
      title: "Invoice No.",
      dataIndex: "id",
      width: "130px",
      render: (_, record) => {
        if (record["invoiceType"] === 1) {
          return (
            <a href="#" onClick={(e) => handleEdit(record)}>
              view
            </a>
          );
        }
        const no = record["id"].toString().padStart(5, "0");
        return (
          <a href="#" onClick={(e) => handleEdit(record)}>
            {(record["invoiceType"] === 0 ? "IN-" : "DC-") + no}
          </a>
        );
      },
    },
    { title: "Load #", dataIndex: "loadNumber" },
    {
      title: "Party",
      dataIndex: "partyName",
      render: (_, record) => {
        return (
          <div
            style={{
              width: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={record["partyName"]}>{record["partyName"]}</Tooltip>
          </div>
        );
      },
    },
    { title: "Custmer", dataIndex: "customerName" },
    {
      title: "Revenue",
      dataIndex: "revenue",
      width: "130px",
      align: "right",
      render: (_, record) => {
        const number = (record["invoiceType"] !== 1 && record.amount) || 0;
        return <span>{formatMoney(number)}</span>;
      },
    },
    {
      title: "Cost",
      dataIndex: "cost",
      width: "130px",
      align: "right",
      render: (_, record) => {
        const number = (record["invoiceType"] === 1 && record.amount) || 0;
        return <span>{formatMoney(number)}</span>;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      width: "130px",
      align: "right",
      render: (_, record) => {
        const number = (record["invoiceType"] === 0 && record.amount) || 0;
        return <span>{formatMoney(number)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "110px",
      align: "center",
      render: (_, record) => {
        const status = invoiceStatusDef[record["status"]];
        return <Tag color={status?.color}>{status?.name}</Tag>;
      },
    },
    // {
    //   title: "Post Date	",
    //   dataIndex: "postDate",
    //   align: "right",
    //   width: "110px",
    //   render: (_, record) => {
    //     return (
    //       <span>
    //         {moment.utc(record["updateTime"]).local().format("YYYY-MM-DD")}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: "110px",
      align: "right",
      render: (_, record) => {
        return (
          <span>
            {moment.utc(record["createTime"]).local().format("YYYY-MM-DD")}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "110px",
      align: "center",
      render: (_, record) => {
        const items = [
          {
            label: (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleActionClick("print", record);
                }}
              >
                Print/Email
              </div>
            ),
            key: "0",
          },
          {
            label: (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleActionClick("delete", record);
                }}
              >
                Delete
              </div>
            ),
            key: "1",
          },
          {
            type: "divider",
          },
          {
            label: "Status",
            key: "3",
            children: invoiceStatusDef.map((item, index) => {
              return {
                label: (
                  <div
                    style={{
                      color: record["status"] === index ? "gray" : "",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleStatusClick(index, record);
                    }}
                  >
                    {item.name}
                  </div>
                ),
                key: String(100 + index),
              };
            }),
          },
        ];
        return (
          <Dropdown
            placement={"bottomRight"}
            menu={{ items }}
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer", color: "gray" }}>
              <Space size={3}>
                Action
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  //选择了某一个Party的时候出发调用
  const onPartySelectChange = (value) => {
    // console.log(value);
    if (!value) {
      setSearchForm({ ...searchForm, invoiceType: null, partyId: null });
    } else {
      setSearchForm({
        ...searchForm,
        partyId: value[1],
        invoiceType: value[0] === "AP" ? 1 : value[0] === "DC" ? 2 : 0,
      });
    }
  };

  //Party选择的过滤函数
  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  //不能选择超过今天的日期
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  //预设选择的时间范围
  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];
  //选择了时间范围
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setSearchForm({
        ...searchForm,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      // console.log("Clear");
      setSearchForm({ ...searchForm, startDate: null, endDate: null });
    }
  };

  const titleNode = (
    <Space>
      <Cascader
        style={{ width: "260px" }}
        options={options}
        onChange={onPartySelectChange}
        placeholder="Please select"
        showSearch={{
          filter,
        }}
        // onSearch={(value) => console.log(value)}
      />
      <Input
        placeholder="Search..."
        style={{ width: 200 }}
        onChange={(e) => {
          let value = e.target.value;
          value = formatLines(value);
          // console.log("value", value, value.split(","));
          setSearchForm({ ...searchForm, name: value });
        }}
        allowClear
      />
      <Select
        defaultValue={null}
        style={{ width: "90px" }}
        placeholder="Status"
        allowClear
        onChange={(value) => {
          setSearchForm({ ...searchForm, status: value });
        }}
      >
        <Select.Option value={0}>INIT</Select.Option>
        <Select.Option value={1}>CFMD</Select.Option>
        <Select.Option value={2}>PAID</Select.Option>
      </Select>
      {/* 选择时间范围 */}
      <RangePicker
        disabledDate={disabledDate}
        presets={rangePresets}
        onChange={onRangeChange}
      />
      <Button
        type="primary"
        // style={{ margin: "0 10px" }}
        onClick={async () => {
          //点击搜索的时候
          await getListData(1);
          await getTotalData();
        }}
      >
        Search
      </Button>
      {selectedRowKeys.length > 0 && (
        <Space style={{ marginLeft: "8px" }}>
          <span
            style={{
              fontSize: "12px",
            }}
          >
            {`Selected ${selectedRowKeys.length} items`}
          </span>
          <Checkbox
            onChange={(e) => {
              setFilterBySelect(e.target.checked);
            }}
          />
        </Space>
      )}

      <span
        style={{
          fontSize: "12px",
          position: "absolute",
          top: "-37px",
          left: "30%",
        }}
      >
        Total: <Tag>{formatMoney(totalData["total"])}</Tag>
        <Divider type="vertical" />
        Paid: <Tag>{formatMoney(totalData["paid"])}</Tag>
        <Divider type="vertical" />
        CFMD: <Tag>{formatMoney(totalData["cfmd"])}</Tag>
        <Divider type="vertical" />
        Balance: <Tag color="#45B6AE">{formatMoney(totalData["balence"])}</Tag>
      </span>
    </Space>
  );

  //对所有进行操作的Action选项
  // const allActionItems = ;
  const items = [
    {
      label: (
        <div
          onClick={async (e) => {
            e.preventDefault();
            await downloadQBApi(
              { ...searchForm, ids: getIds() },
              "qb-invoice.xlsx"
            );
          }}
        >
          QB Excel
        </div>
      ),
      key: "10",
    },
    {
      label: (
        <div
          onClick={async (e) => {
            e.preventDefault();
            await downloadApi({ ...searchForm, ids: getIds() }, "invoice.xlsx");
          }}
        >
          Excel
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            setListAllOpen(true);
          }}
        >
          PDF
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "Status",
      key: "3",
      children: invoiceStatusDef.map((item, index) => {
        return {
          label: (
            <div
              onClick={(e) => {
                e.preventDefault();
                handleUpdateAllStatus(index);
              }}
            >
              {item.name}
            </div>
          ),
          key: String(100 + index),
        };
      }),
    },
  ];

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        extra={
          <Space size={20}>
            <Button
              onClick={async () => {
                editRef.current = {};
              }}
              icon={<PlusOutlined />}
              type="primary"
            >
              Add
            </Button>

            <Dropdown
              placement={"bottomRight"}
              arrow={true}
              menu={{
                items,
              }}
            >
              <div style={{ cursor: "pointer", color: "gray" }}>
                <Space size={3}>
                  Action
                  <DownOutlined />
                </Space>
              </div>
            </Dropdown>

            {/* <Button
              onClick={async () => {
                await downloadApi(
                  { ...searchForm, ids: getIds() },
                  "invoice.xlsx"
                );
              }}
            >
              Excel
            </Button>
            <Button
              onClick={async () => {
                setListAllOpen(true);
              }}
            >
              PDF
            </Button>
            <Button
              onClick={async () => {
                setListAllOpen(true);
              }}
            >
              Analysis
            </Button> */}
          </Space>
        }
      >
        <Table
          rowKey="id"
          bordered
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataList}
          pagination={{
            total: total,
            defaultPageSize: sizeRef.current,
            current: pageRef.current,
            onChange: getListData,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </Card>
      {/* 编辑invoice Modal */}
      <InvoiceEditModal
        title={modalTitle}
        open={open}
        setOpen={setOpen}
        addType={addType}
        invoice={editInvoiceRef.current}
        handleClose={() => {
          editInvoiceRef.current = null;
          setOpen(false);
        }}
        handleInvoiceSave={(values) => handleInvoiceSave(values)}
      />
      {/* 打印Invoice Modal */}
      <InvoicePrintModal
        open={printOpen}
        invoice={printInvoiceRef.current}
        title={"Invoice-Print"}
        setPrintOpen={setPrintOpen}
      />
      {/* 打印所有的Invoice */}
      {listAllOpen && (
        <ListAllInvoiceDiv
          title={"List All Invoice"}
          open={listAllOpen}
          searchForm={{ ...searchForm, ids: getIds() }}
          setPrintOpen={setListAllOpen}
        />
      )}
    </>
  );
}
