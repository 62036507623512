//普通对象转FormData格式
export const obj2formData = (param) => {
  if(isEmpty(param)) return null;
  let form_data = new FormData();
  for (let key in param) {
    form_data.append(
      key,
      param[key] === null || param[key] === undefined ? '' : param[key]
    );
  }
  return form_data;
};

//判断是否是空对象
export const isEmpty = (obj) => {
  if (obj === null || obj === undefined || obj === '') {
    return true;
  }
  return Object.keys(obj).length === 0;
};

//去除收尾空白，大写字符串
export const trimAndUpperCase = (inputString) => {
  if(!inputString) {
    return '';
  }
  // 移除前导和尾随空白字符
  const trimmedString = inputString.trim();
  // 将字符串中的连字符 '-' 替换为空字符串 ''
  const hyphenRemovedString = trimmedString.replace(/-/g, '');
  // 将字符串中的连续空白字符替换为一个空白字符
  const cleanedString = hyphenRemovedString.replace(/\s+/g, ' ');
  // 将字符串转换为大写
  const uppercasedString = cleanedString.toUpperCase();
  return uppercasedString;
}