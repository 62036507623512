import { ajax, downloadFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (params) =>
  {
    // console.log('params,', {...params});
    return ajax("/sys/ownChassisLog/list", {...params});
  }

//添加
export const saveApi = (data) =>
  ajax("/sys/ownChassisLog/save", obj2formData(data), reqType.POST);

//删除
export const deleteApi = (id) =>
  ajax("/sys/ownChassisLog/delete", { id }, reqType.GET);
