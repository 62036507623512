import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { listApi } from "../../api/loads/track-log";
import moment from "moment";
import { FcCheckmark, FcDislike } from "react-icons/fc";

export default function LoadLogs({ record: baseInfo }) {
  const [dataList, setDataList] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getDataList();
  }, []);

  //获取列表数据
  const getDataList = async () => {
    const result = await listApi({ loadId: baseInfo["id"] });
    // console.log("result", result);
    setDataList(result.list);
    setTotal(result.total);
  };

  const fixedColumns = [
    // { title: "CNTR #", dataIndex: "container", fixed: true },
    {
      title: "Update Time",
      dataIndex: "createTime",
      width: 120,
      fixed: "left",
      render: (createTime) => {
        return moment.utc(createTime).local().format("MM-DD HH:mm:ss");
      },
    },
    {
      title: "TERMINAL",
      dataIndex: "terminal",
      width: 100,
      align: "center",
    },
    {
      title: "AVAILABLE",
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (text) => <Tooltip title={text}>{text}</Tooltip>,
    },
    {
      title: "Location",
      dataIndex: "location",
      render: (location) => <Tooltip title={location}>{location}</Tooltip>,
    },
    { title: "D/W", dataIndex: "deckWheeled", width: 80 },
    {
      title: "Pickup Appt",
      dataIndex: "pickupAppt",
      render: (pickupAppt) => (
        <Tooltip title={pickupAppt}>{pickupAppt}</Tooltip>
      ),
    },
    { title: "LFD #", dataIndex: "lfd", width: "110px" },
    {
      title: "Customs Hold",
      dataIndex: "customsHold",
      align: "center",
      render: (hold) => {
        return hold ? <FcDislike /> : <FcCheckmark />;
      },
    },
    {
      title: "LINE Hold",
      dataIndex: "lineHold",
      align: "center",
      render: (hold) => {
        return hold ? <FcDislike /> : <FcCheckmark />;
      },
    },
    {
      title: "CTF Hold",
      dataIndex: "ctfHold",
      align: "center",
      render: (hold) => {
        return hold ? <FcDislike /> : <FcCheckmark />;
      },
    },
    {
      title: "TMF Hold",
      dataIndex: "tmfHold",
      align: "center",
      render: (hold) => {
        return hold ? <FcDislike /> : <FcCheckmark />;
      },
    },
    {
      title: "Other Holds",
      dataIndex: "otherHolds",
      render: (hold) => <Tooltip title={hold}>{hold}</Tooltip>,
    },
    { title: "Demurrage", dataIndex: "demurrage" },
    { title: "SSCO", dataIndex: "ssco", width: 60 },
    {
      title: "SIZE",
      dataIndex: "containerType",
      width: 100,
      align: "center",
      render: (size) => <Tooltip title={size}>{size}</Tooltip>,
    },
  ];

  return (
    <div style={{ marginInline: "-40px", width: "100%" }}>
      <Table
        bordered
        size="small"
        scroll={{ x: "100vw" }}
        columns={fixedColumns}
        dataSource={dataList}
        pagination={{ showTotal: (total) => `Total ${total} items` }}
      />
    </div>
  );
}
