
import { WebSocketUrl } from "./config";
const W3CWebSocket = require("websocket").w3cwebsocket;


export default class WebSocket {

    static instance;

    static getInstance() {
      if (WebSocket.instance == null) {
        WebSocket.instance = new WebSocket();   
      }
      return WebSocket.instance;
    }
    constructor(){
        this.client =  new W3CWebSocket(WebSocketUrl + "/ws", "index");
        this.client.onclose = this.onClose;
        this.client.onopen = this.onOpen;
        this.client.onerror = this.onError;
        this.client.onmessage = this.onMessage;
        this.interval = -1;
        this.callback = [];
    }   
    send(data) {
      //console.log('get') 
      try {
        if(this.client.readyState == this.client.OPEN) {
          this.client.send(data);
          return true;
        } else {
          return false;
        }
          
      } catch(e) {
        console.log("send Mesage Error"); 
        return false;
      }
      
    } 
    onOpen(opened) {
        console.log("connection is opened type ", opened.type);
    }
    onError(error) {
        console.log("Connection is ", error);
    }
    onMessage(message) {
      if(WebSocket.instance.callback) {
        WebSocket.instance.callback.map((callback) => {
          callback(message);
        });
      }
      
    }
    onClose(closed) {
        console.log("Connection is closed type ", closed.type);
    }
    setCallback(callback) {
      WebSocket.instance.callback.push(callback);
      console.log("callback set" + this.callback.length);
    }
    close() {
      WebSocket.instance.client.close();
      WebSocket.instance = null;
    }
    setPingInvtervalId(interval) {
      WebSocket.instance.interval = interval;
    }
    getPingInvtervalId() {
      return WebSocket.instance.interval;
    }
    
}
  
