import React from "react";
import { Form, Input, Button } from "antd";
import styles from "./login.module.css";

export default function LoginForm(props) {
  //提交表单且数据验证成功后回调事件
  const onFinish = (values) => {
    // console.log('Success:', values);
    const { username, password } = values;
    props.handleLogin({ username, password });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // marginTop: "30px",
        // height: "100vh",
      }}
    >
      {/* <div style={{ width: "100%", textAlign: "left" }}>
        <h2 style={{ color: "#363636" }}>Login</h2>
      </div> */}
      <div className={styles.loginForm}>
        <Form
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 18,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input Email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 18,
            }}
          >
            <Button block size="large" type="primary" htmlType="submit">
              Login
            </Button>
            {/* <div>
              <p>
                No account yet?{" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    props.switchTo("register");
                  }}
                >
                  Register (Get Free Quote)
                </a>
              </p>
            </div> */}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
