import { ajax, downloadFile, getUrlFile } from "../ajax";
import { ReqType as reqType } from "../config";
import { obj2formData } from "../../utils/objectUtils";

//列表
export const listApi = (data) =>
  ajax("/sys/loads/list", obj2formData(data), reqType.POST);

//添加
export const saveApi = (data) =>
  ajax("/sys/loads/save", obj2formData(data), reqType.POST);

//获取柜子信息
export const getByIdsApi = (ids) =>
  ajax("/sys/loads/getByIds", obj2formData({ ids }), reqType.POST);

//更新PierPass状态
export const updatePierPassApi = (ids, status) =>
  ajax("/sys/loads/pierpass", obj2formData({ ids, status }), reqType.POST);

//删除
export const deleteApi = (id) => ajax("/sys/loads/delete", { id }, reqType.GET);

//上传文件
export const uploadApi = (file, loadId) =>
  ajax("/sys/loads/uploadFile", obj2formData({ file, loadId }), reqType.POST);

//上传Packing List文件
export const uploadPackingListApi = (data) =>
  ajax("/sys/loads/uploadPackingList", obj2formData(data), reqType.POST);

//删除Packing List信息
export const deletePackingListApi = (loadId) =>
  ajax("/sys/loads/deletePackingList", { loadId });

//获取Packing List信息
export const getPackingListFileContentApi = (loadId) =>
  getUrlFile("/sys/loads/getPackingList", { loadId });

//删除上传文件
export const delUploadApi = (loadId, attachId) =>
  ajax(
    "/sys/loads/deleteFile",
    obj2formData({ loadId, attachId }),
    reqType.POST
  );

//列表
export const getApi = (id) => ajax("/sys/loads/get", { id });

//下载PDF
export const downloadPdfApi = async (url, filename) =>
  await downloadFile("/sys/loads/downloadPDF", filename, url);

//列表
export const getFilesApi = (loadId) =>
  ajax("/sys/loads/getFiles", { id: loadId });

//同步柜子状态
export const syncContainerStatusApi = () => ajax("/sys/sync/container/work");
