import { Tooltip, DatePicker, Space, Button, Input } from "antd";
import React, { useState } from "react";
import { saveApi } from "../../api/loads";

const s_chassis = ["EMCZ", "PierA", "STCP", "IMB", "UAT"];

export default function DoubleClickTextArea({
  id,
  name,
  content,
  handleUpdateList,
  handleSave = null,
}) {
  const [open, setOpen] = useState(false);

  //获取文本的颜色
  const getContentBgColor = () => {
    if (content && name === "chassisNo") {
      for (let index = 0; index < s_chassis.length; index++) {
        const element = s_chassis[index];
        if (content.toLowerCase().includes(element.toLowerCase())) {
          return "#E3B0E4";
        }
      }
    }
    return "white";
  };

  return open ? (
    <Input.TextArea
      autoFocus={true}
      defaultValue={content}
      onBlur={async (e) => {
        let value = e.target.value.trim();
        // console.log("onBlur", value);
        setOpen(false);
        if (handleSave) {
          await handleSave({ id, [name]: value });
        } else {
          await saveApi({ id, [name]: value });
        }
        handleUpdateList(value);
      }}
    />
  ) : (
    <div
      onDoubleClick={(e) => {
        setOpen(true);
      }}
      style={{
        cursor: "pointer",
        backgroundColor: getContentBgColor(),
        color: content ? "black" : "white",
        paddingRight: "10px",
      }}
    >
      {content || "N/A"}
    </div>
  );
}
