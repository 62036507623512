import store from 'store';

import { userKey } from '../config/index';

export const userOptions = {
  //存储用户信息在本地
  saveUserInfo: (userObj) => {
    store.set(userKey, userObj);
  },

  //获取存储在本地的token
  getToken: () => {
    let userObj = store.get(userKey);
    if (userObj) {
      return userObj.token || '';
    }
    return '';
  },

  //获取存储在本地的用户信息
  getUserInfo: () => {
    return store.get(userKey);
  },

  //删除本地存储的用户信息
  delUserInfo: () => {
    // console.log('storage remove userInfo');
    store.remove(userKey);
  },
};
