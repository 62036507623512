import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Select,
  InputNumber,
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import { listApi, saveApi, deleteApi } from "../../api/shipping-line";
import validator from "validator";

export default function ShippingLinePage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      name: searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    setShowAddModal(false);
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    await saveApi(values);
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "SCAC #", dataIndex: "scac", key: "scac" },
    { title: "Free Days", dataIndex: "freeDays", key: "freeDays" },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {remark}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={(e) => {
              e.preventDefault();
              editRef.current = record;
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the SL"
            description="Are you sure to delete this SL?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) => setSearchForm(e.target.value)}
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        extra={
          <Button
            onClick={async () => {
              editRef.current = {};
              setAddLoading(true);
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add SL
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items`, total }}
        />
      </Card>
      <Modal
        title="Edit Shipping Line"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{ ...editRef.current }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="SCAC #"
            name="scac"
            rules={[
              {
                required: true,
                message: "Please input the SCAC Code.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Free Days"
            name="freeDays"
            rules={[
              {
                required: true,
                message: "Please input free days.",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Remark" name="remark">
            <Input.TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
