import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Select,
  Row,
  Col,
  Divider,
  InputNumber,
  Switch,
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { listApi, saveApi, deleteApi } from "../../api/loads/status";
import { SketchPicker } from "react-color";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function LoadStatusConfigPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState({ name: "name" });
  const [bgColor, setBgColor] = useState("#ffffff");
  const [fontColor, setFontColor] = useState("#000000");

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});
  const [addForm] = Form.useForm(); // 获取表单实例的引用

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      ...{
        [searchForm.name]: searchForm.value,
      },
    });
    let myArray = result.list;
    // console.log("myArray,", myArray);
    if (Array.isArray(myArray) && myArray.length > 0) {
      // 使用比较函数对数组进行按index升序排序
      myArray.sort(function (a, b) {
        // console.log(a, b);
        return a.statusId - b.statusId;
      });
    }
    setDataList(myArray);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    addForm.setFieldsValue(null);
    setShowAddModal(false);
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    await saveApi({
      id: values["id"],
      name: values["name"],
      statusId: values["statusId"],
      bgColor,
      fontColor,
    });
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    {
      title: "Index",
      dataIndex: "statusId",
      key: "statusId",
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <div style={{}}>
            <span
              style={{
                color: record["fontColor"],
                backgroundColor: record["bgColor"],
                padding: "2px 5px",
              }}
            >
              {record["name"]}
            </span>
          </div>
        );
      },
    },
    {
      title: "INIT Status #",
      dataIndex: "isInit",
      key: "isInit",
      width: "120px",
      align: "center",
      render: (_, record) => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record["isInit"] === 1}
            onChange={async (checked) => {
              await saveApi({
                id: record["id"],
                isInit: checked ? 1 : 0,
              });
              getListData();
            }}
          />
        );
      },
    },
    {
      title: "Hold Status #",
      dataIndex: "isHoldStatus",
      key: "isHoldStatus",
      width: "120px",
      align: "center",
      render: (_, record) => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record["isHoldStatus"] === 1}
            onChange={async (checked) => {
              await saveApi({
                id: record["id"],
                isHoldStatus: checked ? 1 : 0,
              });
              getListData();
            }}
          />
        );
      },
    },
    {
      title: "Available Status #",
      dataIndex: "isAvailableStatus",
      key: "isAvailableStatus",
      width: "120px",
      align: "center",
      render: (_, record) => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record["isAvailableStatus"] === 1}
            onChange={async (checked) => {
              await saveApi({
                id: record["id"],
                isAvailableStatus: checked ? 1 : 0,
              });
              getListData();
            }}
          />
        );
      },
    },
    {
      title: "SCHEDULED #",
      dataIndex: "isScheduled",
      key: "isScheduled",
      width: "120px",
      align: "center",
      render: (_, record) => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record["isScheduled"] === 1}
            onChange={async (checked) => {
              await saveApi({
                id: record["id"],
                isScheduled: checked ? 1 : 0,
              });
              getListData();
            }}
          />
        );
      },
    },
    {
      title: "Empty Status #",
      dataIndex: "isEmptyStatus",
      key: "isEmptyStatus",
      width: "120px",
      align: "center",
      render: (_, record) => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record["isEmptyStatus"] === 1}
            onChange={async (checked) => {
              await saveApi({
                id: record["id"],
                isEmptyStatus: checked ? 1 : 0,
              });
              getListData();
            }}
          />
        );
      },
    },
    // {
    //   title: "Background Color",
    //   dataIndex: "bgColor",
    //   key: "bgColor",
    //   render: (_, { bgColor, fontColor }) => {
    //     return (
    //       <div style={{ backgroundColor: bgColor, color: fontColor }}>
    //         {bgColor}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Font Color",
    //   dataIndex: "fontColor",
    //   key: "fontColor",
    //   render: (fontColor) => {
    //     return <div style={{ color: fontColor }}>{fontColor}</div>;
    //   },
    // },
    {
      title: "Action",
      key: "action",
      width: "220px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={async (e) => {
              e.preventDefault();
              editRef.current = record;
              addForm.setFieldsValue({ ...record });
              setBgColor(record["bgColor"] || "0xfff");
              setFontColor(record["fontColor"] || "0xfff");
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the status"
            description="Are you sure to delete this status?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => {
              e.preventDefault();
              confirmDelete(record["id"]);
            }}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Select
        placeholder="Search..."
        onChange={(v) => {
          setSearchForm({ ...searchForm, name: v });
        }}
        style={{ width: "110px" }}
        defaultValue="name"
      >
        <Select.Option value="name">Name</Select.Option>
      </Select>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) =>
          setSearchForm({ ...searchForm, value: e.target.value })
        }
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData();
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        extra={
          <Button
            onClick={async () => {
              editRef.current = {};
              addForm.setFieldsValue(null);
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Status
          </Button>
        }
      >
        <Table
          rowKey="name"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={false}
        />
      </Card>
      <Modal
        title="Edit Status"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          form={addForm}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 22 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          // initialValues={{ ...editRef.current }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Index"
            name="statusId"
            rules={[{ required: true, message: "Please input index." }]}
          >
            <InputNumber />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ backgroundColor: bgColor, color: fontColor }}>
                    Background Color
                  </div>
                }
                name="bgColor"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select background color.",
                //   },
                // ]}
              >
                <SketchPicker
                  color={bgColor}
                  onChange={(c) => {
                    // console.log("bgColor onChange, ", c);
                    addForm.setFieldValue({
                      bgColor: c.hex,
                    });
                    setBgColor(c.hex);
                    setAddLoading(false);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<div style={{ color: fontColor }}>Font Color</div>}
                name="fontColor"
                // rules={[
                //   { required: true, message: "Please select font color." },
                // ]}
              >
                <SketchPicker
                  color={fontColor}
                  onChange={(c) => {
                    // console.log("fontColor onChange, ", c);
                    addForm.setFieldValue({
                      fontColor: c.hex,
                    });
                    setFontColor(c.hex);
                    setAddLoading(false);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item wrapperCol={{ offset: 8, span: 24 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
