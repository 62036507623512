
  //格式化Invoice ID
 export const formatInvoiceId = (invoiceId) => {
    return "inv-" + invoiceId.toString().padStart(5, "0");
  };


//格式化金额数字
export const formatMoney = (number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(number);
};