import { Select, Tag } from "antd";
import React, { useState } from "react";
import { saveApi } from "../../api/loads";

export default function DoubleClickSelect({
  id,
  name,
  content,
  handleUpdateList,
  handleSave = null,
  options,
  width
}) {
  const [open, setOpen] = useState(false);

  const handleChange = async (value) => {
    console.log(`selected ${value}`);
    if(value.trim()=='') {
      value='   ';
     }
    if (handleSave) {
      //alert(name);
      await handleSave({ id, [name]: value });
    } else {
      await saveApi({ id, [name]: value });
    }
    
    handleUpdateList(value);
    setOpen(false);
  };
  const submit = async (e) => {
    let value = e.target.value.trim();
    // console.log("onBlur", value);
    handleChange(value);  
  }
  var minwidth = 120;
  if (width != null) {
    minwidth = width; 
  }

  return open ? (
    <Select
      defaultValue={content}
      style={{
        width: minwidth 
      }}
      showSearch
      onChange={handleChange}
      options={options}
      onKeyDown={async (e) => {
        if(e.key === 'Enter') {
          submit(e);
        }
      }}
      onBlur={(e)=>{
        const value = e.target.value;
        setOpen(false);
        if (value && value.length >= 3) {
          handleChange(value);
          setOpen(false);
        } else {
          setOpen(false);
        }
      }}
    />
  ) : (
    <div
      onDoubleClick={(e) => {
        setOpen(true);
      }}
      style={{
        cursor: "pointer",
        fontWeight: 500,
        color: content
          ? (name === "cSize" && content && !content.includes("40")) || (name === "onWheeled" && content && content.includes("Wheeled"))
            ? "red"
            : "black"
          : "white",
      }}
    >
      {content || "N/A"}
    </div>
  );
}
