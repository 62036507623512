import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Upload, Space, Card, Button, Row, Input, Popconfirm } from "antd";
import { BsCloudUpload } from "react-icons/bs";
import { saveApi } from "../../api/attachment";
import { uploadApi, delUploadApi, getFilesApi } from "../../api/loads";

const { Dragger } = Upload;

const WallContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 3px;
`;

const WallItem = styled.div`
  width: 220px;
  height: 220px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const WallFileName = styled.p`
  width: 98%;
  margin: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadDocuments = ({ record }) => {
  const [fileList, setFileList] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getListData();
  }, []);

  //获取附件列表
  const getListData = async () => {
    const res = (await getFilesApi(record["id"])) || [];
    setFileList(res);
  };

  //删除附件
  const confirmDelete = async (id) => {
    await delUploadApi(record["id"], id);
    getListData();
  };

  //拖拽文件上传
  const draggerProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    showUploadList: false,
    customRequest: async ({ file }) => {
      const res = await uploadApi(file, record["id"]);
      // console.log("file eeeee,", res, file);
      let newFileList = [...fileList, res];
      setFileList(newFileList);
    },
  };

  return (
    <>
      <Card
        title="DOCUMENT LIST"
        style={{ width: "calc(100vw - 120px)", padding: "0px 0px 100px 2px" }}
      >
        <WallContainer>
          {fileList.map((file) => (
            <WallItem key={file.id}>
              {editId !== file.id ? (
                <WallFileName>{file.name}</WallFileName>
              ) : (
                <Input
                  defaultValue={file.name}
                  style={{ width: "90%" }}
                  onBlur={async (e) => {
                    // console.log("input onBlur, ", e.target.value);
                    setEditId(null);
                    await saveApi({ id: file.id, name: e.target.value });
                    getListData();
                  }}
                />
              )}
              <Row style={{ position: "absolute", bottom: "0px" }}>
                <Space>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    Download
                  </a>
                  <Button
                    type="link"
                    style={{ color: "grey" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setEditId(file.id);
                    }}
                  >
                    Rename
                  </Button>
                </Space>
              </Row>
              <Popconfirm
                title="Delete the File"
                description="Are you sure to delete this file?"
                okText="Yes"
                cancelText="No"
                onConfirm={(e) => confirmDelete(file["id"])}
              >
                <Button
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "1px",
                    color: "gray",
                  }}
                  type="link"
                >
                  Delete
                </Button>
              </Popconfirm>
            </WallItem>
          ))}

          <div style={{ width: "260px", height: "220px", overflow: "hidden" }}>
            <Dragger {...draggerProps}>
              <Space direction="vertical">
                <BsCloudUpload size={40} />
                <div>Click or drag file to this area to upload</div>
              </Space>
            </Dragger>
          </div>
        </WallContainer>
      </Card>
    </>
  );
};

export default LoadDocuments;
