import { combineReducers } from "redux"
import auth from "./auth"
import headerReducer from "./header"

const allReducers = combineReducers({
  auth: auth,
  headerTitle: headerReducer,
})

export default allReducers
