import { Col, Row, Table, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { listApi, saveApi, deleteApi } from "../../api/loads/notes";
import moment from "moment";

const { TextArea } = Input;

export default function LoadNotes({ record }) {
  const [listData, setListData] = useState([]);
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    getListData();
  }, []);

  const getListData = async () => {
    const res = await listApi({ loadId: record["id"] });
    setListData(res);
  };

  //添加notes
  const handleAdd = async () => {
    if (!notes || notes.trim().length < 1) {
      return;
    }
    await saveApi({ loadId: record["id"], notes: notes.trim() });
    getListData();
  };

  const fixedColumns = [
    {
      title: "Date",
      // dataIndex: "createTime",
      fixed: true,
      width: 180,
      render: ({ createTime }) => {
        return moment.utc(createTime).local().format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "Description",
      dataIndex: "notes",
      render: (notes) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
            {notes}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: "70px",
      render: (record) => {
        return (
          <a
            href="#"
            onClick={async (e) => {
              e.preventDefault();
              await deleteApi(record["id"]);
              getListData();
            }}
          >
            delete
          </a>
        );
      },
    },
  ];

  return (
    <>
      <Row
        style={{
          width: "calc(100vw - 60px)",
          marginBottom: "30px",
          marginLeft: "-40px",
        }}
      >
        <Col span={14}>
          <Table
            rowKey={"id"}
            bordered
            columns={fixedColumns}
            dataSource={listData}
            pagination={false}
          />
        </Col>
        <Col span={10}>
          <div style={{ marginLeft: "15px", width: "60%" }}>
            <TextArea
              allowClear
              rows={8}
              onChange={(e) => {
                // console.log("e", e.target.value);
                setNotes(e.target.value);
              }}
            />
            <div style={{ height: "11px" }}></div>
            <Row justify={"end"}>
              <Button onClick={handleAdd}>Add</Button>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
