import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Input,
  Space,
  Row,
  DatePicker,
  Select,
  Col,
  Divider,
} from "antd";
import { etaListApi } from "../../api/load-analysis";
import { selectListApi } from "../../api/customer";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function LoadDateAnalysisPage() {
  const [loading, setLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [searchForm, setSearchForm] = useState({});
  //未来几周的数据
  const [weekDates, setWeekDates] = useState([]);

  const customerRef = useRef({});

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getCustomerSelectListData();
    await getListData();
  };
  //获取客户下拉选择数据
  const getCustomerSelectListData = async () => {
    const result = await selectListApi();
    // console.log("getSelectListData result,", result);
    setCustomerList(result);
    result.forEach((item) => {
      customerRef.current[item.id] = item.name;
    });
  };
  //获取数据
  const getListData = async () => {
    setLoading(true);
    //返回日期的最早时间和最晚时间
    const { beginTime, endTime, allDates } = getWeekDates();
    const result = await etaListApi({
      ...searchForm,
      beginTime,
      endTime,
    });
    // console.log("result,", result);
    // console.log("allDates", allDates);
    fillWeekDatas("etaCount", result["eta"], allDates);
    fillWeekDatas("pickupCount", result["pickup"], allDates);
    fillWeekDatas("returnCount", result["return"], allDates);
    fillWeekDatas("doCount", result["do"], allDates);
    // console.log("allDates", allDates);
    setWeekDates(allDates);
    setLoading(false);
  };

  // 将字符串日期转换为 Date 对象
  function parseDate(dateString) {
    return new Date(dateString + "T00:00:00");
  }
  // 检查日期是否在给定的开始和结束日期之间
  function isDateInRange(date, start, end) {
    return date >= start && date <= end;
  }
  //填充数据
  const fillWeekDatas = (countField, datas, res) => {
    //遍历服务端返回的数据，eta、pickup、return其中一个数组
    datas.forEach((item) => {
      const itemDate = parseDate(item["etaDate"]);

      //遍历 weekDates 数组
      res.forEach((segment) => {
        const startDate = parseDate(segment.start);
        const endDate = parseDate(segment.end);
        if (isDateInRange(itemDate, startDate, endDate)) {
          segment[countField] = (segment[countField] || 0) + item.count;
          // console.log('item["etaDate"]', item["etaDate"], segment);
          segment["days"][item["etaDate"]][countField] = item.count;
        }
      });
    });
  };

  //选择了时间范围
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setSearchForm({
        ...searchForm,
        beginTime: dateStrings[0],
        endTime: dateStrings[1],
      });
    } else {
      // console.log("Clear");
      setSearchForm({ ...searchForm, beginTime: null, endTime: null });
    }
  };

  const titleNode = (
    <Space size={10}>
      <Select
        placeholder="Customer..."
        style={{ width: "300px" }}
        showSearch
        allowClear
        onChange={(value) =>
          setSearchForm({
            ...searchForm,
            warehouseId: value,
          })
        }
        optionFilterProp="label"
        options={customerList.map((item) => ({
          label: item.name.split("-")[0].trim(),
          value: item.id,
        }))}
      />
      {/* 选择时间范围 */}
      <RangePicker onChange={onRangeChange} />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData();
        }}
      >
        Search
      </Button>
    </Space>
  );

  //判断是否是今天的日期
  function isToday(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();

    return (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    );
  }

  //有数据的span加粗字体
  const getBoldSpan = (value) => {
    return (
      <span
        style={{
          fontWeight: value > 0 ? "bold" : "normal",
        }}
      >
        {value}
      </span>
    );
  };

  //每周统计的DIV
  const getTotalDiv = (_item) => {
    return (
      <div
        style={{
          border: "0.1px solid #000",
          width: "100%",
          marginTop: "3px",
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row style={{ height: "30px", textAlign: "center", width: "100%" }}>
            <h3 style={{ width: "100%" }}>{"Total"}</h3>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <div style={{ paddingLeft: "15px", paddingBottom: "20px" }}>
            <Row>
              <Col span={8}>ETA</Col>
              <Col>{getBoldSpan(_item["etaCount"])}</Col>
            </Row>
            <Row>
              <Col span={8}>Pickup</Col>
              <Col>{getBoldSpan(_item["pickupCount"])}</Col>
            </Row>
            <Row>
              <Col span={8}>Return</Col>
              <Col>{getBoldSpan(_item["returnCount"])}</Col>
            </Row>
            <Row>
              <Col span={8}>Do</Col>
              <Col>{getBoldSpan(_item["doCount"])}</Col>
            </Row>
          </div>
        </Space>
      </div>
    );
  };

  // 获取
  const getDayDiv = (key, value) => {
    const dateString = key + "T00:00:00";
    const dateObject = new Date(dateString);
    // 获取星期几的文本形式
    const dayOfWeek = dateObject.toLocaleDateString("en-US", {
      weekday: "short",
    });
    // 获取日期的本地化字符串，以 "MM/DD/YYYY" 格式输出
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return (
      <div
        style={{
          border: "0.1px solid #000",
          width: "100%",
          marginTop: "3px",
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row style={{ height: "30px", textAlign: "center", width: "100%" }}>
            <h3 style={{ width: "100%" }}>
              {dayOfWeek}
              {" - "}
              <span style={{ color: isToday(dateString) ? "red" : "" }}>
                {formattedDate}
              </span>
            </h3>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <div style={{ paddingLeft: "15px", paddingBottom: "20px" }}>
            <Row>
              <Col span={8}>ETA</Col>
              <Col>{getBoldSpan(value["etaCount"])}</Col>
            </Row>
            <Row>
              <Col span={8}>Pickup</Col>
              <Col>{getBoldSpan(value["pickupCount"])}</Col>
            </Row>
            <Row>
              <Col span={8}>Return</Col>
              <Col>{getBoldSpan(value["returnCount"])}</Col>
            </Row>
            <Row>
              <Col span={8}>Do</Col>
              <Col>{getBoldSpan(value["doCount"])}</Col>
            </Row>
          </div>
        </Space>
      </div>
    );
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //获取本周，到未来3周的最早日期和最晚日期
  const getEarliseLatesWeekDates = () => {
    if (searchForm["beginTime"] && searchForm["endTime"]) {
      return {
        beginTime: searchForm["beginTime"],
        endTime: searchForm["endTime"],
      };
    }
    //从哪天开始计算的
    const today = new Date();
    const currentDay = today.getDay(); // 获取今天是星期几（0-6，0表示星期日）
    // console.log("today", today, "currentDay", currentDay);

    // 计算今天距离本周第一天的偏移量
    const offset = currentDay > 0 ? 0 - currentDay : 0;
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - 7  + offset);

    // 获取未来4周的所有日期范围
    const lastDayOfFourthWeek = new Date(firstDayOfWeek);
    lastDayOfFourthWeek.setDate(firstDayOfWeek.getDate() + 27); // 计算第四周的最后一天

    const earliestDate = formatDate(firstDayOfWeek);
    const latestDate = formatDate(lastDayOfFourthWeek);

    // console.log(`beginTime: ${earliestDate}, endTime: ${latestDate} `);
    return { beginTime: earliestDate, endTime: latestDate };
  };

  //获取日历面板的时间范围
  const getWeekDates = () => {
    //获取计算的开始日期和结束日期
    let res = getEarliseLatesWeekDates();
    const startDate = new Date(res["beginTime"] + "T00:00:00");
    const endDate = new Date(res["endTime"] + "T00:00:00");
    // console.log("startDate", startDate, res["beginTime"]);

    const allDates = [];
    let currentDay = startDate;

    while (currentDay <= endDate) {
      const weekStart = new Date(currentDay);
      const offset = weekStart.getDay() > 0 ? 1 - weekStart.getDay() : 0;
      weekStart.setDate(currentDay.getDate() + offset);
      // console.log("weekStart", weekStart, offset);

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);

      // console.log("weekEnd", weekStart);
      const _data = {
        start: formatDate(weekStart),
        end: formatDate(weekEnd),
        days: {},
        etaCount: 0, // 用于存储每个时间段的 eta 计数
        pickupCount: 0, // 用于存储每个时间段的 pickup 计数
        returnCount: 0, // 用于存储每个时间段的 return 计数
        doCount: 0, // 用于存储每个时间段的 do 计数
      };
      const _weekDates = {};
      for (let i = 0; i < 7; i++) {
        _weekDates[formatDate(weekStart)] = {
          etaCount: 0, // 用于存储每天的 eta 计数
          pickupCount: 0, // 用于存储每天的 pickup 计数
          returnCount: 0, // 用于存储每天的 return 计数
          doCount: 0, // 用于存储每个时间段的 do 计数
        };
        weekStart.setDate(weekStart.getDate() + 1);
      }
      _data["days"] = _weekDates;
      allDates.push(_data);
      // console.log("allDates", allDates);
      currentDay.setDate(currentDay.getDate() + 7); // 移到下一周的同一天
    }

    // setWeekDates(allDates);
    // console.log("allDates", allDates);
    return {
      beginTime: formatDate(new Date(allDates[0]["start"])),
      endTime: formatDate(new Date(allDates[allDates.length - 1]["end"])),
      allDates,
    };
  };

  return (
    <>
      <Card title={titleNode} loading={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "95%",
            }}
          >
            <Row style={{ height: "10px" }}></Row>
            {weekDates.length > 0 &&
              weekDates.map((item) => {
                // console.log("item", item);
                return (
                  <>
                    <Row>
                      <Col span={3}>{getTotalDiv(item)}</Col>
                      {Object.entries(item.days)?.map(([key, value]) => {
                        return <Col span={3}>{getDayDiv(key, value)}</Col>;
                      })}
                    </Row>
                    <Divider />
                  </>
                );
              })}
            <Row style={{ height: "150px" }}></Row>
          </div>
        </div>
      </Card>
    </>
  );
}
