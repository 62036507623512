import React from "react";
// import { history } from "../../../history";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function Error404() {
  const navigate = useNavigate();
  // console.log("404 navigate", navigate);
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            onClick={() => navigate("/", { replace: true })}
            type="primary"
          >
            Go Home
          </Button>
        }
      />
    </div>
  );
}
