import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Divider,
  Checkbox,
  message,
  Col,
  Row,
  Tooltip,
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  listApi,
  saveApi,
  deleteApi,
  initPasswordApi,
  updatePermissionApi,
} from "../../api/system-user";
import validator from "validator";
import { nanoid } from "nanoid";

const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  "Container#",
  "SL #",
  "Status",
  "Notes #",
  "Chassis#",
  "Size",
  "Weight (lb)",
  "LFD",
  "P-Term..",
  "APPT/PIN #",
  "P-Date#",
  "D-Date#",
  "R-LFD",
  "Call-Empty",
  "R-Term..",
  "R-Date#",
  "Notes",
  "Delivery Address",
  "Load Type",
  "ETA",
  "PP",
  "Notes Charge",
  "Action",
  "Customer Name", // 客户名称
];

export default function SystemUserListPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState({ name: "name" });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const dateRef = useRef({});

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;

  useEffect(() => {
    getListData();
  }, []);

  const checkBoxOnChange = (list) => {
    // console.log("checkBoxOnChange", list);
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      ...{
        [searchForm.name]: searchForm.value,
      },
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    //保存数据
    result.list?.map((item) => {
      dateRef.current[item["id"]] = item;
    });
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  //初始化密码
  const handleResetPassword = async (id) => {
    await initPasswordApi({ id });
    getListData();
  };

  //保存客户信息
  const handleSave = async ({ id, username, contactInfo, password }) => {
    // console.log("handleSave,", values);
    if (!username || !validator.isEmail(username)) {
      message.error("Username Error");
      return;
    }
    await saveApi({ id, username, contactInfo, password });
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  //更新用户权限
  const handleUpdatePermission = async (id) => {
    // console.log("handleUpdatePermission", JSON.stringify(checkedList));
    await updatePermissionApi({ id, columnJson: JSON.stringify(checkedList) });
    message.success("Success");
    getListData();
  };

  //添加或者删除元素
  function addOrRemoveFromArray(arr, value) {
    // 修改成只展开一行
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1); // 如果存在，则删除
    } else {
      arr = [value];
    }
    // 展开行的ID
    for (let index in arr) {
      let record = dateRef.current[arr[index]];
      // console.log("addOrRemoveFromArray", record["columnJson"]);
      setCheckedList(
        record["columnJson"] ? JSON.parse(record["columnJson"]) : []
      );
    }
    return arr;
  }

  //数组分组
  function groupArrayElements(array, groupSize) {
    let result = [];
    for (let i = 0; i < array.length; i += groupSize) {
      let group = array.slice(i, i + groupSize);
      result.push(group);
    }
    return result;
  }

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username #",
      dataIndex: "username",
      key: "username",
      render: (_, record) => {
        return (
          <div
            onClick={(e) => {
              e.preventDefault();
              setExpandedRowKeys([
                ...addOrRemoveFromArray([...expandedRowKeys], record["id"]),
              ]);
            }}
            style={{ cursor: "pointer" }}
          >
            {record["username"]}
          </div>
        );
      },
    },
    {
      title: "Permission",
      dataIndex: "columnJson",
      key: "columnJson",
      render: (columnJson) => {
        // console.log("columnJson", columnJson);
        return (
          <Tooltip
            title={columnJson && columnJson.length > 3 ? columnJson : null}
          >
            <div
              style={{
                width: "360px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {columnJson && columnJson.length > 3 ? columnJson : "ALL"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Remark",
      dataIndex: "contactInfo",
      key: "contactInfo",
    },
    {
      title: "Action",
      key: "action",
      width: "180px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <Popconfirm
            title="Reset the password"
            description="Are you sure to reset this password?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => handleResetPassword(record["id"])}
          >
            <a style={{ color: "#999" }}>reset</a>
          </Popconfirm>
          <Popconfirm
            title="Delete the account"
            description="Are you sure to delete this account?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) =>
          setSearchForm({ ...searchForm, value: e.target.value })
        }
        allowClear
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        extra={
          <Button
            onClick={() => {
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Account
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items` }}
          expandable={{
            // columnWidth: 0,
            showExpandColumn: false,
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: (record) => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "",
                  }}
                >
                  <Space>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      Check all
                    </Checkbox>
                    <Button
                      style={{ marginLeft: "20px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleUpdatePermission(record["id"]);
                      }}
                    >
                      Save Change
                    </Button>
                  </Space>
                  <Divider />
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    value={checkedList}
                    onChange={checkBoxOnChange}
                  >
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {groupArrayElements(plainOptions, 6).map((item) => {
                        // console.log("item", item);
                        return (
                          <Row
                            key={nanoid()}
                            style={{ width: "100%", margin: "10px" }}
                          >
                            {item.map((value) => (
                              <Col
                                key={nanoid()}
                                style={{ marginLeft: "10px" }}
                                span={3}
                              >
                                <Checkbox value={value}>{value}</Checkbox>
                              </Col>
                            ))}
                          </Row>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </div>
              );
            },
          }}
        />
      </Card>
      <Modal
        title="Edit Account"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{}}
          onChange={(e) => setAddLoading(false)}
          style={{ paddingTop: "25px" }}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="User Name"
            name="username"
            rules={[{ required: true, message: "Please input username." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input password." }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label="Remark" name="contactInfo">
            <TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
