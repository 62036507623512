import { Alert, Spin } from "antd";
import React from "react";

export default function MySpin(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minHeight: props["minHeight"] || "600px",
      }}
    >
      <Spin>{props.msg ? <Alert type="info" message={props.msg} /> : ""}</Spin>
    </div>
  );
}
