import {
  AUTH_LOGIN_OK,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_OK,
  INIT_USERINFO_FROM_LOCAL,
  AUTH_UNAUTHORIZED,
  AUTH_USERINFO_UPDATE,
} from "../../action-types";

const initialState = {
  //登录以后拿到的用户信息
  userInfo: null,
  //登录错误提示
  loginErrorMessage: "",
  //是否显示登录页面
  showLoginModel: false,
  //等待刷新token信息
  waitForUserInfo: true,
};

const authReducer = (state = initialState, action) => {
  // console.log('authReducer', action.type);
  switch (action.type) {
    case AUTH_LOGIN_OK:
    case AUTH_USERINFO_UPDATE:
      //拿获取到的值覆盖原来的值
      return {
        ...state,
        userInfo: action.payload,
        loginErrorMessage: "",
        showLoginModel: false,
        waitForUserInfo: false,
      };
    case AUTH_LOGIN_ERROR:
      //重新赋值错误信息
      return { ...state, userInfo: {}, loginErrorMessage: action.payload };
    case AUTH_LOGOUT_OK:
    case AUTH_UNAUTHORIZED:
      //退出登录，清除本地存储的用户信息
      return { ...state, userInfo: null, waitForUserInfo: false };
    case INIT_USERINFO_FROM_LOCAL:
      //从本地存储的用户信息初始化到redux
      return { ...state, userInfo: action.payload };
    default:
      // console.log('switch default');
      return state;
  }
};

export default authReducer;
