import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  Table,
  Input,
  Form,
  Space,
  Popconfirm,
  Tag,
  Checkbox,
  message,
  Select,
} from "antd";
import { PAGE_SIZE } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import {
  listApi,
  saveApi,
  deleteApi,
  updateUsernameApi,
} from "../../api/driver";
import validator from "validator";

export default function DriverPage() {
  const [addLoading, setAddLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUsernameAddModal, setShowUsernameAddModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchForm, setSearchForm] = useState(null);

  const sizeRef = useRef(PAGE_SIZE);
  const pageRef = useRef(1);
  const editRef = useRef({});
  const editUsernameRef = useRef({});

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getListData();
  };

  //获取数据
  const getListData = async (
    page = pageRef.current,
    size = sizeRef.current
  ) => {
    setLoading(true);
    const result = await listApi({
      page,
      size,
      name: searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result.list);
    setTotal(result.total);
    pageRef.current = page;
    sizeRef.current = size;
    setLoading(false);
  };

  //关闭编辑客户对话框
  const handleCloseModal = () => {
    editRef.current = {};
    setShowAddModal(false);
  };

  //关闭编辑客户对话框
  const handleCloseUsernameModal = () => {
    editUsernameRef.current = {};
    setShowUsernameAddModal(false);
  };

  //保存用户名和密码信息
  const handleSaveUsername = async ({ id, username, password, password2 }) => {
    // console.log("handleSaveUsername,", username, password, password2);
    if (!username || !validator.isEmail(username)) {
      message.error("Username Error");
      return;
    }
    if (password !== password2) {
      message.error("Password Error");
      return;
    }
    await updateUsernameApi({ id, username, password });
    handleCloseUsernameModal();
    getListData();
  };

  //保存客户信息
  const handleSave = async (values) => {
    // console.log("handleSave,", values);
    await saveApi(values);
    handleCloseModal();
    message.success("Success");
    getListData();
  };

  //确定删除客户
  const confirmDelete = async (id) => {
    await deleteApi(id);
    message.success("Success");
    getListData();
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "DL #", dataIndex: "dlNo", key: "dlNo" },
    { title: "LP #", dataIndex: "lpNo", key: "lpNo" },
    { title: "Phone #", dataIndex: "phone", key: "phone" },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (companyName) => {
        return <div>{companyName}</div>;
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, record) => {
        return (
          <Space>
            <div>{record["username"]}</div>
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault();
                editUsernameRef.current = {
                  username: record["username"],
                  id: record["id"],
                  password: "",
                  password2: "",
                };
                setShowUsernameAddModal(true);
              }}
            >
              edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (_, record) => (
        <Space style={{ width: "120px" }} size="middle">
          <a
            onClick={async (e) => {
              editRef.current = record;
              setShowAddModal(true);
            }}
          >
            edit
          </a>
          <Popconfirm
            title="Delete the customer"
            description="Are you sure to delete this customer?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(record["id"])}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const titleNode = (
    <>
      <Input
        placeholder="Search..."
        style={{ width: 600, margin: "0 15px" }}
        onChange={(e) => setSearchForm(e.target.value.trim())}
        allowClear
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
    </>
  );

  return (
    <>
      <Card
        title={titleNode}
        loading={loading}
        extra={
          <Button
            onClick={async () => {
              editRef.current = {};
              setShowAddModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Driver
          </Button>
        }
      >
        <Table
          rowKey="id"
          bordered
          columns={columns}
          dataSource={dataList}
          pagination={{ showTotal: (total) => `Total ${total} items` }}
        />
      </Card>
      <Modal
        title="Edit Driver"
        open={showAddModal}
        onCancel={(e) => handleCloseModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSave}
          autoComplete="off"
          preserve={false}
          initialValues={{ ...editRef.current }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Driver License #"
            name="dlNo"
            rules={[
              {
                required: true,
                message: "Please input driver license number.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="License Plate #"
            name="lpNo"
            rules={[
              {
                required: true,
                message: "Please input license plate number.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Please input phone number." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Company Name" name="companyName">
            <Input />
          </Form.Item>
          {/* <Form.Item label="Address" name="address">
            <Input />
          </Form.Item> */}

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      {/* 添加司机用户名和密码 */}
      <Modal
        title="Edit Driver Username"
        open={showUsernameAddModal}
        onCancel={(e) => handleCloseUsernameModal()}
        style={{ top: 100 }}
        width={800}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleSaveUsername}
          autoComplete="off"
          preserve={false}
          initialValues={{ ...editUsernameRef.current }}
          onChange={(e) => setAddLoading(false)}
        >
          <Form.Item label="ID" name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input username." }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <Input.Password />
          </Form.Item>

          <Form.Item label="Confirm Password" name="password2">
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Space size={60}>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  handleCloseUsernameModal();
                }}
              >
                Cancel
              </Button>
              <Button type="primary" loading={addLoading} htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
