import { Checkbox, Col, Divider, Form, Input, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import VerticalLayout from "../../components/vertical-layout";
import ItemForm from "../../components/item-form/item-input";
import styled from "styled-components";
import { saveApi, getApi } from "../../api/loads";
import ItemSelectForm from "../../components/item-form/item-select";
import { selectListApi as warehouseListApi } from "../../api/warehouse";
import ItemTextAreaForm from "../../components/item-form/item-textarea";
import { SSL } from "../../config";
const StyledH3 = styled.h3`
  height: 30px;
  line-height: 30px;
  margin: 0px;
  padding: 0px;
`;

export default function LoadBaseInfo({
  loadId,
  updateList,
  customers,
  consignee,
  warehouseId,
  deliveryWhId,
  refresh
}) {
  const [loadInfo, setLoadInfo] = useState(null);
  const [deliverId, setDeliverId] = useState(deliveryWhId);
  const [warehouseList, setWarehouseList] = useState(consignee);
  const [holdOptions, _] = useState([
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "NO",
      value: 0,
    },
  ]);

  useEffect(() => {
    // console.log("LoadBaseInfo consignee", consignee.length === 0);
    getLoadData();
  }, [refresh]);

  //更新仓库列表
  const updateWarehouseListData = async (userId) => {
    const res = await warehouseListApi({ userId });
    setWarehouseList(
      res.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  //获取Load的最新信息
  const getLoadData = async () => {
    const res = await getApi(loadId);
    console.log("LoadBaseInfo res", res);
    setLoadInfo(res);
    if (consignee.length === 0) {
      updateWarehouseListData(res["warehouseId"]);
    }
  };

  //更新指定数据
  const handleSave = async (name, value) => {
    console.log(name, value);
    await saveApi({ id: loadInfo.id, [name]: value });
    setLoadInfo({ ...loadInfo, [name]: value });
    updateList();
  };

  var shippingLine = "";
  if(loadInfo !=null) {
    shippingLine = loadInfo.shippingLine;
    if(shippingLine == null) {
      shippingLine = loadInfo.mbl.slice(0,4);
    }
  } 

 
  return (
    <div style={{ width: "calc(100vw - 100px)" }}>
      {loadInfo && (
        <Row gutter={10} justify="space-around">
          <Col
            span={11}
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            <Space direction="vertical" size={0} style={{ width: "100%" }}>
              <h3>Load Info</h3>
              {/* <ItemForm label={"Customer"} /> */}
              <ItemSelectForm
                label={"Customer"}
                options={customers}
                value={warehouseId}
                handleSave={async (value) => {
                  //保存修改客户ID
                  await saveApi({
                    id: loadId,
                    warehouseId: value,
                    deliveryWhId: 0,
                  });
                  setDeliverId(0);
                  //更新仓库列表
                  await updateWarehouseListData(value);
                }}
              />
              <ItemSelectForm
                label={"Consignee"}
                options={warehouseList}
                value={deliverId}
                handleSave={(value) => {
                  handleSave("deliveryWhId", value);
                }}
              />

              <Row>
                <VerticalLayout
                  items={[
                    <h3>Special</h3>,
                    <Row justify={"space-evenly"}>
                      <Col span={6}>
                        <Checkbox
                          checked={loadInfo.hazmat ? true : false}
                          onChange={(e) => {
                            // console.log(e.target.checked);
                            const value = e.target.checked ? 1 : 0;
                            handleSave("hazmat", value);
                          }}
                        >
                          Hazmat
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          checked={loadInfo.overWeight ? true : false}
                          onChange={(e) => {
                            // console.log(e.target.checked);
                            const value = e.target.checked ? 1 : 0;
                            handleSave("overWeight", value);
                          }}
                        >
                          Overweight
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          checked={loadInfo.prepaidChassis ? true : false}
                          onChange={(e) => {
                            // console.log(e.target.checked);
                            const value = e.target.checked ? 1 : 0;
                            handleSave("prepaidChassis", value);
                          }}
                        >
                          Pre-Paid Chassis
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox
                          checked={loadInfo.onWheeled ? true : false}
                          onChange={(e) => {
                            // console.log(e.target.checked);
                            const value = e.target.checked ? 1 : 0;
                            handleSave("onWheeled", value);
                          }}
                        >
                          On_Wheel
                        </Checkbox>
                      </Col>
                      {/* <Col span={6}>
                      <Checkbox onChange={(e) => console.log(e.target.value)}>
                        Exam Station
                      </Checkbox>
                    </Col> */}
                      {/* <Col span={6}>
                      <Checkbox onChange={(e) => console.log(e.target.value)}>
                        Hot
                      </Checkbox>
                    </Col> */}
                    </Row>,
                  ]}
                />
              </Row>

              {/* <StyledH3>Port</StyledH3>
              <Space>
                <ItemForm label={"Port Name"} />
                <ItemForm label={"Pick Up From"} />
                <ItemForm label={"Pick Up To"} />
              </Space> */}
              {/* <h4>Consignee</h4> */}
              {/* <ItemForm label={"Consignee"} /> */}
              {/* <h3>Container Return</h3>
              <Space>
                <ItemForm label={"Container Return"} />
                <ItemForm label={"Return From"} />
                <ItemForm label={"Return To"} />
              </Space>
              <h3>Chassis Locations</h3>
              <Row gutter={20}>
                <Col span={12}>
                  <ItemForm label={"Chassis Pickup"} />
                </Col>
                <Col span={12}>
                  <ItemForm label={"Chassis Termination"} />
                </Col>
              </Row> */}
              <h3>Holds</h3>
              <Row gutter={20}>
                <Col span={6}>
                  {/* <ItemSelectForm label={"Freight Hold"} /> */}
                  <ItemSelectForm
                    label={"Freight Hold"}
                    options={holdOptions}
                    value={loadInfo["lineHold"]}
                    handleSave={(value) => {
                      handleSave("lineHold", value);
                    }}
                  />
                </Col>
                <Col span={6}>
                  {/* <ItemForm label={"Custom Hold"} /> */}
                  <ItemSelectForm
                    label={"Custom Hold"}
                    options={holdOptions}
                    value={loadInfo["customsHold"]}
                    handleSave={(value) => {
                      handleSave("customsHold", value);
                    }}
                  />
                </Col>
                <Col span={6}>
                  {/* <ItemForm label={"Terminal Hold"} /> */}
                  <ItemSelectForm
                    label={"PierPass Hold"}
                    options={holdOptions}
                    value={loadInfo["pierpassHold"]}
                    handleSave={(value) => {
                      handleSave("pierpassHold", value);
                    }}
                  />
                </Col>
                <Col span={6}>
                  {/* <ItemForm label={"Terminal Hold"} /> */}
                  <ItemSelectForm
                    label={"CTF Hold"}
                    options={holdOptions}
                    value={loadInfo["ctfHold"]}
                    handleSave={(value) => {
                      handleSave("ctfHold", value);
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                {/* <Col span={8}>
                  <ItemForm label={"PierPass Hold"} />
                </Col> */}
                {/* <Col span={8}>
                  <ItemForm label={"CTF Hold"} />
                </Col> */}
                <Col span={24}>
                  <ItemTextAreaForm
                    rows={10}
                    label={"Other Hold"}
                    keyName={"otherHolds"}
                    value={loadInfo.otherHolds}
                    handleSave={(key, value) => handleSave(key, value)}
                  />
                </Col>
              </Row>
            </Space>
          </Col>
          <Col span={12} style={{ backgroundColor: "" }}>
            <Space direction="vertical">
              {/* <Row>
                <VerticalLayout
                  items={[<h3>Ref #</h3>, <Input.TextArea rows={4} />]}
                />
              </Row> */}
              <VerticalLayout
                items={[
                  <h3>Equipment</h3>,
                  <Row>
                    <Space>
                      <ItemForm
                        label={"Container #"}
                        keyName="container"
                        value={loadInfo.container}
                        handleSave={(key, value) => handleSave(key, value)}
                      />
                      <ItemForm label={"Size"} value={loadInfo.csize} handleSave={(value) => handleSave("size",value)}/>
                      {/* <ItemForm label={"Type"} /> */}
                      {/* <ItemForm
                        label={"Owner"}
                        value={
                          loadInfo.mbl &&
                          loadInfo.mbl.substring(0, 4).toUpperCase()
                        }
                      /> */}
                      <ItemForm
                        label={"Weight LBS"}
                        keyName="weight"
                        value={loadInfo.weight}
                        handleSave={(value) => handleSave("weight",value)}
                      />
                    </Space>
                  </Row>,
                  <Row>
                    <Space>
                      <ItemForm
                        label={"Chassis #"}
                        keyName="chassisNo"
                        value={loadInfo.chassisNo}
                        handleSave={(value) => handleSave("chassisNo", value)}
                      />
                      {/* <ItemForm label={"Size"} value={loadInfo.csize} /> */}
                      {/* <ItemForm label={"Type"} /> */}
                      <ItemSelectForm
                        label={"Shipping line"}
                        options={SSL.map((item) => ({ value: item, label: item }))}
                        value={shippingLine}
                        handleSave={(value) => {
                          handleSave("shippingLine", value);
                        }}
                  />
                    </Space>
                  </Row>,
                  // <Row>
                  //   <Space>
                  //     <ItemForm label={"Genset#"} />
                  //     <ItemForm label={"Temperature"} />
                  //     <ItemForm label={"Route"} />
                  //     <ItemForm label={"SCAC"} />
                  //   </Space>
                  // </Row>,
                ]}
              />
              <VerticalLayout
                items={[
                  <h3>Reference Numbers</h3>,
                  <Row gutter={20}>
                    <Col span={12}>
                      <ItemForm
                        label={"Master Bill Lading"}
                        keyName={"mbl"}
                        value={loadInfo.mbl}
                        handleSave={(key, value) => handleSave(key, value)}
                      />
                    </Col>
                    <Col span={12}>
                      <ItemForm label={"Seal #"} />
                    </Col>
                  </Row>,
                  <Row gutter={20}>
                    {/* <Col span={12}>
                    <ItemForm label={"House Bill of Lading"} />
                  </Col> */}
                    {/* <Col span={12}>
                      <ItemForm label={"Reference #"} />
                    </Col> */}
                  </Row>,
                  <Row gutter={20}>
                    <Col span={12}>
                      <ItemForm label={"Vessel Name"} />
                    </Col>
                    <Col span={12}>
                      <ItemForm label={"Voyage"} />
                    </Col>
                  </Row>,
                  // <Row gutter={20}>
                  //   <Col span={12}>
                  //     <ItemForm label={"Purchase Order #"} />
                  //   </Col>
                  //   <Col span={12}>
                  //     <ItemForm label={"Shipment #"} />
                  //   </Col>
                  // </Row>,
                  // <Row gutter={20}>
                  //   <Col span={12}>
                  //     <ItemForm
                  //       label={"Pick Up #"}
                  //       value={loadInfo.pickupTerminal}
                  //     />
                  //   </Col>
                  //   <Col span={12}>
                  //     <ItemForm
                  //       label={"Appointment #"}
                  //       value={loadInfo.pickupAptDate}
                  //     />
                  //   </Col>
                  // </Row>,
                  // <Row gutter={20}>
                  //   <Col span={12}>
                  //     <ItemForm
                  //       label={"Return #"}
                  //       value={loadInfo.returnTerminal}
                  //     />
                  //   </Col>
                  //   <Col span={12}>
                  //     <ItemForm
                  //       label={"Reservation #"}
                  //       value={loadInfo.returnAptDate}
                  //     />
                  //   </Col>
                  // </Row>,
                ]}
              />
              <VerticalLayout
                items={[
                  <h3>Dates</h3>,
                  <Space style={{ display: "flex", flexWrap: "wrap" }}>
                    <ItemForm label={"Departure Date"} />
                    <ItemForm label={"Vessel ETA"} />
                    <ItemForm label={"Discharged Date"} />
                    <ItemForm label={"PickUp LFD"} />
                    <ItemForm label={"PickUp Date"} />
                    <ItemForm label={"Empty Date"} />
                    <ItemForm label={"Return LFD"} />
                    <ItemForm label={"Return Date"} />
                  </Space>,
                ]}
              />
              {/* <VerticalLayout
                items={[
                  <h3>Dates</h3>,
                  <Space style={{ display: "flex", flexWrap: "wrap" }}>
                    <ItemForm label={"Vessel ETA"} />
                    <ItemForm label={"Lase Free Day"} />
                    <ItemForm label={"Discharged Date"} />
                    <ItemForm label={"Outgate Date"} />
                    <ItemForm label={"Empty Date"} />
                    <ItemForm label={"Return Date"} />
                    <ItemForm label={"Return Date"} />
                  </Space>,
                ]}
              /> */}
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
}
