import React, { useState, useRef, useEffect } from "react";
import { Card, Button, Input, Space, Row, DatePicker, Select } from "antd";
import { listApi as listSLApi } from "../../api/shipping-line";
import { TERMINALS } from "../../config";
import { listApi } from "../../api/load-analysis";
import { selectListApi } from "../../api/customer";
import { selectListApi as statusListApi } from "../../api/loads/status";
import { Column, Pie } from "@ant-design/plots";
import moment from "moment";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function LoadAnalysisPage() {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [searchForm, setSearchForm] = useState({});
  const [loadStatusList, setLoadStatusList] = useState([]);
  const [colConfig, setColConfig] = useState(null);
  const [pieConfig, setPieConfig] = useState(null);

  const customerRef = useRef({});
  const shippingLineRef = useRef([]);

  useEffect(() => {
    getInitData();
  }, []);

  //获取初始化数据
  const getInitData = async () => {
    await getCustomerSelectListData();
    await getLoadStatusListData();
    await getSLData();
    await getListData();
  };
  //获取船司列表
  const getSLData = async () => {
    const res = await listSLApi({ size: 10000 });
    // console.log("SL", res);
    const sl = [];
    res.list.forEach((item) => {
      sl.push(item["scac"]);
    });
    shippingLineRef.current = sl;
  };
  //Load状态下拉列表
  const getLoadStatusListData = async () => {
    const result = await statusListApi();
    // console.log("getLoadStatusListData, ", result);
    setLoadStatusList(result);
  };
  //获取客户下来选择数据
  const getCustomerSelectListData = async () => {
    const result = await selectListApi();
    // console.log("getSelectListData result,", result);
    setCustomerList(result);
    result.forEach((item) => {
      customerRef.current[item.id] = item.name;
    });
  };
  //获取数据
  const getListData = async () => {
    setLoading(true);
    const result = await listApi({
      ...searchForm,
    });
    // console.log("searchForm,", searchForm);
    setDataList(result);
    setLoading(false);
    //生成柱状图
    getSameDayData(result);
    //生成饼状图
    getPieSLData(result);
  };

  //生成柱状图配置
  const getSameDayData = (data) => {
    // 创建一个对象来存储每天的container数量
    const containerCountsByDate = {};
    // 遍历数组中的每个对象
    data.forEach((item) => {
      // 获取当前对象的 pickupAptDate
      const pickupAptDate = item.pickupAptDate;

      // 如果 pickupAptDate 不为 null
      if (pickupAptDate !== null && item["status"] !== 1100) {
        // 将 pickupAptDate 格式化为日期字符串（只包含日期部分，不包含时间）
        const yearStr = item["createTime"].split("-")[0]; // 提取日期部分
        const dayStr = pickupAptDate.split(" ")[0]; // 提取日期部分
        const dateStr = dayStr + "/" + yearStr; // 提取日期部分

        // 检查 containerCountsByDate 中是否已存在该日期的记录
        if (containerCountsByDate[dateStr] === undefined) {
          // 如果不存在，创建该日期的记录，并初始化 container 数量为 1
          containerCountsByDate[dateStr] = 1;
        } else {
          // 如果已存在该日期的记录，将 container 数量加 1
          containerCountsByDate[dateStr] += 1;
        }
      }
    });
    // 将 containerCountsByDate 转换成 { type: 日期, sales: 数量 } 格式的数组
    const transformedData = Object.entries(containerCountsByDate).map(
      ([date, count]) => ({
        type: date,
        sales: count,
      })
    );
    // 排序函数，按照 type 日期大小升序排序
    function sortByDateAscending(a, b) {
      const dateA = new Date(a.type);
      const dateB = new Date(b.type);
      return dateA - dateB;
    }
    transformedData.sort(sortByDateAscending);
    // console.log("transformedData", transformedData);
    const config = {
      data: transformedData,
      xField: "type",
      yField: "sales",
      label: {
        // 可手动配置 label 数据标签位置
        position: "middle",
        // 'top', 'bottom', 'middle',
        // 配置样式
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        type: {
          alias: "类别",
        },
        sales: {
          alias: "Pick Up",
        },
      },
      scrollbar: {
        type: "horizontal",
      },
    };
    // 更新柱状图配置
    setColConfig(config);
  };

  //生成饼状图配置
  const getPieSLData = (data) => {
    // 创建一个对象来存储统计结果，键为MBL的前四位，值为对应的数量
    const mblCounts = { others: 0 };
    // 遍历数组中的每个对象
    data.forEach((item) => {
      // 获取当前对象的 MBL
      const mbl = item.mbl;

      // 如果 MBL 不为 null
      if (mbl !== null && item["status"] !== 1100) {
        // 提取 MBL 的前四位
        const mblPrefix = mbl.slice(0, 4);

        // 检查 mblCounts 中是否已存在该 MBL 前四位的记录
        if (mblCounts[mblPrefix] === undefined) {
          // 如果不存在，创建该 MBL 前四位的记录，并初始化数量为 1
          mblCounts[mblPrefix] = 1;
        } else {
          // 如果已存在该 MBL 前四位的记录，将数量加 1
          mblCounts[mblPrefix] += 1;
        }
      }
    });
    // console.log("mblCounts", mblCounts);
    for (const key in mblCounts) {
      if (
        mblCounts[key] < 5 &&
        key !== "others" &&
        !shippingLineRef.current.includes(key)
      ) {
        mblCounts["others"] = mblCounts["others"] + mblCounts[key];
        delete mblCounts[key]; // 从mblCounts中移除这个键值对
      }
    }
    // 将 containerCountsByDate 转换成 { type: 日期, sales: 数量 } 格式的数组
    const transformedData = Object.entries(mblCounts).map(([date, count]) => ({
      type: date,
      value: count,
    }));
    const config = {
      aappendPadding: 10,
      data: transformedData,
      angleField: "value",
      colorField: "type",
      radius: 0.75,
      label: {
        type: "spider",
        labelHeight: 28,
        content: "{name}\n{percentage}",
      },
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
    };
    // 更新饼状图配置
    setPieConfig(config);
  };

  //不能选择超过今天的日期
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  //预设选择的时间范围
  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];
  //选择了时间范围
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setSearchForm({
        ...searchForm,
        beginTime: dateStrings[0],
        endTime: dateStrings[1],
      });
    } else {
      // console.log("Clear");
      setSearchForm({ ...searchForm, beginTime: null, endTime: null });
    }
  };

  const titleNode = (
    <Space size={10}>
      <Input
        allowClear
        placeholder="Search..."
        style={{ width: "300px" }}
        onChange={(e) =>
          setSearchForm({
            ...searchForm,
            name: e.target.value?.trim(),
          })
        }
      />
      <Select
        placeholder="Customer..."
        style={{ width: "120px" }}
        showSearch
        allowClear
        onChange={(value) =>
          setSearchForm({
            ...searchForm,
            warehouseId: value,
          })
        }
        optionFilterProp="label"
        options={customerList.map((item) => ({
          label: item.name.split("-")[0].trim(),
          value: item.id,
        }))}
      />
      <Select
        placeholder="Status..."
        style={{ width: "180px" }}
        showSearch
        allowClear
        onChange={(value) =>
          setSearchForm({
            ...searchForm,
            status: value,
          })
        }
        optionFilterProp="label"
        options={loadStatusList.map((item) => ({
          label: item.name,
          value: item.statusId,
        }))}
      />
      <Select
        placeholder="Terminal..."
        style={{ width: "120px" }}
        showSearch
        allowClear
        onChange={(value) =>
          setSearchForm({
            ...searchForm,
            terminal: value,
          })
        }
        optionFilterProp="label"
        options={TERMINALS.map((name) => ({
          label: name,
          value: name,
        }))}
      />
      {/* 选择时间范围 */}
      <RangePicker
        disabledDate={disabledDate}
        presets={rangePresets}
        onChange={onRangeChange}
      />
      <Button
        type="primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          getListData(1);
        }}
      >
        Search
      </Button>
      <span>总数：{dataList.length}</span>
    </Space>
  );

  return (
    <>
      <Card title={titleNode} loading={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "95%",
            }}
          >
            <Row style={{ height: "20px" }}></Row>
            <Row>
              {colConfig && <Column {...colConfig} style={{ width: "100%" }} />}
            </Row>
            <Row style={{ height: "50px" }}></Row>
            <Row>
              {pieConfig && <Pie {...pieConfig} style={{ width: "100%" }} />}
            </Row>
            <Row style={{ height: "50px" }}></Row>
          </div>
        </div>
      </Card>
    </>
  );
}
